import axios from 'axios';

// Instancia de axios para la API v1 (existente)
export const apiV1 = axios.create({
  baseURL: 'https://us-central1-alphaplatform.cloudfunctions.net/api'
});

// Instancia de axios para la API v2 (nueva)
export const apiV2 = axios.create({
  baseURL: 'https://api2-xzfhezst5q-uc.a.run.app'
});

// Función para configurar el token en las instancias de axios
export const setAuthorizationHeader = (token) => {
  if (token) {
    localStorage.setItem('FBIdToken', `Bearer ${token}`);
    apiV1.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    apiV2.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    localStorage.removeItem('FBIdToken');
    delete apiV1.defaults.headers.common['Authorization'];
    delete apiV2.defaults.headers.common['Authorization'];
  }
};

// Configurar el token si ya existe en localStorage
const token = localStorage.getItem('FBIdToken');
if (token) {
  apiV1.defaults.headers.common['Authorization'] = token;
  apiV2.defaults.headers.common['Authorization'] = token;
}
import React, { Component, Fragment  } from 'react';
import './ProgramarCalibracion.css'; 
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft,FaPlus, FaPencilAlt, FaCheck, FaCheckCircle } from "react-icons/fa";

import {editEquipoDetails,getEquipo,clearErrors} from '../../../redux/actions/dataactions';

export class DetallesEquipo extends Component {

    constructor(){
        super();
        this.state={
            nombre:'',
            marca:'',
            registroinvima:'',
            modelo:'',
            serie:'',
            ubicacion:'',
            servicio:'',
            tipo:'',
            clase:'',
            riesgo:'',
            movilfijo:'',
            clasbiomedica:'',
            reqcalibracion:'',
            adquisicion:'',
            costo:'',
            fabricacion:'',
            operacion:'',
            vidautil:'',
            proveedor:'',
            proveedortel:'',
            proveedoremail:'',
            tipotec:'',
            fuente:'',
            tensionmin:'',
            tensionmax:'',
            corrientemin:'',
            corrientemax:'',
            temperatura:'',
            frecuencia:'',
            potencia:'',
            presion:'',
            alto:'',
            ancho:'',
            largo:'',
            peso:'',
            idinventario:'',
            urlfactura:'',
            urlmanual:'',
            errors:{},
            aprobado:{},
             // New variables
             fechainstalacion: '',
             garantia: '',
             fechainiciogarantia: '',
             fechafingarantia: '',
             velocidad: '',
             capacidad: '',
             tipouso: '',
             pais: '',
             descripcion: '',
             nombrefabricante: '',
             emailfabricante: '',
             telfabricante: '',
             rutinamto: '',
             recomendaciones: '',
             accesorio: '',
             accesorionombre1: '',
             accesorioref1: '',
             accesorioserie1: '',
             accesorionombre2: '',
             accesorioref2: '',
             accesorioserie2: '',
             accesorionombre3: '',
             accesorioref3: '',
             accesorioserie3: '',
            open:false
        };
        
    };
    static getDerivedStateFromProps(props, state) {
      const newState = {};
  
      if (props.UI.errors) {
          newState.errors = props.UI.errors;
      }
  
      if (props.UI.aprobado) {
         newState.aprobado = props.UI.aprobado;
      }
  
      return Object.keys(newState).length > 0 ? newState : null;
  }
    
  mapUserDetailsToState = (equipo) => {
    this.setState({
        nombre:equipo.nombre?equipo.nombre:'',
        marca:equipo.marca?equipo.marca:'',
            registroinvima:equipo.registroinvima?equipo.registroinvima:'',
            modelo:equipo.modelo?equipo.modelo:'',
            serie:equipo.serie?equipo.serie:'',
            ubicacion:equipo.ubicacion?equipo.ubicacion:'',
            servicio:equipo.servicio?equipo.servicio:'',
            tipo:equipo.tipo?equipo.tipo:'',
            clase:equipo.clase?equipo.clase:'',
            riesgo:equipo.riesgo?equipo.riesgo:'',
            movilfijo:equipo.movilfijo?equipo.movilfijo:'',
            clasbiomedica:equipo.clasbiomedica?equipo.clasbiomedica:'',
            reqcalibracion:equipo.reqcalibracion?equipo.reqcalibracion:'',
            adquisicion:equipo.adquisicion?equipo.adquisicion:'',
            costo:equipo.costo?equipo.costo:'',
            fabricacion:equipo.fabricacion?equipo.fabricacion:'',
            operacion:equipo.operacion?equipo.operacion:'',
            vidautil:equipo.vidautil?equipo.vidautil:'',
            proveedor:equipo.proveedor?equipo.proveedor:'',
            proveedortel:equipo.proveedortel?equipo.proveedortel:'',
            proveedoremail:equipo.proveedoremail?equipo.proveedoremail:'',
            tipotec:equipo.tipotec?equipo.tipotec:'',
            fuente:equipo.fuente?equipo.fuente:'',
            tensionmin:equipo.tensionmin?equipo.tensionmin:'',
            tensionmax:equipo.tensionmax?equipo.tensionmax:'',
            corrientemin:equipo.corrientemin?equipo.corrientemin:'',
            corrientemax:equipo.corrientemax?equipo.corrientemax:'',
            temperatura:equipo.temperatura?equipo.temperatura:'',
            frecuencia:equipo.frecuencia?equipo.frecuencia:'',
            presion:equipo.presion?equipo.presion:'',
            potencia:equipo.potencia?equipo.potencia:'',
            alto:equipo.alto?equipo.alto:'',
            ancho:equipo.ancho?equipo.ancho:'',
            largo:equipo.largo?equipo.largo:'',
            peso:equipo.peso?equipo.peso:'',
            idinventario:equipo.idinventario?equipo.idinventario:'',
            urlfactura:equipo.urlfactura?equipo.urlfactura:'',
            urlmanual:equipo.urlmanual?equipo.urlmanual:'',
             // Map new variables
             fechainstalacion: equipo.fechainstalacion ? equipo.fechainstalacion : '',
             garantia: equipo.garantia ? equipo.garantia : '',
             fechainiciogarantia: equipo.fechainiciogarantia ? equipo.fechainiciogarantia : '',
             fechafingarantia: equipo.fechafingarantia ? equipo.fechafingarantia : '',
             velocidad: equipo.velocidad ? equipo.velocidad : '',
             capacidad: equipo.capacidad ? equipo.capacidad : '',
             tipouso: equipo.tipouso ? equipo.tipouso : '',
             pais: equipo.pais ? equipo.pais : '',
             descripcion: equipo.descripcion ? equipo.descripcion : '',
             nombrefabricante: equipo.nombrefabricante ? equipo.nombrefabricante : '',
             emailfabricante: equipo.emailfabricante ? equipo.emailfabricante : '',
             telfabricante: equipo.telfabricante ? equipo.telfabricante : '',
             rutinamto: equipo.rutinamto ? equipo.rutinamto : '',
             recomendaciones: equipo.recomendaciones ? equipo.recomendaciones : '',
             accesorio: equipo.accesorio ? equipo.accesorio : '',
             accesorionombre1: equipo.accesorionombre1 ? equipo.accesorionombre1 : '',
             accesorioref1: equipo.accesorioref1 ? equipo.accesorioref1 : '',
             accesorioserie1: equipo.accesorioserie1 ? equipo.accesorioserie1 : '',
             accesorionombre2: equipo.accesorionombre2 ? equipo.accesorionombre2 : '',
             accesorioref2: equipo.accesorioref2 ? equipo.accesorioref2 : '',
             accesorioserie2: equipo.accesorioserie2 ? equipo.accesorioserie2 : '',
             accesorionombre3: equipo.accesorionombre3 ? equipo.accesorionombre3 : '',
             accesorioref3: equipo.accesorioref3 ? equipo.accesorioref3 : '',
             accesorioserie3: equipo.accesorioserie3 ? equipo.accesorioserie3 : ''
            
    });
  };

  handleOpen = () => {
    this.setState({ open: true,errors:{},
      aprobado:{}});
    this.mapUserDetailsToState(this.props.equipo);
  };
  handleClose = () => {
    if (this.state.aprobado && this.state.aprobado.mensaje) {
        // Si la edición fue exitosa, actualizar el equipo
        this.props.getEquipo(this.props.equipoId);
        
        // Reiniciar el estado
        this.setState({
            open: false,
            errors: {},
            aprobado: {}
        });
        this.props.clearErrors();
    } else {
        // Si no se completó la edición o hubo un error
        this.props.clearErrors();
        this.setState({
            open: false,
            errors: {},
            aprobado: {}
        });
    }
};
  componentDidMount() {
    const { equipo } = this.props;
    this.mapUserDetailsToState(equipo);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    
    // Special handling for warranty and accessory fields
    if (name === 'garantia' && value === 'false') {
        // If warranty is set to false, clear warranty dates
        this.setState({
            [name]: value,
            fechainiciogarantia: '',
            fechafingarantia: ''
        });
    } else if (name === 'accesorio' && value === 'false') {
        // If accessories is set to false, clear all accessory fields
        this.setState({
            [name]: value,
            accesorionombre1: '',
            accesorioref1: '',
            accesorioserie1: '',
            accesorionombre2: '',
            accesorioref2: '',
            accesorioserie2: '',
            accesorionombre3: '',
            accesorioref3: '',
            accesorioserie3: ''
        });
    } else {
        // Default handling for all other fields
        this.setState({ [name]: value });
    }
};
  handleSubmit = (event) => {
    event.preventDefault();
            const newEquipoData={
                nombre:this.state.nombre,
                marca:this.state.marca,
                registroinvima:this.state.registroinvima,
                modelo:this.state.modelo,
                serie:this.state.serie,
                ubicacion:this.state.ubicacion,
                servicio:this.state.servicio,
                tipo:this.state.tipo,
                clase:this.state.clase,
                riesgo:this.state.riesgo,
                movilfijo:this.state.movilfijo,
                clasbiomedica:this.state.clasbiomedica,
                reqcalibracion:this.state.reqcalibracion,
                adquisicion:this.state.adquisicion,
                costo:this.state.costo,
                fabricacion:this.state.fabricacion,
                operacion:this.state.operacion,
                vidautil:this.state.vidautil,
                proveedor:this.state.proveedor,
                proveedortel:this.state.proveedortel,
                proveedoremail:this.state.proveedoremail,
                tipotec:this.state.tipotec,
                fuente:this.state.fuente,
                tensionmin:this.state.tensionmin,
                tensionmax:this.state.tensionmax,
                corrientemin:this.state.corrientemin,
                corrientemax:this.state.corrientemax,
                frecuencia:this.state.frecuencia,
                temperatura:this.state.temperatura,
                presion:this.state.presion,
                potencia:this.state.potencia,
                alto:this.state.alto,
                ancho:this.state.ancho,
                largo:this.state.largo,
                peso:this.state.peso,
                idinventario:this.state.idinventario,
                urlfactura:this.state.urlfactura,
                urlmanual:this.state.urlmanual,
                fechainstalacion: this.state.fechainstalacion,
                garantia: this.state.garantia,
                fechainiciogarantia: this.state.fechainiciogarantia,
                fechafingarantia: this.state.fechafingarantia,
                velocidad: this.state.velocidad,
                capacidad: this.state.capacidad,
                tipouso: this.state.tipouso,
                pais: this.state.pais,
                descripcion: this.state.descripcion,
                nombrefabricante: this.state.nombrefabricante,
                emailfabricante: this.state.emailfabricante,
                telfabricante: this.state.telfabricante,
                rutinamto: this.state.rutinamto,
                recomendaciones: this.state.recomendaciones,
                accesorio: this.state.accesorio,
                accesorionombre1: this.state.accesorionombre1,
                accesorioref1: this.state.accesorioref1,
                accesorioserie1: this.state.accesorioserie1,
                accesorionombre2: this.state.accesorionombre2,
                accesorioref2: this.state.accesorioref2,
                accesorioserie2: this.state.accesorioserie2,
                accesorionombre3: this.state.accesorionombre3,
                accesorioref3: this.state.accesorioref3,
                accesorioserie3: this.state.accesorioserie3
             
          };
    
     this.props.editEquipoDetails(newEquipoData,this.props.equipoId);
   
  };


 


    render() {
        const { UI:{loading}}=this.props; 
        const {errors}=this.state;
        return (
            <div>
                <Fragment>
            <MyButton tip="Editar Información" onClick={this.handleOpen}>
            <FaPencilAlt color="#03178C" className="icon-crearcliente"/>
             </MyButton>
             <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
             maxWidth="sm"
            >

{this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Cargado Exitosamente</h2>
              </div>
                
                ) : (<div>
              <DialogTitle>Editar Detalles del Equipo</DialogTitle>
               
               <DialogContent>
                     
               <form>
                       <Accordion>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
                <h4 className="text-title-accorion">Identificación del Equipo</h4>
           </AccordionSummary>
           <AccordionDetails>
           <div className="flex-accordion">
       
          <TextField className="login-input-email"
                      required
                      type="text"
                      name="nombre"
                      id="nombre"
                      label="Nombre del Equipo"
                      value={this.state.nombre}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombre}
                      error={errors.nombre?true:false}
                      />

                  <div className="input_divido">
                  <div className="input_divido_children">
                  <TextField className="login-input-email"
                                        required
                                        type="text"
                                        name="idinventario"
                                        id="idinventario"
                                        label="No. Inventario"
                                        value={this.state.idinventario}
                                        onChange={this.handleChange}
                                        fullWidth
                                        helperText={errors.idinventario}
                                        error={errors.idinventario?true:false}
                                        />
                    </div>
                    <div className="input_divido_children">
                    <TextField className="login-input-email"
                                        required
                                        type="text"
                                        name="serie"
                                        id="serie"
                                        label="Numero de Serie"
                                        value={this.state.serie}
                                        onChange={this.handleChange}
                                        fullWidth
                                        helperText={errors.serie}
                                        error={errors.serie?true:false}
                                        />
                    </div>
                    </div>
                            
                     
                      <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="modelo"
                      id="modelo"
                      label="Modelo del Equipo"
                      value={this.state.modelo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.modelo}
                      error={errors.modelo?true:false}
                      />
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="marca"
                      id="marca"
                      label="Marca del Equipo"
                      value={this.state.marca}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.marca}
                      error={errors.marca?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="ubicacion"
                      id="ubicacion"
                      label="Ubicación del Equipo"
                      value={this.state.ubicacion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ubicacion}
                      error={errors.ubicacion?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="servicio"
                      id="servicio"
                      label="Servicio"
                      value={this.state.servicio}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.servicio}
                      error={errors.servicio?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <FormControl className="formcontrol-nuevocliente"  error={errors.tipouso?true:false}>
                            <InputLabel>Tipo de Uso</InputLabel>
                            <Select
                            required
                         name="tipouso"
                         id="tipouso"
                         value={this.state.tipouso}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Medico">Medico</MenuItem>
                            <MenuItem value="Basico">Básico</MenuItem>
                            <MenuItem value="Apoyo">Apoyo</MenuItem>
                            <MenuItem value="N/A">N/A</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipouso}</FormHelperText>
                        </FormControl>
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="registroinvima"
                      id="registroinvima"
                      label="Registro Invima del Equipo"
                      value={this.state.registroinvima}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.registroinvima}
                      error={errors.registroinvima?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    
                 
                    
                      
                      <div className="input_divido">
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente"  error={errors.tipo?true:false}>
                            <InputLabel>Tipo de Aislamiento</InputLabel>
                            <Select
                            required
                         name="tipo"
                         id="tipo"
                         value={this.state.tipo}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="B">B</MenuItem>
                            <MenuItem value="BF">BF</MenuItem>
                            <MenuItem value="CF">CF</MenuItem>
                            <MenuItem value="N/A">N/A</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipo}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.clase?true:false}>
                            <InputLabel>Clase de Aislamiento</InputLabel>
                            <Select
                            required
                         name="clase"
                         id="clase"
                         value={this.state.clase}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="N/A">N/A</MenuItem>
                            <MenuItem value="I">I</MenuItem>
                            <MenuItem value="II">II</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.clase}</FormHelperText>
                        </FormControl>
                        </div>
                        </div>

                        

                        <div className="input_divido">
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.clasbiomedica?true:false}>
                            <InputLabel>Clasificación Biomédica</InputLabel>
                            <Select
                            required
                         name="clasbiomedica"
                         id="clasbiomedica"
                         value={this.state.clasbiomedica}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Diagnóstico">Diagnóstico</MenuItem>
                            <MenuItem value="Rehabilitación">Rehabilitación</MenuItem>
                            <MenuItem value="Apoyo Vital">Apoyo Vital</MenuItem>
                            <MenuItem value="Tratamiento">Tratamiento</MenuItem>
                            <MenuItem value="Prevención">Prevención</MenuItem>
                            <MenuItem value="Laboratorio">Laboratorio</MenuItem>
                            <MenuItem value="Industrial">Industrial</MenuItem>
                            <MenuItem value="Mobiliario">Mobiliario</MenuItem>

                            </Select>
                            <FormHelperText>{errors.clasbiomedica}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.reqcalibracion?true:false}>
                            <InputLabel>Requiere Calibración</InputLabel>
                            <Select
                            required
                         name="reqcalibracion"
                         id="reqcalibracion"
                         value={this.state.reqcalibracion}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="true">Si</MenuItem>
                            <MenuItem value="false">No</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.reqcalibracion}</FormHelperText>
                        </FormControl>
                        </div>
                        </div>
                        
                        <div className="input_divido">
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.riesgo?true:false}>
                            <InputLabel>Clasificación Riesgo</InputLabel>
                            <Select
                            required
                         name="riesgo"
                         id="riesgo"
                         value={this.state.riesgo}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="I">I</MenuItem>
                            <MenuItem value="IIA">IIA</MenuItem>
                            <MenuItem value="IIB">IIB</MenuItem>
                            <MenuItem value="III">III</MenuItem>
                            </Select>
                            <FormHelperText>{errors.riesgo}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.movilfijo?true:false}>
                            <InputLabel>¿Equipo es Movil o Fijo?</InputLabel>
                            <Select
                            required
                         name="movilfijo"
                         id="movilfijo"
                         value={this.state.movilfijo}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Movil">Móvil</MenuItem>
                            <MenuItem value="Fijo">Fijo</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.movilfijo}</FormHelperText>
                        </FormControl>
                        </div>
                        </div>

                        <div className="input_divido">
                        <TextField className="login-input-email"
                                                multiline
                                                type="Text"
                                                name="descripcion"
                                                id="descripcion"
                                                label="Breve Descripción de Uso"
                                                rows={4}
                                                value={this.state.descripcion}
                                                onChange={this.handleChange}
                                                fullWidth
                                                helperText={errors.descripcion}
                                                error={errors.descripcion?true:false}
                                              />
                        </div>
                        




          </div>
           
                       
   
             </AccordionDetails>
             </Accordion>
   
             <Accordion>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
                <h4 className="text-title-accorion">Historial de Adquisición</h4>
           </AccordionSummary>
           <AccordionDetails>
           <div className="flex-accordion">
      
        <div className="input_divido">
                      <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.adquisicion?true:false}>
                            <InputLabel>Forma Adquisición</InputLabel>
                            <Select
                            required
                         name="adquisicion"
                         id="adquisicion"
                         value={this.state.adquisicion}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="compra">Compra</MenuItem>
                            <MenuItem value="comodato">Comodato</MenuItem>
                            <MenuItem value="donacion">Donación</MenuItem>
                            <MenuItem value="leasing">Leasing</MenuItem>
                            <MenuItem value="otro">Otro</MenuItem>
                            </Select>
                            <FormHelperText>{errors.adquisicion}</FormHelperText>
                        </FormControl>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="costo"
                      id="costo"
                      label="Valor del Equipo"
                      value={this.state.costo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.costo}
                      error={errors.costo?true:false}
                      />
                      <br/>
                        </div>
                        </div>

                        
                       

                        <div className="input_divido">
                      <div className="input_divido_children">
                      <TextField className="login-input-email"
                      required
                      type="date"
                      name="fabricacion"
                      id="fabricacion"
                      label="Fecha Fabricación"
                      value={this.state.fabricacion}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.fabricacion}
                      error={errors.fabricacion?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
                        <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="date"
                      name="operacion"
                      id="operacion"
                      label="Fecha de Adquisición"
                      value={this.state.operacion}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.operacion}
                      error={errors.operacion?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
                      <br/>
                        </div>
                        </div>

                        <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="date"
                      name="fechainstalacion"
                      id="fechainstalacion"
                      label="Fecha de Instalacion"
                      value={this.state.fechainstalacion}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2026-01-01"
                      fullWidth
                      helperText={errors.fechainstalacion}
                      error={errors.fechainstalacion?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
                     
                      
                        </div>

                        <div className="input_divido_children">
                      <FormControl className="formcontrol-nuevocliente" error={errors.garantia?true:false}>
                            <InputLabel>¿Equipo en Garantia?</InputLabel>
                            <Select
                            required
                         name="garantia"
                         id="garantia"
                         value={this.state.garantia}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value='true'>Si</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.garantia}</FormHelperText>
                        </FormControl>
                        </div>

                        </div>

                        {/* Conditional rendering for warranty dates */}
                      {this.state.garantia === 'true' && (
                        <div className="input_divido">
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="date"
                              name="fechainiciogarantia"
                              id="fechainiciogarantia"
                              label="Fecha Inicio de Garantia"
                              value={this.state.fechainiciogarantia}
                              onChange={this.handleChange}
                              min="2000-01-01" max="2026-01-01"
                              fullWidth
                              helperText={errors.fechainiciogarantia}
                              error={errors.fechainiciogarantia?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                          <div className="input_divido_children">
                            <TextField className="login-input-email"
                              required
                              type="date"
                              name="fechafingarantia"
                              id="fechafingarantia"
                              label="Fecha Final de Garantía"
                              value={this.state.fechafingarantia}
                              onChange={this.handleChange}
                              min="2000-01-01" max="2026-01-01"
                              fullWidth
                              helperText={errors.fechafingarantia}
                              error={errors.fechafingarantia?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </div>
                        </div>
                      )}

                        <div className="input_divido">
                      <div className="input_divido_children">
                      <TextField className="login-input-email"
                      required
                      type="text"
                      name="pais"
                      id="pais"
                      label="País de Fabricación"
                      value={this.state.pais}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.pais}
                      error={errors.pais?true:false}
                      />
                        <br/>
                        </div>

                        <div className="input_divido_children">
                      <TextField className="login-input-email"
                      required
                      type="text"
                      name="nombrefabricante"
                      id="nombrefabricante"
                      label="Nombre Fabricante"
                      value={this.state.nombrefabricante}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombrefabricante}
                      error={errors.nombrefabricante?true:false}
                      />
                        <br/>
                        </div>
                       
                        </div>

                        <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="telfabricante"
                      id="telfabricante"
                      label="Telefono Fabricante"
                      value={this.state.telfabricante}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.telfabricante}
                      error={errors.telfabricante?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="email"
                      name="emailfabricante"
                      id="emailfabricante"
                      label="Email Fabricante"
                      value={this.state.emailfabricante}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.emailfabricante}
                      error={errors.emailfabricante?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                        <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="vidautil"
                      id="vidautil"
                      label="Años de Vida Útil"
                      value={this.state.vidautil}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.vidautil}
                      error={errors.vidautil?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="proveedor"
                      id="proveedor"
                      label="Proveedor del Equipo"
                      value={this.state.proveedor}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedor}
                      error={errors.proveedor?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="proveedortel"
                      id="proveedortel"
                      label="Telefono Proveedor"
                      value={this.state.proveedortel}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedortel}
                      error={errors.proveedortel?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="email"
                      name="proveedoremail"
                      id="proveedoremail"
                      label="Email proveedor del equipo"
                      value={this.state.proveedoremail}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedoremail}
                      error={errors.proveedoremail?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>
                    </div>
   
           </AccordionDetails>
   
   
    
             </Accordion>
   
             <Accordion>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
                <h4 className="text-title-accorion">Datos Técnicos del Equipo</h4>
           </AccordionSummary>
           <AccordionDetails>
           <div className="flex-accordion">
       
        <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Tecnología Predominante</InputLabel>
                            <Select
                            required
                         name="tipotec"
                         id="tipotec"
                         value={this.state.tipotec}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="Electrónica">Electrónica</MenuItem>
                            <MenuItem value="Eléctrica">Eléctrica</MenuItem>
                            <MenuItem value="Mecánica">Mecánica</MenuItem>
                            <MenuItem value="Hidraulica">Hidraulica</MenuItem>                            
                            <MenuItem value="Neumatica">Neumatica</MenuItem>
                            <MenuItem value="Vapor">Vapor</MenuItem>
                            <MenuItem value="Otra">Otra</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipotec}</FormHelperText>
                        </FormControl>

                        <FormControl className="formcontrol-nuevocliente">
                            <InputLabel>Fuente de Alimentación</InputLabel>
                            <Select
                            required
                         name="fuente"
                         id="fuente"
                         value={this.state.fuente}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value="AC">AC</MenuItem>
                            <MenuItem value="DC">DC </MenuItem>
                            <MenuItem value="Gas Medicinal">Gas Medicinal</MenuItem>
                            <MenuItem value="Agua">Agua</MenuItem>        
                            <MenuItem value="Aire">Aire</MenuItem>                    
                            <MenuItem value="Aire comprimido">Aire comprimido</MenuItem>
                            <MenuItem value="Energia Solar">Energia Solar</MenuItem>
                            <MenuItem value="Derivados del Petroleo">Derivados del Petroleo</MenuItem>
                            <MenuItem value="Vapor">Vapor</MenuItem>
                            <MenuItem value="Otra">Otra</MenuItem>
                            </Select>
                            <FormHelperText>{errors.tipotec}</FormHelperText>
                        </FormControl>

                        <TextField className="login-input-email"
                      required
                      type="tex"
                      name="urlmanual"
                      id="urlmanual"
                      label="Link del Manual o Ficha Técnica"
                      value={this.state.urlmanual}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.urlmanual}
                      error={errors.urlmanual?true:false}
                      />

              <TextField className="login-input-email"
                      required
                      type="tex"
                      name="urlfactura"
                      id="urlfactura"
                      label="Link de Factura o Declaración de Importación"
                      value={this.state.urlfactura}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.urlfactura}
                      error={errors.urlfactura?true:false}
                      />

                        <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="tensionmin"
                      id="tensionmin"
                      label="Tensión Min (V)"
                      value={this.state.tensionmin}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.tensionmin}
                      error={errors.tensionmin?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="tensionmax"
                      id="tensionmax"
                      label="Tensión Max (V)"
                      value={this.state.tensionmax}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.tensionmax}
                      error={errors.tensionmax?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>


                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="corrientemin"
                      id="corrientemin"
                      label="Corriente Min (A)"
                      value={this.state.corrientemin}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.corrientemin}
                      error={errors.corrientemin?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="corrientemax"
                      id="corrientemax"
                      label="Corriente Max (A)"
                      value={this.state.corrientemax}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.corrientemax}
                      error={errors.corrientemax?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>


                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="velocidad"
                      id="velocidad"
                      label="Velocidad"
                      value={this.state.velocidad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.velocidad}
                      error={errors.velocidad?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="potencia"
                      id="potencia"
                      label="Potencia del Equipo (W)"
                      value={this.state.potencia}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.potencia}
                      error={errors.potencia?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>
                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="presion"
                      id="presion"
                      label="Presion"
                      value={this.state.presion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.presion}
                      error={errors.presion?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="capacidad"
                      id="capacidad"
                      label="Capacidad"
                      value={this.state.capacidad}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.capacidad}
                      error={errors.capacidad?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="temperatura"
                      id="temperatura"
                      label="Temperatura de Operación"
                      value={this.state.temperatura}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.temperatura}
                      error={errors.temperatura?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="frecuencia"
                      id="frecuencia"
                      label="Frecuencia(Hz)"
                      value={this.state.frecuencia}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.frecuencia}
                      error={errors.frecuencia?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>


                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="alto"
                      id="alto"
                      label="Medidas Alto (cm)"
                      value={this.state.alto}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.alto}
                      error={errors.alto?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="ancho"
                      id="ancho"
                      label="Medidas Ancho (cm)"
                      value={this.state.ancho}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.ancho}
                      error={errors.ancho?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="largo"
                      id="largo"
                      label="Medidas Largo (cm)"
                      value={this.state.largo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.largo}
                      error={errors.largo?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="peso"
                      id="peso"
                      label="Peso (Kg)"
                      value={this.state.peso}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.peso}
                      error={errors.peso?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                  

                      
                           
                         




          </div>
             </AccordionDetails>
             </Accordion>
                   
   
   
             <Accordion>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             aria-controls="panel1a-content"
             id="panel1a-header"
           >
                <h4 className="text-title-accorion">Información Adicional</h4>
           </AccordionSummary>
           <AccordionDetails>

           <div className="flex-accordion">
        
          <div className="input_divido">
                        <TextField className="login-input-email"
                                                multiline
                                                type="Text"
                                                name="rutinamto"
                                                id="rutinamto"
                                                label="Rutina de Mantenimiento"
                                                rows={4}
                                                value={this.state.rutinamto}
                                                onChange={this.handleChange}
                                                fullWidth
                                                helperText={errors.rutinamto}
                                                error={errors.rutinamto?true:false}
                                              />
                        </div>

                        <div className="input_divido">
                        <TextField className="login-input-email"
                                                multiline
                                                type="Text"
                                                name="recomendaciones"
                                                id="recomendaciones"
                                                label="Recomendaciones de Uso"
                                                rows={4}
                                                value={this.state.recomendaciones}
                                                onChange={this.handleChange}
                                                fullWidth
                                                helperText={errors.recomendaciones}
                                                error={errors.recomendaciones?true:false}
                                              />
                        </div>
          <div className="input_divido">
                        <FormControl className="formcontrol-nuevocliente" error={errors.garantia?true:false}>
                            <InputLabel>¿El equipo tiene accesorios?</InputLabel>
                            <Select
                            required
                         name="accesorio"
                         id="accesorio"
                         value={this.state.accesorio}
                            onChange={this.handleChange}
                            fullWidth
                            >
                            <MenuItem value="">
                                <em></em>
                            </MenuItem>
                            <MenuItem value='true'>Si</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                            
                            </Select>
                            <FormHelperText>{errors.accesorio}</FormHelperText>
                        </FormControl>
                        </div>
{/* accesorionombre1:'',
            accesorioref1:'',
            accesorioserie1:'', */}
                            {this.state.accesorio === 'true' && (
                               <>
 <div className="input_divido">
                        <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorionombre1"
                      id="accesorionombre1"
                      label="Nombre Accesorio 1"
                      value={this.state.accesorionombre1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorionombre1}
                      error={errors.accesorionombre1?true:false}
                      />
                          </div>

                          <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorioref1"
                      id="accesorioref1"
                      label="Referencia"
                      value={this.state.accesorioref1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorioref1}
                      error={errors.accesorioref1?true:false}
                      />
                          </div>

                          <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorioserie1"
                      id="accesorioserie1"
                      label="Serie"
                      value={this.state.accesorioserie1}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorioserie1}
                      error={errors.accesorioserie1?true:false}
                      />
                          </div>

                          </div>

                          <div className="input_divido">
                        <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorionombre2"
                      id="accesorionombre2"
                      label="Nombre Accesorio 2"
                      value={this.state.accesorionombre2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorionombre2}
                      error={errors.accesorionombre2?true:false}
                      />
                          </div>

                          <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorioref2"
                      id="accesorioref2"
                      label="Referencia"
                      value={this.state.accesorioref2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorioref2}
                      error={errors.accesorioref2?true:false}
                      />
                          </div>

                          <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorioserie2"
                      id="accesorioserie2"
                      label="Serie"
                      value={this.state.accesorioserie2}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorioserie2}
                      error={errors.accesorioserie2?true:false}
                      />
                          </div>

                          </div>

                          <div className="input_divido">
                        <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorionombre3"
                      id="accesorionombre3"
                      label="Nombre Accesorio 3"
                      value={this.state.accesorionombre3}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorionombre3}
                      error={errors.accesorionombre3?true:false}
                      />
                          </div>

                          <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorioref3"
                      id="accesorioref3"
                      label="Referencia"
                      value={this.state.accesorioref3}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorioref3}
                      error={errors.accesorioref3?true:false}
                      />
                          </div>

                          <div className="input_divido_children">
                          <TextField className="login-input-email"
                      required
                      type="tex"
                      name="accesorioserie3"
                      id="accesorioserie3"
                      label="Serie"
                      value={this.state.accesorioserie3}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.accesorioserie3}
                      error={errors.accesorioserie3?true:false}
                      />
                          </div>

                          </div>
                            </>

                            )}
          </div>
        


           </AccordionDetails>
           </Accordion>
                          
   
   
   
   
               </form>
                            
               </DialogContent>
                </div>)
                }

            
            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Actualizar Información" onClick={this.handleSubmit}>
                  <FaPlus  color="#03178C" className="icon-formulario"/>
                   </MyButton>
                )
              )}
         
            
            
          </DialogActions>

            </Dialog>
             </Fragment>

            </div>
        )
    }
}

DetallesEquipo.propTypes = {
    editEquipoDetails: PropTypes.func.isRequired,
    getEquipo:PropTypes.func.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired  
  };

  const mapActionsToProps={editEquipoDetails,getEquipo,clearErrors};

  const mapStateToProps = (state) => ({
    equipo: state.data.equipo.credentials,
    UI:state.UI
  });
  
  export default connect(mapStateToProps,mapActionsToProps)(DetallesEquipo);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaPlus, FaPencilAlt, FaCheck, FaCheckCircle } from "react-icons/fa";

import { editDocLegal, clearErrors, getDocsLegal } from '../../../redux/actions/dataactions';

import './DocumentoEditar.css';

export class DocumentoEditar extends Component {
  constructor() {
    super();
    this.state = {
      tipodoc: '',
      descripcion: '',
      consecutivo: '',
      errors: {},
      aprobado: {},
      open: false
    };
  }

  mapUserDetailsToState = (doc) => {
    this.setState({
      tipodoc: doc.tipodoc ? doc.tipodoc : '',
      descripcion: doc.descripcion ? doc.descripcion : '',
      consecutivo: doc.consecutivo ? doc.consecutivo : '',
    });
  };

  static getDerivedStateFromProps(props, state) {
    const newState = {};
  
    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }
  
    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }
  
    return Object.keys(newState).length > 0 ? newState : null;
  }

  handleOpen = () => {
    this.setState({ open: true });
    this.mapUserDetailsToState(this.props.doc);
  };

  handleClose = () => {
    if (this.props.clearErrors) {
      this.props.clearErrors();
    }
    
    if (this.state.aprobado && this.state.aprobado.mensaje) {
      console.log('Documento editado exitosamente:', this.state.aprobado);
      
      this.setState({
        open: false,
        aprobado: {},
        errors: {}
      });

      // Actualizar la lista de documentos
      this.props.getDocsLegal(this.props.clienteId);
    } else {
      this.setState({
        open: false,
        errors: {}
      });
    }
  };

  componentDidMount() {
    const { doc } = this.props;
    this.mapUserDetailsToState(doc);
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const newDocData = {
      tipodoc: this.state.tipodoc,
      descripcion: this.state.descripcion,
      consecutivo: this.state.consecutivo,
      clienteid: this.props.clienteId,
    };
    this.props.editDocLegal(newDocData, this.props.clienteId, this.props.docId);
  };

  render() {
    const { UI: { loading } } = this.props;
    const { errors } = this.state;

    return (
      <div>
        <Fragment>
          <div className="documento-editar-btn-container">
            <MyButton tip="Editar Documento" onClick={this.handleOpen}>
              <FaPencilAlt color="#03178C" className="documento-editar-icon" />
            </MyButton>
          </div>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            {this.state.aprobado && this.state.aprobado.mensaje ? (
              <div className='documento-editar-aprobado'>
                <div className='documento-editar-icono-container'>
                  <FaCheck color="#ffffff" className='documento-editar-icono' />
                </div>
                <h2 className='documento-editar-texto'>¡Documento Actualizado Exitosamente!</h2>
              </div>
            ) : (
              <>
                <DialogTitle>Editar Documento</DialogTitle>
                <DialogContent>
                  <form>
                    <FormControl className="documento-editar-formcontrol">
                      <InputLabel>Tipo Documento</InputLabel>
                      <Select
                        required
                        name="tipodoc"
                        id="tipodoc"
                        value={this.state.tipodoc}
                        onChange={this.handleChange}
                        fullWidth
                      >
                        <MenuItem value="">
                          <em></em>
                        </MenuItem>
                        <MenuItem value="Contrato">Contrato</MenuItem>
                        <MenuItem value="Factura">Factura</MenuItem>
                        <MenuItem value="Hoja de Vida">Hoja de Vida Ingeniero</MenuItem>
                        <MenuItem value="Registro Invima">Registro Invima</MenuItem>
                        <MenuItem value="Programa de Tecnovigilancia">Programa de Tecnovigilancia</MenuItem>
                        <MenuItem value="Programa de Capacitaciones">Programa de Capacitaciones</MenuItem>
                        <MenuItem value="Programa de Mantenimiento">Programa de Mantenimiento</MenuItem>
                        <MenuItem value="Certificado de Patron">Certificado de Patron</MenuItem>
                      </Select>
                      <FormHelperText>{errors.tipodoc}</FormHelperText>
                    </FormControl>

                    <TextField 
                      className="documento-editar-textfield"
                      required
                      type="text"
                      name="descripcion"
                      id="descripcion"
                      label="Descripción"
                      value={this.state.descripcion}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.descripcion}
                      error={errors.descripcion ? true : false}
                    />

                    <TextField 
                      className="documento-editar-textfield"
                      required
                      type="text"
                      name="consecutivo"
                      id="consecutivo"
                      label="Consecutivo"
                      value={this.state.consecutivo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.consecutivo}
                      error={errors.consecutivo ? true : false}
                    />

                    {/* Mostrar errores si existen */}
                    <div className="documento-editar-error-container">
                      {errors && errors.error && (
                        <p className="documento-editar-error-message">{errors.error}</p>
                      )}
                    </div>
                  </form>
                </DialogContent>
              </>
            )}

            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="documento-editar-form-icon" />
              </MyButton>

              {loading ? (
                <CircularProgress size={30} color="primary" className="documento-editar-progress" />
              ) : this.state.aprobado && this.state.aprobado.mensaje ? (
                <MyButton tip="Continuar" onClick={this.handleClose}>
                  <FaCheckCircle color="#20B90C" className="documento-editar-form-icon" />
                </MyButton>
              ) : (
                <MyButton 
                  tip="Actualizar Documento" 
                  onClick={this.handleSubmit}
                  disabled={!this.state.tipodoc || !this.state.descripcion || !this.state.consecutivo}
                >
                  <FaPlus color="#03178C" className="documento-editar-form-icon" />
                </MyButton>
              )}
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}

DocumentoEditar.propTypes = {
  editDocLegal: PropTypes.func.isRequired,
  getDocsLegal: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  doc: PropTypes.object.isRequired,
  docId: PropTypes.string.isRequired,
  clienteId: PropTypes.string.isRequired
};

const mapActionsToProps = { editDocLegal, clearErrors, getDocsLegal };

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(mapStateToProps, mapActionsToProps)(DocumentoEditar);
import React from 'react';

import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import './HojaDeVidaDocuPDF.css';
import alphalogo from '../../../utils/alphahorizontal.png'
import membrete from '../../../utils/hojadevidatemplate.jpg'


// Paleta de colores corporativa
const createStyles = (primaryColor, secondaryColor) => {
    const colors = {
      primary: primaryColor,
      secondary: secondaryColor,
      estados: {
        funcionando: {
          bg: '#ECFDF5',
          text: '#047857',
          border: '#059669'
        },
        correctivo: {
          bg: '#FFFBEB',
          text: '#B45309',
          border: '#D97706'
        },
        baja: {
          bg: '#FEF2F2',
          text: '#B91C1C',
          border: '#DC2626'
        }
      },
      gray: {
        50: '#F2F2F2',
        100: '#F1F4F8',
        200: '#E3E8EF',
        300: '#D2DDEB',
        800: '#3B506C',
      }
      
    };
  
    return StyleSheet.create({
      page: {
        backgroundColor: '#FFFFFF',
        padding: 20,
        paddingBottom: 40,
      },
      footer: {
        position: 'absolute',
        bottom: 25,
        left: 25,
        right: 25,
        borderTop: `1px solid ${colors.gray[200]}`,
        paddingTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      footerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 8,
      },
      footerLogo: {
        width: 100,
        height: 20,
        objectFit: 'contain',
      },
      footerText: {
        fontSize: 8,
        color: colors.gray[800],
      },
      footerWeb: {
        fontSize: 8,
        color: colors.primary,
        fontWeight: 'bold',
      },
      header: {
        backgroundColor: '#FFFFFF',
        padding: '12',
        borderRadius: 8,
        marginBottom: 10,
        border: `1px solid ${colors.gray[200]}`,
        
        boxShadow: '0 2px 4px rgba(27, 58, 107, 0.1)',
      },
      headerContent: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 15,
      },
      headerLeft: {
        width: '30%',
      },
      headerLogoContainer: {
        backgroundColor: 'white',
        padding: 6,
        borderRadius: 8,
        border: `1px solid ${colors.gray[200]}`,
      },
      headerLogo: {
        width: '100%',
        height: 32,
        objectFit: 'contain',
        borderRadius: 6,
      },
      headerCenter: {
        width: '30%',
      },
      headerInfo: {
        gap: 4,
      },
      headerInfoItem: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#f2f2f2',
        padding: '3 6',
        borderRadius: 4,
        marginBottom: 2,
      },
      headerLabel: {
        fontSize: 12,
        color: colors.primary,
        fontWeight: 'bold',
        marginRight: 4,
        width: '25%',
      },
      headerValue: {
        fontSize: 9,
        color: colors.primary,
        flex: 1,
      },
      headerRight: {
        width: '35%',
        backgroundColor: colors.primary,
        padding: '6 10',
        borderRadius: 6,
      },
      headerTitle: {
        fontSize: 9,
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
      },
      mainContent: {
        flexDirection: 'row',
        gap: 15,
      },
      leftColumn: {
        width: '35%',
      },
      rightColumn: {
        width: '65%',
      },
      equipmentImageSection: {
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 8,
        marginBottom: 15,
        alignItems: 'center',
        border: `1px solid ${colors.gray[200]}`,
        boxShadow: '0 1px 3px rgba(27, 58, 107, 0.05)',
      },
      equipmentImage: {
        width: '100%',
        height: 140,
        objectFit: 'contain',
        borderRadius: 6,
      },
      equipmentName: {
        fontSize: 9,
        color: colors.primary,
        fontWeight: 'bold',
        marginTop: 8,
        textAlign: 'center',
      },
      sectionTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 8,
        backgroundColor: colors.primary,
        padding: '6 10',
        borderRadius: 4,
      },
      sectionTitle: {
        fontSize: 8,
        color: 'white',
        fontWeight: 'bold',
      },
      clientSection: {
        backgroundColor: colors.gray[50],
        padding: 12,
        borderRadius: 8,
        marginBottom: 15,
        borderLeft: `3px solid ${colors.secondary}`,
        boxShadow: '0 1px 3px rgba(27, 58, 107, 0.05)',
      },
      infoSection: {
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 8,
        marginBottom: 8,
        border: `1px solid ${colors.gray[200]}`,
        boxShadow: '0 1px 3px rgba(27, 58, 107, 0.05)',
      },
      infoRow: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 4,
        paddingBottom: 2,
        borderBottom: `1px solid ${colors.gray[100]}`,
      },
      label: {
        fontSize: 7,
        color: colors.primary,
        fontWeight: 'bold',
        width: '35%',
      },
      value: {
        fontSize: 7,
        color: colors.gray[800],
        width: '65%',
      },
      specsGrid: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 2,
      },
      specBox: {
        flexDirection: 'row',
        width: '48%',
        padding: 4,
        backgroundColor: 'white',
        borderRadius: 4,
        marginBottom: 4,
        border: `1px solid ${colors.gray[200]}`,
      },
      specLabel: {
        fontSize: 7,
        color: colors.primary,
        fontWeight: 'bold',
        marginBottom: 2,
      },
      specValue: {
        marginLeft: 3,
        fontSize: 7,
        color: colors.gray[800],
      },
      statusIndicator: {
        padding: '4 12',
        borderRadius: 12,
        fontSize: 7,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 8,
        width: '70%',
      },
      statusFuncionando: {
        backgroundColor: colors.estados.funcionando.bg,
        color: colors.estados.funcionando.text,
        border: `1px solid ${colors.estados.funcionando.border}`,
      },
      statusCorrectivo: {
        backgroundColor: colors.estados.correctivo.bg,
        color: colors.estados.correctivo.text,
        border: `1px solid ${colors.estados.correctivo.border}`,
      },
      statusBaja: {
        backgroundColor: colors.estados.baja.bg,
        color: colors.estados.baja.text,
        border: `1px solid ${colors.estados.baja.border}`,
      },
      documentSection: {
        marginTop: 8,
        padding: '8 10',
        backgroundColor: 'white',
        borderRadius: 6,
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: `1px solid ${colors.gray[200]}`,
      },
      // Estilos para la segunda página
      fullWidthSection: {
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 8,
        marginBottom: 15,
        border: `1px solid ${colors.gray[200]}`,
        boxShadow: '0 1px 3px rgba(27, 58, 107, 0.05)',
      },
      contentText: {
        fontSize: 8,
        color: colors.gray[800],
        lineHeight: 1.5,
        textAlign: 'justify',
      },
      accessoryTable: {
        marginTop: 8,
        width: '100%',
      },
      tableHeader: {
        flexDirection: 'row',
        backgroundColor: colors.gray[100],
        borderRadius: 4,
        padding: '4 8',
        marginBottom: 4,
      },
      tableRow: {
        flexDirection: 'row',
        borderBottom: `1px solid ${colors.gray[200]}`,
        padding: '4 0',
      },
      tableCell: {
        fontSize: 7,
        padding: '2 8',
      },
      tableHeaderCell: {
        fontSize: 7,
        fontWeight: 'bold',
        color: colors.primary,
        padding: '2 8',
      },
      col1: { width: '40%' },
      col2: { width: '30%' },
      col3: { width: '30%' },
      noAccessoriesMsg: {
        fontSize: 8,
        color: colors.gray[800],
        fontStyle: 'italic',
        textAlign: 'center',
        padding: 10,
      }
    });
  };


  const HojaDeVidaDocuPDF2 = ({ equipo, cliente }) => {
    if (!equipo || !cliente) {
      return (
        <Document>
          <Page size="A4">
            <View>
              <Text>No hay información disponible</Text>
            </View>
          </Page>
        </Document>
      );
    }
  
    const styles = createStyles(cliente.primaryColor, cliente.secondaryColor);
    const headerLogo = cliente.plantillaType === 'prestador' ? cliente.imagenurl : cliente.logoUrl;
  
    const renderInfoRow = (label, value, unit = '') => (
      <View style={styles.infoRow}>
        <Text style={styles.label}>{label}:</Text>
        <Text style={styles.value}>{value ? `${value}${unit}` : 'No Registra'}</Text>
      </View>
    );
  
    const renderSpecBox = (label, value, unit = '') => (
      <View style={styles.specBox}>
        <Text style={styles.specLabel}>{label}</Text>
        <Text style={styles.specValue}>
          {value ? `${value}${unit}` : 'No Registra'}
        </Text>
      </View>
    );
  
    const Footer = () => (
      <View style={styles.footer} fixed>
        <View style={styles.footerLeft}>
          <Image src={alphalogo} style={styles.footerLogo} />
        </View>
        <View>
          <Text style={styles.footerWeb}>www.alphabiomedica.com</Text>
        </View>
      </View>
    );
  
    const getStatusStyle = (estado) => {
      const estadoLower = estado.toLowerCase().trim();
      if (estadoLower.includes('funcion')) return styles.statusFuncionando;
      if (estadoLower.includes('correctiv')) return styles.statusCorrectivo;
      if (estadoLower.includes('baja')) return styles.statusBaja;
      return styles.statusBaja;
    };

    // Renderiza la lista de accesorios
    const renderAccessories = () => {
      if (!equipo.credentials.accesorio) {
        return (
          <View>
            <Text style={styles.noAccessoriesMsg}>No hay accesorios registrados para este equipo</Text>
          </View>
        );
      }

      return (
        <View style={styles.accessoryTable}>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableHeaderCell, styles.col1]}>Nombre</Text>
            <Text style={[styles.tableHeaderCell, styles.col2]}>Referencia</Text>
            <Text style={[styles.tableHeaderCell, styles.col3]}>Serie</Text>
          </View>
          
          {equipo.credentials.accesorionombre1 && (
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.col1]}>{equipo.credentials.accesorionombre1 || 'No Registra'}</Text>
              <Text style={[styles.tableCell, styles.col2]}>{equipo.credentials.accesorioref1 || 'No Registra'}</Text>
              <Text style={[styles.tableCell, styles.col3]}>{equipo.credentials.accesorioserie1 || 'No Registra'}</Text>
            </View>
          )}
          
          {equipo.credentials.accesorionombre2 && (
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.col1]}>{equipo.credentials.accesorionombre2 || 'No Registra'}</Text>
              <Text style={[styles.tableCell, styles.col2]}>{equipo.credentials.accesorioref2 || 'No Registra'}</Text>
              <Text style={[styles.tableCell, styles.col3]}>{equipo.credentials.accesorioserie2 || 'No Registra'}</Text>
            </View>
          )}
          
          {equipo.credentials.accesorionombre3 && (
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, styles.col1]}>{equipo.credentials.accesorionombre3 || 'No Registra'}</Text>
              <Text style={[styles.tableCell, styles.col2]}>{equipo.credentials.accesorioref3 || 'No Registra'}</Text>
              <Text style={[styles.tableCell, styles.col3]}>{equipo.credentials.accesorioserie3 || 'No Registra'}</Text>
            </View>
          )}
        </View>
      );
    };
  
    return (
      <Document>
        {/* Primera página - Información existente */}
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <View style={styles.headerLeft}>
                <View style={styles.headerLogoContainer}>
                  <Image src={headerLogo} style={styles.headerLogo} />
                </View>
              </View>
              <View style={styles.headerRight}>
                <Text style={styles.headerTitle}>HOJA DE VIDA EQUIPO BIOMÉDICO</Text>
              </View>
              <View style={styles.headerCenter}>
                <View style={styles.headerInfo}>
                  <View style={styles.headerInfoItem}>
                    <Text style={styles.headerValue}>{cliente.nombre}</Text>
                  </View>
                  <View style={styles.headerInfoItem}>
                    
                    <Text style={styles.headerValue}>{cliente.ccnit}</Text>
                  </View>
                  <View style={styles.headerInfoItem}>
                    
                    <Text style={styles.headerValue}>{cliente.ciudad}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
  
          <View style={styles.mainContent}>
            <View style={styles.leftColumn}>
              <View style={styles.equipmentImageSection}>
                <Image src={equipo.credentials.imagenurl} style={styles.equipmentImage} />
                <Text style={styles.equipmentName}>{equipo.credentials.nombre}</Text>
                <View style={[styles.statusIndicator, getStatusStyle(equipo.credentials.estado)]}>
                  <Text>{equipo.credentials.estado}</Text>
                </View>
              </View>
  
              <View style={styles.infoSection}>
                <View style={styles.sectionTitleContainer}>
                  <Text style={styles.sectionTitle}>INFORMACIÓN INSTITUCIONAL</Text>
                </View>
                {renderInfoRow('PRESTADOR', cliente.nombre)}
                {renderInfoRow('SEDE', cliente.sede)}
                {renderInfoRow('CIUDAD', cliente.ciudad)}
                {renderInfoRow('CC/NIT', cliente.ccnit)}
                {renderInfoRow('DIRECCIÓN', cliente.direccion)}
                {renderInfoRow('CÓDIGO REPS', cliente.codigoreps)}
                {renderInfoRow('TELÉFONO', cliente.telefono)}
                {renderInfoRow('E-MAIL', cliente.email)}
              </View>
  
              <View style={styles.infoSection}>
                <View style={styles.sectionTitleContainer}>
                  <Text style={styles.sectionTitle}>INFORMACIÓN PROVEEDOR</Text>
                </View>
                {renderInfoRow('NOMBRE', equipo.credentials.proveedor)}
                {renderInfoRow('TELÉFONO', equipo.credentials.proveedortel)}
                {renderInfoRow('E-MAIL', equipo.credentials.proveedoremail)}
              </View>

              <View style={styles.infoSection}>
                <View style={styles.sectionTitleContainer}>
                  <Text style={styles.sectionTitle}>INFORMACIÓN FABRICANTE</Text>
                </View>
                {renderInfoRow('PAIS', equipo.credentials.pais)}
                {renderInfoRow('NOMBRE', equipo.credentials.nombrefabricante)}
                {renderInfoRow('TELÉFONO', equipo.credentials.telfabricante)}
                {renderInfoRow('E-MAIL', equipo.credentials.emailfabricante)}
              </View>
            </View>
  
            <View style={styles.rightColumn}>
              <View style={styles.infoSection}>
                <View style={styles.sectionTitleContainer}>
                  <Text style={styles.sectionTitle}>IDENTIFICACIÓN DEL EQUIPO</Text>
                </View>
                {renderInfoRow('ID INVENTARIO', equipo.credentials.idinventario)}
                {renderInfoRow('MARCA', equipo.credentials.marca)}
                {renderInfoRow('MODELO', equipo.credentials.modelo)}
                {renderInfoRow('SERIE', equipo.credentials.serie)}
                {renderInfoRow('UBICACIÓN', equipo.credentials.ubicacion)}
                {renderInfoRow('SERVICIO', equipo.credentials.servicio)}
                {renderInfoRow('TIPO', equipo.credentials.tipo)}
                {renderInfoRow('TIPO USO', equipo.credentials.tipouso)}
                {renderInfoRow('CLASIFICACIÓN', equipo.credentials.clasbiomedica)}
                {renderInfoRow('RIESGO', equipo.credentials.riesgo)}
                {renderInfoRow('REG. INVIMA', equipo.credentials.registroinvima)}
                {renderInfoRow('GARANTIA', equipo.credentials.garantia ? 'Sí' : 'No')}
              </View>

              <View style={styles.infoSection}>
                <View style={styles.sectionTitleContainer}>
                  <Text style={styles.sectionTitle}>INFORMACIÓN ADMINISTRATIVA</Text>
                </View>

                <View style={styles.specsGrid}>
                  {renderSpecBox('ADQUISICIÓN', equipo.credentials.adquisicion)}
                  {renderSpecBox('COSTO', equipo.credentials.costo, '$')}
                  {renderSpecBox('VIDA ÚTIL', equipo.credentials.vidautil, ' años')}
                  {renderSpecBox('FECHA FABRICACIÓN', equipo.credentials.fabricacion)}
                  {renderSpecBox('FECHA ADQUISICION', equipo.credentials.operacion)}
                  {renderSpecBox('FECHA INSTALACION', equipo.credentials.fechainstalacion)}
                  {renderSpecBox('FECHA INICIO GARANTIA', equipo.credentials.fechainiciogarantia)}
                  {renderSpecBox('FECHA FIN GARANTIA', equipo.credentials.fechafingarantia)}
                </View>
              </View>

              <View style={styles.infoSection}>
                <View style={styles.sectionTitleContainer}>
                  <Text style={styles.sectionTitle}>ESPECIFICACIONES TÉCNICAS</Text>
                </View>
                <View style={styles.specsGrid}>
                  {renderSpecBox('TECNOLOGÍA', equipo.credentials.tipotec)}
                  {renderSpecBox('FUENTE', equipo.credentials.fuente)}
                  {renderSpecBox('TENSIÓN MIN', equipo.credentials.tensionmin, 'V')}
                  {renderSpecBox('TENSIÓN MAX', equipo.credentials.tensionmax, 'V')}
                  {renderSpecBox('CORRIENTE MIN', equipo.credentials.corrientemin, 'A')}
                  {renderSpecBox('CORRIENTE MAX', equipo.credentials.corrientemax, 'A')}
                  {renderSpecBox('PRESIÓN', equipo.credentials.presion)}
                  {renderSpecBox('TEMPERATURA', equipo.credentials.temperatura, '°C')}
                  {renderSpecBox('CAPACIDAD', equipo.credentials.capacidad)}
                  {renderSpecBox('VELOCIDAD', equipo.credentials.velocidad)}
                  {renderSpecBox('POTENCIA', equipo.credentials.potencia, 'W')}
                  {renderSpecBox('FRECUENCIA', equipo.credentials.frecuencia, 'Hz')}
                  {renderSpecBox('DIMENSIONES', `${equipo.credentials.alto || 0} x ${equipo.credentials.largo || 0} x ${equipo.credentials.ancho || 0}`, 'cm')}
                  {renderSpecBox('PESO', equipo.credentials.peso, 'Kg')}
                </View>
                
                <View style={styles.documentSection}>
                  <Text style={styles.specLabel}>
                    Manual de Usuario: {equipo.credentials.urlmanual ? '✓ Disponible' : '✗ No Disponible'}
                  </Text>
                  <Text style={styles.specLabel}>
                    Factura: {equipo.credentials.urlfactura ? '✓ Disponible' : '✗ No Disponible'}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <Footer />
        </Page>

        {/* Segunda página - Información adicional */}
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <View style={styles.headerContent}>
              <View style={styles.headerLeft}>
                <View style={styles.headerLogoContainer}>
                  <Image src={headerLogo} style={styles.headerLogo} />
                </View>
              </View>
              <View style={styles.headerRight}>
                <Text style={styles.headerTitle}>HOJA DE VIDA EQUIPO BIOMÉDICO</Text>
              </View>
              <View style={styles.headerCenter}>
                <View style={styles.headerInfo}>
                  <View style={styles.headerInfoItem}>
                    <Text style={styles.headerValue}>{cliente.nombre}</Text>
                  </View>
                  <View style={styles.headerInfoItem}>
                    
                    <Text style={styles.headerValue}>{cliente.ccnit}</Text>
                  </View>
                  <View style={styles.headerInfoItem}>
                    
                    <Text style={styles.headerValue}>{cliente.ciudad}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>

          {/* Sección 1: Descripción del equipo */}
          <View style={styles.fullWidthSection}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>DESCRIPCIÓN DEL EQUIPO</Text>
            </View>
            <Text style={styles.contentText}>
              {equipo.credentials.descripcion || 'No hay descripción disponible para este equipo.'}
            </Text>
          </View>

          {/* Sección 2: Recomendaciones de uso */}
          <View style={styles.fullWidthSection}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>RECOMENDACIONES DE USO</Text>
            </View>
            <Text style={styles.contentText}>
              {equipo.credentials.recomendaciones || 'No hay recomendaciones disponibles para este equipo.'}
            </Text>
          </View>

          {/* Sección 3: Rutinas de mantenimiento */}
          <View style={styles.fullWidthSection}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>RUTINAS DE MANTENIMIENTO</Text>
            </View>
            <Text style={styles.contentText}>
              {equipo.credentials.rutinamto || 'No hay rutinas de mantenimiento disponibles para este equipo.'}
            </Text>
          </View>

          {/* Sección 4: Lista de accesorios */}
          <View style={styles.fullWidthSection}>
            <View style={styles.sectionTitleContainer}>
              <Text style={styles.sectionTitle}>ACCESORIOS</Text>
            </View>
            {renderAccessories()}
          </View>

          <Footer />
        </Page>
      </Document>
    );
  };

export default HojaDeVidaDocuPDF2
import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import alphalogo from '../../../utils/alphahorizontal.png';

const createStyles = (primaryColor = '#005695', secondaryColor = '#0067b2') => {
  // Color palette
  const colors = {
    primary: primaryColor,
    secondary: secondaryColor,
    gray: {
      50: '#F9FAFB',
      100: '#F3F4F6',
      200: '#E5E7EB',
      300: '#D1D5DB',
      800: '#1F2937',
    },
    white: '#FFFFFF'
  };

  return StyleSheet.create({
    // Base page setup con menos padding
    page: {
      backgroundColor: colors.white,
      padding: '20 20',
      paddingBottom: 55,
      fontFamily: 'Helvetica',
    },
    //Detalles Ejecucion
    detallesEjecucionGrid: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 2,
      padding: 2,
    },
    detalleItemContainer: {
      width: '48%',
      marginBottom: 4,
      backgroundColor:'#e2e2e2'
    },
    detalleItem1: {
      padding: 5,
      borderWidth: 1,
      borderColor: colors.gray[200],
      borderRadius: 4,
      backgroundColor: colors.gray[50],
      width: '100%',
      display: 'flex',
      flexDirection: 'column', // Asegurar que los elementos estén en columna
    },
    detalleLabel: {
      fontSize: 7,
      fontWeight: 'bold',
      color: colors.primary,
      marginBottom: 3,
      whiteSpace: 'nowrap',
      width: '100%', // Ocupar todo el ancho disponible
      // Mantén tu color de fondo para visualización
    },
    detalleValue: {
      fontSize: 8,
      color: colors.gray[800],
    },

    // Header styles más compacto
    header: {
      backgroundColor: colors.white,
      padding: 10,
      borderRadius: 6,
      marginBottom: 8,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    headerContent: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 10,
    },
    headerLogoContainer: {
      backgroundColor: colors.white,
      padding: 5,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: colors.gray[200],
      width: '25%',
    },
    headerLogo: {
      width: '100%',
      height: 30,
      objectFit: 'contain',
    },
    headerTitle: {
      backgroundColor: colors.primary,
      padding: '6 10',
      borderRadius: 4,
      width: '45%',
    },
    headerTitleText: {
      fontSize: 10,
      color: colors.white,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    headerInfo: {
      width: '25%',
      gap: 4,
    },
    headerInfoItem: {
      backgroundColor: colors.gray[50],
      padding: '4 6',
      borderRadius: 4,
      marginBottom: 2,
    },

    prestadorOptimizado: {
      flexDirection: 'row',
      padding: 4,
      alignItems: 'center',
    },
    prestadorLogoContainer: {
      width: '15%',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: 5,
    },
    prestadorLogo: {
      width: 50,
      height: 50,
      borderRadius: 4,
      objectFit: 'contain',
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    prestadorDetails: {
      width: '85%',
      flexDirection: 'column',
    },
    prestadorRow: {
      flexDirection: 'row',
      marginBottom: 8,
      
    },
    prestadorField: {
      width: '33%',
      flexDirection: 'row',
      alignItems: 'center',
      paddingRight: 2,
    },
    prestadorLabel: {
      fontSize: 8,
      fontWeight: 'bold',
      color: colors.primary,
      marginRight: 2,
    },
    prestadorValue: {
      fontSize: 8,
      color: colors.gray[800],
    },
    rowCompact: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 2,
      gap: 3,
    },
    labelCompact: {
      fontSize: 6,
      color: colors.gray[800],
      fontWeight: 'bold',
      width: '35%',
    },
    valueCompact: {
      fontSize: 6,
      color: colors.gray[800],
      flex: 1,
    },

    // Layout más compacto
    content: {
      flexDirection: 'row',
      gap: 8,
    },
    leftColumn: {
      width: '42%',
    },
    rightColumn: {
      width: '58%',
    },
    
    // Section Styles más compacto
    section: {
      backgroundColor: colors.white,
      padding: 3,
      borderRadius: 6,
      marginBottom: 6,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    sectionTitle: {
      backgroundColor: colors.primary,
      padding: '4 8',
      borderRadius: 3,
      marginBottom: 3,
    },
    sectionTitleText: {
      fontSize: 8,
      color: colors.white,
      fontWeight: 'bold',
    },
    
    // Equipment Section más compacto
    equipmentContent: {
      flexDirection: 'row',
      gap: 6,
    },
    equipmentImageContainer: {
      width: '30%',
    },
    equipmentImage: {
      width: '100%',
      height: 80,
      borderRadius: 4,
      objectFit: 'contain',
      borderWidth: 1,
      borderColor: colors.gray[200],
      backgroundColor: colors.gray[50],
      padding: 2,
    },
    equipmentInfo: {
      flex: 1,
      gap: 1,
    },
    
    // Data Display más compacto
    row: {
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 3,
      gap: 4,
    },
    label: {
      fontSize: 7,
      color: colors.gray[800],
      fontWeight: 'bold',
      width: '35%',
    },
    value: {
      fontSize: 7,
      color: colors.gray[800],
      flex: 1,
    },
    
    // Causales más compacto
    causalesGrid: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: 4,
    },
    causalItem: {
      width: '48%',
      backgroundColor: colors.gray[50],
      padding: '4 6',
      borderRadius: 3,
      marginBottom: 2,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    causalLabel: {
      fontSize: 7,
      color: colors.gray[800],
      fontWeight: 'bold',
      flex: 1,
    },
    causalValueTrue: {
      fontSize: 7,
      fontWeight: 'bold',
      marginLeft: 4,
      color: colors.primary,
    },
    causalValueFalse: {
      fontSize: 7,
      fontWeight: 'bold',
      marginLeft: 4,
      color: colors.gray[800],
    },

    // Servicio Técnico más compacto
    servicioTecnico: {
      marginBottom: 4,
    },
    actividad: {
      backgroundColor: colors.gray[50],
      padding: 4,
      borderRadius: 3,
      marginBottom: 4,
      borderWidth: 1,
      borderColor: colors.gray[200],
      minHeight:'50px',
      maxHeight:'50px'
    },
    actividadLabel: {
      fontSize: 7,
      color: colors.gray[800],
      fontWeight: 'bold',
      marginBottom: 2,
    },
    actividadText: {
      fontSize: 7,
      color: colors.gray[800],
      lineHeight: 1.3,
    },
    
    repuestosTable: {
      width: '100%',
      borderWidth: 1,
      borderColor: colors.gray[200],
      borderRadius: 4,
    },
    repuestoHeader: {
      flexDirection: 'row',
      backgroundColor:colors.gray[200],
      padding: 0,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
    },
    repuestoHeaderText: {
      fontSize: 7,
      fontWeight: 'bold',
      color: colors.gray[800],
      textAlign: 'center',
    },
    repuestoRow: {
      flexDirection: 'row',
      borderTopWidth: 1,
      borderColor: colors.gray[200],
      backgroundColor: colors.gray[50],
    },
    repuestoCol1: {
      width: '40%',
      padding: 4,
      borderRightWidth: 1,
      borderColor: colors.gray[200],
    },
    repuestoCol2: {
      width: '15%',
      padding: 4,
      borderRightWidth: 1,
      borderColor: colors.gray[200],
      alignItems: 'center',
    },
    repuestoCol3: {
      width: '20%',
      padding: 4,
      borderRightWidth: 1,
      borderColor: colors.gray[200],
      alignItems: 'center',
    },
    repuestoCol4: {
      width: '25%',
      padding: 4,
      alignItems: 'center',
    },
    repuestoText: {
      fontSize: 8,
      color: colors.gray[800],
    },
    repuestoTotal: {
      backgroundColor: colors.gray[200],
      padding: 2,
      alignItems: 'flex-end',
      borderBottomLeftRadius: 3,
      borderBottomRightRadius: 3,
    },
    totalText: {
      fontSize: 8,
      fontWeight: 'bold',
      color: colors.primary,
    },
    
    // Tiempos más compacto
    timeRow: {
      flexDirection: 'column',
      gap: 4,
      marginBottom: 6,
    },
    timeItem: {
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.gray[50],
      padding: '4 6',
      borderRadius: 3,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    
    // Personal más compacto
    personnelSection: {
      marginTop: 4,
      marginBottom: 10,
    },
    personnelContainer: {
      flexDirection: 'row',
      gap: 4,
      backgroundColor: colors.white,
      padding: 1,
      height: 70,
    },
    personnelHalf: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: colors.white,
      padding: 1,
      borderRadius: 3,
      borderWidth: 1,
      borderColor: colors.gray[200],
      gap: 6,
    },
    signatureContainer: {
      width: '40%',
      height: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: colors.white,
      borderRadius: 3,
      borderWidth: 1,
      borderColor: colors.gray[200],
    },
    signatureImage: {
      width: '90%',
      height: '80%',
      objectFit: 'contain',
    },
    noSignatureText: {
      fontSize: 7,
      color: colors.gray[800],
      fontStyle: 'italic',
    },
    personnelInfo: {
      flex: 1,
      gap: 2,
    },
    infoItem: {
      alignItems: 'center',
      justifyContent: 'center',
      paddingVertical: 2,
    },
    labelPersonnel: {
      fontSize: 8,
      color: colors.gray[800],
      fontWeight: 'bold',
      marginBottom: 2,
    },
    roleText: {
      fontSize: 7,
      color: colors.primary,
    },
    
    // Footer más compacto
    footer: {
      position: 'absolute',
      bottom: 15,
      left: 25,
      right: 25,
      borderTopWidth: 1,
      borderColor: colors.gray[200],
      paddingTop: 3,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    footerLogo: {
      width: 100,
      height: 20,
      objectFit: 'contain',
    },
    footerWeb: {
      fontSize: 8,
      color: colors.primary,
      fontWeight: 'bold',
    },
    
    // Estado equipo más compacto
    estadoEquipo: {
      flexDirection: 'row',
      gap: 6,
      marginTop: 3,
    },
    estadoItem: {
      flex: 1,
      backgroundColor: colors.gray[50],
      padding: '4 6',
      borderRadius: 3,
      borderWidth: 1,
      borderColor: colors.gray[200],
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    
    // Layout para detalles de ejecución
    detallesEjecucionContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      gap: 4,
    },
    detalleItem: {
      width: '48%',
    }
  });
};

const ReporteCorrDocuPDF = ({ equipo, cliente, mtopreventivo }) => {
  if (!equipo || !cliente || !mtopreventivo) {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text>No hay información disponible</Text>
          </View>
        </Page>
      </Document>
    );
  }

  const styles = createStyles(cliente.primaryColor, cliente.secondaryColor);
  const imagenUrl = cliente.imagenurl || 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';
  const imagenEquipoUrl = equipo.credentials.imagenurl || 'https://firebasestorage.googleapis.com/v0/b/alphaplatform.appspot.com/o/noImg.png?alt=media';

  const Footer = () => (
    <View style={styles.footer} fixed>
      <View>
        <Image src={alphalogo} style={styles.footerLogo} />
      </View>
      <View>
        <Text style={styles.footerWeb}>www.alphabiomedica.com</Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.headerContent}>
            <View style={styles.headerLogoContainer}>
              <Image src={cliente.imagenurl} style={styles.headerLogo} />
            </View>
            <View style={styles.headerTitle}>
              <Text style={styles.headerTitleText}>REPORTE DE MANTENIMIENTO CORRECTIVO</Text>
            </View>
            <View style={styles.headerInfo}>
              <View style={styles.headerInfoItem}>
                <Text style={styles.value}>Reporte No: {(mtopreventivo.mtocorrectivoid || '').toString().slice(-6) || 'N/A'}</Text>
              </View>
              <View style={styles.headerInfoItem}>
                <Text style={styles.value}>Fecha: {mtopreventivo.fecha || 'N/A'}</Text>
              </View>
            </View>
          </View>
        </View>

       
        {/* Información del Prestador (Optimizada) */}
<View style={styles.section}>
  <View style={styles.sectionTitle}>
    <Text style={styles.sectionTitleText}>INFORMACIÓN DEL PRESTADOR - {cliente.nombre || 'N/A'} </Text>
  </View>
  <View style={styles.prestadorOptimizado}>
    <View style={styles.prestadorLogoContainer}>
      <Image src={imagenUrl} style={styles.prestadorLogo} />
    </View>
    <View style={styles.prestadorDetails}>
      <View style={styles.prestadorRow}>
        
        <View style={styles.prestadorField}>
          <Text style={styles.prestadorLabel}>DIRECCIÓN:</Text>
          <Text style={styles.prestadorValue}>{cliente.direccion || 'N/A'}</Text>
        </View>
        <View style={styles.prestadorField}>
          <Text style={styles.prestadorLabel}>SEDE:</Text>
          <Text style={styles.prestadorValue}>{cliente.sede || 'N/A'}</Text>
        </View>
      </View>
      <View style={styles.prestadorRow}>
        <View style={styles.prestadorField}>
          <Text style={styles.prestadorLabel}>CC/NIT:</Text>
          <Text style={styles.prestadorValue}>{cliente.ccnit || 'N/A'}</Text>
        </View>
        <View style={styles.prestadorField}>
          <Text style={styles.prestadorLabel}>TELÉFONO:</Text>
          <Text style={styles.prestadorValue}>{cliente.telefono || 'N/A'}</Text>
        </View>
        <View style={styles.prestadorField}>
          <Text style={styles.prestadorLabel}>CÓDIGO REPS:</Text>
          <Text style={styles.prestadorValue}>{cliente.codigoreps || 'N/A'}</Text>
        </View>
      </View>
      <View style={styles.prestadorRow}>
        <View style={styles.prestadorField}>
          <Text style={styles.prestadorLabel}>CIUDAD:</Text>
          <Text style={styles.prestadorValue}>{cliente.ciudad || 'N/A'}</Text>
        </View>
        <View style={[styles.prestadorField, { width: '65%' }]}>
          <Text style={styles.prestadorLabel}>EMAIL:</Text>
          <Text style={styles.prestadorValue}>{cliente.email || 'N/A'}</Text>
        </View>
      </View>
    </View>
  </View>
</View>

        {/* Main Content */}
        <View style={styles.content}>
          {/* Left Column */}
          <View style={styles.leftColumn}>
            {/* Detalles de la solicitud */}
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>DETALLES DE LA SOLICITUD</Text>
              </View>
              <View style={styles.timeRow}>
                <View style={styles.timeItem}>
                  <Text style={styles.label}>FECHA SOLICITUD:</Text>
                  <Text style={styles.value}>{mtopreventivo.fechasolicitud || 'N/A'}</Text>
                </View>
                <View style={styles.timeItem}>
                  <Text style={styles.label}>HORA SOLICITUD:</Text>
                  <Text style={styles.value}>{mtopreventivo.horasolicitud || 'N/A'}</Text>
                </View>
                <View style={styles.timeItem}>
                  <Text style={styles.label}>FALLA REPORTADA:</Text>
                  <Text style={styles.value}>{mtopreventivo.fallareportada || 'N/A'}</Text>
                </View>
              </View>
            </View>

            {/* Información del equipo */}
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>INFORMACIÓN DEL EQUIPO</Text>
              </View>
              <View style={styles.equipmentContent}>
                <View style={styles.equipmentImageContainer}>
                  <Image src={imagenEquipoUrl} style={styles.equipmentImage} />
                </View>
                <View style={styles.equipmentInfo}>
                  <View style={styles.row}>
                    <Text style={styles.label}>NOMBRE:</Text>
                    <Text style={styles.value}>{equipo.credentials.nombre || 'N/A'}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>ID INVENTARIO:</Text>
                    <Text style={styles.value}>{equipo.credentials.idinventario || 'N/A'}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>MARCA:</Text>
                    <Text style={styles.value}>{equipo.credentials.marca || 'N/A'}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>MODELO:</Text>
                    <Text style={styles.value}>{equipo.credentials.modelo || 'N/A'}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>SERIE:</Text>
                    <Text style={styles.value}>{equipo.credentials.serie || 'N/A'}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>UBICACIÓN:</Text>
                    <Text style={styles.value}>{equipo.credentials.ubicacion || 'N/A'}</Text>
                  </View>
                  <View style={styles.row}>
                    <Text style={styles.label}>SERVICIO:</Text>
                    <Text style={styles.value}>{equipo.credentials.servicio || 'N/A'}</Text>
                  </View>
                </View>
              </View>
            </View>

            {/* Detalles de Ejecución */}
<View style={styles.section}>
  <View style={styles.sectionTitle}>
    <Text style={styles.sectionTitleText}>DETALLES DE EJECUCIÓN</Text>
  </View>
  
  <View style={styles.detallesEjecucionGrid}>
    <View style={styles.detalleItemContainer}>
      <View style={styles.detalleItem1}>
        <Text style={styles.detalleLabel}>FECHA INICIAL:</Text>
        <Text style={styles.detalleValue}>{mtopreventivo.fecha || 'N/A'}</Text>
      </View>
    </View>
    
    <View style={styles.detalleItemContainer}>
      <View style={styles.detalleItem1}>
        <Text style={styles.detalleLabel}>FECHA FINAL:</Text>
        <Text style={styles.detalleValue}>{mtopreventivo.fechafinal || 'N/A'}</Text>
      </View>
    </View>
    <View style={styles.detalleItemContainer}>
      <View style={styles.detalleItem1}>
        <Text style={styles.detalleLabel}>HORA INICIO:</Text>
        <Text style={styles.detalleValue}>{mtopreventivo.horainicio || 'N/A'}</Text>
      </View>
    </View>
    <View style={styles.detalleItemContainer}>
      <View style={styles.detalleItem1}>
        <Text style={styles.detalleLabel}>HORA FINAL:</Text>
        <Text style={styles.detalleValue}>{mtopreventivo.horafinal || 'N/A'}</Text>
      </View>
    </View>
    <View style={styles.detalleItemContainer}>
      <View style={styles.detalleItem1}>
        <Text style={styles.detalleLabel}>TIEMPO RESPUESTA:</Text>
        <Text style={styles.detalleValue}>{mtopreventivo.tiemporespuestahora || 'N/A'}</Text>
      </View>
    </View>
    {mtopreventivo.solicitudid && (
      <View style={styles.detalleItemContainer}>
        <View style={styles.detalleItem1}>
          <Text style={styles.detalleLabel}>NO. SOLICITUD:</Text>
          <Text style={styles.detalleValue}>{mtopreventivo.solicitudid?.slice(-6) || 'N/A'}</Text>
        </View>
      </View>
    )}
  </View>
</View>
          </View>

          {/* Right Column */}
          <View style={styles.rightColumn}>
            {/* Causales de Falla */}
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>CAUSALES DE FALLA</Text>
              </View>
              <View style={styles.causalesGrid}>
                {[
                  { label: 'OBSOLESCENCIA', value: mtopreventivo.obsolescencia },
                  { label: 'MALA OPERACIÓN', value: mtopreventivo.malaoperacion },
                  { label: 'MALA INSTALACIÓN', value: mtopreventivo.malainst },
                  { label: 'DESGASTE', value: mtopreventivo.desgaste },
                  { label: 'CAÍDA', value: mtopreventivo.caida },
                  { label: 'CORTO CIRCUITO', value: mtopreventivo.corto },
                  { label: 'ACCESORIOS', value: mtopreventivo.accesorios },
                  { label: 'PACIENTE', value: mtopreventivo.paciente },
                  { label: 'SIN FALLA', value: mtopreventivo.sinfalla },
                  { label: 'DESCONOCIDO', value: mtopreventivo.desconocido }
                ].map((item, index) => (
                  <View key={index} style={styles.causalItem}>
                    <Text style={styles.causalLabel}>{item.label}</Text>
                    <Text style={item.value ? styles.causalValueTrue : styles.causalValueFalse}>
                      {item.value ? 'Si' : 'No'}
                    </Text>
                  </View>
                ))}
                {mtopreventivo.otro && (
                  <View style={[styles.causalItem, { width: '100%' }]}>
                    <Text style={styles.causalLabel}>OTRO:</Text>
                    <Text style={styles.value}>{mtopreventivo.otro}</Text>
                  </View>
                )}
              </View>
            </View>

            {/* Servicio Técnico */}
            <View style={styles.section}>
              <View style={styles.sectionTitle}>
                <Text style={styles.sectionTitleText}>SERVICIO TÉCNICO</Text>
              </View>
              <View style={styles.servicioTecnico}>
                <View style={styles.actividad}>
                  <Text style={styles.actividadLabel}>DESCRIPCIÓN DE LA FALLA:</Text>
                  <Text style={styles.actividadText}>{mtopreventivo.descfalla || 'No registra'}</Text>
                </View>
                <View style={styles.actividad}>
                  <Text style={styles.actividadLabel}>ACTIVIDAD REALIZADA:</Text>
                  <Text style={styles.actividadText}>{mtopreventivo.descactividad || 'No registra'}</Text>
                </View>
                <View style={styles.actividad}>
                  <Text style={styles.actividadLabel}>OBSERVACIONES:</Text>
                  <Text style={styles.actividadText}>{mtopreventivo.observaciones || 'No registra'}</Text>
                </View>
                <View style={styles.estadoEquipo}>
                  <View style={styles.estadoItem}>
                    <Text style={styles.label}>¿EQUIPO APTO PARA USO?</Text>
                    <Text style={mtopreventivo.apto ? styles.causalValueTrue : styles.causalValueFalse}>
                      {mtopreventivo.apto ? 'Si' : 'No'}
                    </Text>
                  </View>
                  <View style={styles.estadoItem}>
                    <Text style={styles.label}>¿EQUIPO RETIRADO?</Text>
                    <Text style={mtopreventivo.retirado ? styles.causalValueTrue : styles.causalValueFalse}>
                      {mtopreventivo.retirado ? 'Si' : 'No'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>

            
          </View>
        </View>

        {/* Repuestos Utilizados */}
        {/* Repuestos Utilizados */}
<View style={styles.section}>
  <View style={styles.sectionTitle}>
    <Text style={styles.sectionTitleText}>REPUESTOS UTILIZADOS</Text>
  </View>
  <View style={styles.repuestosTable}>
    {/* Encabezados de tabla */}
    <View style={styles.repuestoHeader}>
      <View style={styles.repuestoCol1}>
        <Text style={styles.repuestoHeaderText}>REPUESTO</Text>
      </View>
      <View style={styles.repuestoCol2}>
        <Text style={styles.repuestoHeaderText}>CANT.</Text>
      </View>
      <View style={styles.repuestoCol3}>
        <Text style={styles.repuestoHeaderText}>COSTO</Text>
      </View>
      <View style={styles.repuestoCol4}>
        <Text style={styles.repuestoHeaderText}>SUBTOTAL</Text>
      </View>
    </View>
    
    {/* Filas de repuestos */}
    
      <View style={styles.repuestoRow}>
        <View style={styles.repuestoCol1}>
          <Text style={styles.repuestoText}>{mtopreventivo.nombrerepuesto || 'No Registra'}</Text>
        </View>
        <View style={styles.repuestoCol2}>
          <Text style={styles.repuestoText}>{mtopreventivo.cantidad || '0'}</Text>
        </View>
        <View style={styles.repuestoCol3}>
          <Text style={styles.repuestoText}>{mtopreventivo.costo || '0'}</Text>
        </View>
        <View style={styles.repuestoCol4}>
          <Text style={styles.repuestoText}>{mtopreventivo.subtotal || '0'}</Text>
        </View>
      </View>
    
    
   
      <View style={styles.repuestoRow}>
        <View style={styles.repuestoCol1}>
          <Text style={styles.repuestoText}>{mtopreventivo.nombrerepuesto1 || 'No Registra'} </Text>
        </View>
        <View style={styles.repuestoCol2}>
          <Text style={styles.repuestoText}>{mtopreventivo.cantidad1 || '0'}</Text>
        </View>
        <View style={styles.repuestoCol3}>
          <Text style={styles.repuestoText}>{mtopreventivo.costo1 || '0'}</Text>
        </View>
        <View style={styles.repuestoCol4}>
          <Text style={styles.repuestoText}>{mtopreventivo.subtotal1 || '0'}</Text>
        </View>
      </View>
   
    
    
      <View style={styles.repuestoRow}>
        <View style={styles.repuestoCol1}>
          <Text style={styles.repuestoText}>{mtopreventivo.nombrerepuesto2 || 'No Registra'}</Text>
        </View>
        <View style={styles.repuestoCol2}>
          <Text style={styles.repuestoText}>{mtopreventivo.cantidad2 || '0'}</Text>
        </View>
        <View style={styles.repuestoCol3}>
          <Text style={styles.repuestoText}>{mtopreventivo.costo2 || '0'}</Text>
        </View>
        <View style={styles.repuestoCol4}>
          <Text style={styles.repuestoText}>{mtopreventivo.subtotal2 || '0'}</Text>
        </View>
      </View>
    
    
    {/* Total invertido */}
    <View style={styles.repuestoTotal}>
      <Text style={styles.totalText}>TOTAL INVERTIDO: {mtopreventivo.totalrepuesto || '0'}</Text>
    </View>
  </View>
</View>

        {/* Personal (más compacto) */}
<View style={styles.section}>
  <View style={styles.sectionTitle}>
    <Text style={styles.sectionTitleText}>INFORMACIÓN DEL PERSONAL</Text>
  </View>
  <View style={styles.personnelContainer}>
    {/* Receptor */}
    <View style={styles.personnelHalf}>
      <View style={styles.signatureContainer}>
        {(mtopreventivo.metodoRecepcion === 'manual' || !mtopreventivo.metodoRecepcion) && (
          cliente.imagenfirma ? (
            <Image src={cliente.imagenfirma} style={styles.signatureImage} />
          ) : (
            <Text style={styles.noSignatureText}>Pendiente firma</Text>
          )
        )}
        
        {(mtopreventivo.metodoRecepcion === 'firma' || mtopreventivo.metodoRecepcion === 'imagen') && (
          mtopreventivo.imagenfirma ? (
            <Image src={mtopreventivo.imagenfirma} style={styles.signatureImage} />
          ) : (
            <Text style={styles.noSignatureText}>Pendiente firma</Text>
          )
        )}
        
        {mtopreventivo.metodoRecepcion === 'usuario' && (
          mtopreventivo.imagenfirma ? (
            <Image src={mtopreventivo.imagenfirma} style={styles.signatureImage} />
          ) : (
            <Text style={styles.noSignatureText}>Pendiente firma</Text>
          )
        )}
      </View>
      <View style={styles.personnelInfo}>
        <View style={styles.infoItem}>
          <Text style={styles.labelPersonnel}>{mtopreventivo.firmarecibe || 'N/A'}</Text>
          <Text style={styles.roleText}>{mtopreventivo.cargorecibe || 'Receptor'}</Text>
        </View>
      </View>
    </View>

    {/* Ingeniero */}
    <View style={styles.personnelHalf}>
      <View style={styles.signatureContainer}>
        {mtopreventivo.firmaIngeniero ? (
          <Image src={mtopreventivo.firmaIngeniero} style={styles.signatureImage} />
        ) : (
          <Text style={styles.noSignatureText}>Pendiente firma</Text>
        )}
      </View>
      <View style={styles.personnelInfo}>
        <View style={styles.infoItem}>
          <Text style={styles.labelPersonnel}>
            {mtopreventivo.handle || mtopreventivo.handleuser || 'N/A'}
          </Text>
          <Text style={styles.roleText}>Ingeniero Biomédico</Text>
        </View>
      </View>
    </View>
  </View>
</View>

{/* Footer */}
<Footer />
</Page>
    </Document>
  );
};

export default ReporteCorrDocuPDF;
import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';


import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaLightbulb, FaRobot, FaInfoCircle } from "react-icons/fa";

import { usoRecomendacionesGemini } from '../../../redux/actions/dataactions';

import './RecomendacionesUsoGemini.css'

export class RecomendacionesUsoGemini extends Component {

  constructor() {
    super();
    this.state = {
      nombre: '',
      marca: '',
      modelo: '',
      errors: {},
      aprobado: {},
      open: false
    };

  };
  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  componentDidMount() {
    const { equipo } = this.props;
    this.setState({
      nombre: equipo.nombre ? equipo.nombre : '',
      marca: equipo.marca ? equipo.marca : '',
      modelo: equipo.modelo ? equipo.modelo : '',
    });
  }

  handleOpen = () => {
    this.setState({
      open: true, errors: {},
      aprobado: {}
    });
    const dataequipo = {
      nombre: this.state.nombre,
      marca: this.state.marca,
      modelo: this.state.modelo
    }
    console.log(this.props.tipouser)
    console.log(dataequipo);
    this.props.usoRecomendacionesGemini(dataequipo);
  };

  handleClose = () => {
    this.setState({
      open: false, errors: {},
      aprobado: {}
    });
    console.log(this.props.recomendacionesIa)
  };

  render() {
    const { UI: { loading } } = this.props;
    const { errors } = this.state;
    const { recomendacionIa, equipo } = this.props;
    // Verificamos si recomendaciones existe como string y no está vacío
    const tieneRecomendaciones = equipo && equipo.recomendaciones && typeof equipo.recomendaciones === 'string' && equipo.recomendaciones.trim() !== '';

    console.log(this.props.tipouser)
    return (
      <div>
        <Fragment>
          <MyButton
            tip="Recomendaciones de Uso"
            onClick={this.handleOpen}
          >
            <FaInfoCircle color="#03178C" className="icon-crearcliente" />
          </MyButton>


          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
            <div className='container-title-recomendaciones'>

            <h2 className='title-recomendaciones'>Recomendaciones de Uso del equipo</h2>
            </div>
            </DialogTitle>

            {loading ? (
              <CircularProgress size={30} color="primary" className="login-progress-recomendation-uso" />
            ) : (
              <div>
                <DialogContent>
                  <div className='container-recomendaciones-uso'>
                    {/* Mostrar recomendaciones existentes si las hay */}
                    {tieneRecomendaciones && (
                      <div className="recomendaciones-existentes-uso">
                        <div className="seccion-cabecera-uso">
                          <FaInfoCircle className="icon-seccion-uso" />
                          <Typography variant="h6" className="seccion-titulo-uso">
                            Recomendaciones de Uso Registradas
                          </Typography>
                        </div>
                        
                        <div className="recomendacion-item-uso">
                          <h3 className='text-titulo-cliente-uso'>Recomendaciones de Uso</h3>
                          <p className='recomendacion-text-uso'>{equipo.recomendaciones}</p>
                        </div>
                        
                        <Divider style={{ margin: '20px 0' }} />
                      </div>
                    )}

                    {/* Mostrar recomendaciones de IA */}
                    {recomendacionIa !== undefined && recomendacionIa.length > 0 && (
                      <div className="recomendaciones-ia-uso">
                        <div className="seccion-cabecera-uso">
                          <FaRobot className="icon-seccion-uso" />
                          <Typography variant="h6" className="seccion-titulo-uso">
                            Recomendaciones de Uso Generadas por IA
                          </Typography>
                        </div>
                        {recomendacionIa.map((recomendacion, index) => (
                          <div key={`ia-${index}`} className="recomendacion-item-uso">
                            <h3 className='text-titulo-cliente-uso'>{recomendacion.titulo}</h3>
                            <p className='recomendacion-text-uso'>{recomendacion.descripcion}</p>
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Mostrar mensaje si no hay ninguna recomendación */}
                    {(!tieneRecomendaciones && (!recomendacionIa || recomendacionIa.length === 0)) && (
                      <div className="no-recomendaciones-uso">
                        <Typography variant="body1">
                          No hay recomendaciones de uso disponibles para este equipo.
                        </Typography>
                      </div>
                    )}
                  </div>
                </DialogContent>
              </div>
            )}

            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
              </MyButton>
            </DialogActions>

          </Dialog>
        </Fragment>
      </div >
    )
  }
}

RecomendacionesUsoGemini.propTypes = {
  usoRecomendacionesGemini: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  tipouser: PropTypes.string.isRequired,
  recomendacionesIa: PropTypes.array.isRequired
};

const mapActionsToProps = { usoRecomendacionesGemini };

const mapStateToProps = (state) => ({
  equipo: state.data.equipo.credentials,
  UI: state.UI,
  tipouser: state.user.credentials.tipouser,
  recomendacionIa: state.data.recomendacionIa,
});

export default connect(mapStateToProps, mapActionsToProps)(RecomendacionesUsoGemini);
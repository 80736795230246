import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MyButton from '../../../utils/MyButton';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FaTrashAlt, FaArrowLeft, FaCheckCircle, FaTrash } from "react-icons/fa";

import { deleteDocLegal, getDocsLegal,clearErrors } from '../../../redux/actions/dataactions';

import './DocumentoEliminar.css';

export class DocumentoEliminar extends Component {
  constructor() {
    super();
    this.state = {
      open: false,
      errors: {},
      aprobado: {}
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
  
    if (props.UI && props.UI.errors) {
      newState.errors = props.UI.errors;
    }
  
    if (props.UI && props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }
  
    return Object.keys(newState).length > 0 ? newState : null;
  }

  handleOpen = () => {
     this.setState({ open: true,
         
         aprobado: {},
         errors: {} });
   };
       
   handleClose = () => {
     if (this.props.clearErrors) {
       this.props.clearErrors();
     }
     
     if (this.state.aprobado && this.state.aprobado.mensaje) {
       console.log('Documento Eliminado exitosamente:', this.state.aprobado);
       
       this.setState({
         open: false,
         
         aprobado: {},
         errors: {}
       });
       this.props.getDocsLegal(this.props.clienteId);
 
     } else {
       this.setState({
         open: false,
         
         errors: {}
       });
     }
   };

  deleteDocument = () => {
    console.log('Eliminando documento:', {
      docId: this.props.docId,
      clienteId: this.props.clienteId
    });
    
    this.props.deleteDocLegal(this.props.docId, this.props.clienteId);
  }

  render() {
    const { UI } = this.props;
    const loading = UI && UI.loading;
    const { errors } = this.state;

    return (
        <div>
        <Fragment>
          <div className="documento-eliminar-btn-container">
            <MyButton tip="Eliminar Documento" onClick={this.handleOpen}>
              <FaTrashAlt color="#03178C" className="documento-eliminar-icon" />
            </MyButton>
          </div>

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            {this.state.aprobado && this.state.aprobado.mensaje ? (
              <div className='documento-eliminar-aprobado'>
                <div className='documento-eliminar-icono-container'>
                  <FaTrash color="#ffffff" className='documento-eliminar-icono' />
                </div>
                <h2 className='documento-eliminar-texto'>Documento Borrado Exitosamente</h2>
              </div>
            ) : (
              <div>
                <DialogTitle>
                  ¿Estás seguro de eliminar este Documento?
                </DialogTitle>
                
                {/* Mostrar errores si existen */}
                {errors && errors.error && (
                  <div className="documento-eliminar-error-container">
                    <p className="documento-eliminar-error-message">{errors.error}</p>
                  </div>
                )}
              </div>
            )}

            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="documento-eliminar-form-icon" />
              </MyButton>
              
              {loading ? (
                <CircularProgress size={30} color="primary" className="documento-eliminar-progress" />
              ) : (
                this.state.aprobado && this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#f9044e" className="documento-eliminar-form-icon" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Documento" onClick={this.deleteDocument}>
                    <FaTrash color="#03178C" className="documento-eliminar-form-icon" />
                  </MyButton>
                )
              )}
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}

DocumentoEliminar.propTypes = {
  deleteDocLegal: PropTypes.func.isRequired,
  getDocsLegal:PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  docId: PropTypes.string.isRequired,
  clienteId: PropTypes.string.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  deleteDocLegal,
  clearErrors,
  getDocsLegal
};

export default connect(mapStateToProps, mapActionsToProps)(DocumentoEliminar);
import React, { Component } from 'react';
import './DashboardAsistencial.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getIndicadoresPrestador,getIndicadoresPrestadorNew} from '../../../redux/actions/dataactions';


import CircularProgress from '@material-ui/core/CircularProgress';
import { Bar,  Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';

import {FaTasks,FaRuler,FaCogs,FaRegCalendarCheck,FaLaptopMedical,FaWrench,FaRocket,FaPhoneSquareAlt, FaRegClock} from "react-icons/fa";
import { Link } from 'react-router-dom';
import IndicadoresPrestadorYear from '../Equipos/IndicadoresPrestadorYear';

export class DashboardAsistencial extends Component {
        

    state = {
        selectedYear: new Date().getFullYear()
      };
    
      componentDidMount() {
          this.props.getIndicadoresPrestadorNew(this.props.clienteId, this.state.selectedYear);
      }
    
      handleYearChange = (year) => {
          this.setState({ selectedYear: year }, () => {
              this.props.getIndicadoresPrestadorNew(this.props.clienteId, year);
          });
      };
    
    render() {
        

        const {indicadoresp,loadingindp}= this.props.data;
        let cumplimientosolicitudes=Math.round(indicadoresp.mtosCorr*100/indicadoresp.soliCorr); 
        let cumplimientomantenimiento=Math.round(indicadoresp.mtosPrev*100/indicadoresp.progMtosPrev);
        let cumplimientocalibracion=Math.round(indicadoresp.cals*100/indicadoresp.progCals);

        const procesarDatosDepreciacion = () => {
          const categorias = {};
          let totalDepreciacion = 0;
          let valorTotalInicial = 0;
        
          if (indicadoresp.datosDepreciacion) {
            Object.entries(indicadoresp.datosDepreciacion).forEach(([categoria, equipos]) => {
              equipos.forEach(equipo => {
                if (!categorias[categoria]) {
                  categorias[categoria] = {
                    cantidad: 0,
                    valorTotal: 0,
                    valorActual: 0
                  };
                }
        
                const años = new Date().getFullYear() - new Date(equipo.fechaAdquisicion).getFullYear();
                const valorActual = equipo.valorCompra * Math.pow(1 - 0.125, años);
                
                categorias[categoria].cantidad += 1;
                categorias[categoria].valorTotal += Number(equipo.valorCompra || 0);
                categorias[categoria].valorActual += Number(valorActual || 0);
        
                valorTotalInicial += Number(equipo.valorCompra || 0);
                totalDepreciacion += Number(equipo.valorCompra - valorActual || 0);
              });
            });
          }
        
          return {
            categorias,
            valorTotalInicial: Math.round(valorTotalInicial),
            totalDepreciacion: Math.round(totalDepreciacion),
            valorActual: Math.round(valorTotalInicial - totalDepreciacion)
          };
        };


        
        const datosDepreciacion = procesarDatosDepreciacion();

        const generarDatosLineales = () => {
          const añoActual = new Date().getFullYear();
          const datosAño = {};
          
          // Inicializar datos para los últimos 5 años y 5 años futuros
          for (let año = añoActual - 5; año <= añoActual + 5; año++) {
            datosAño[año] = {
              valorTotal: 0,
              valorDepreciado: 0
            };
          }
        
          // Calcular valor para cada año
          if (indicadoresp.datosDepreciacion) {
            Object.values(indicadoresp.datosDepreciacion).flat().forEach(equipo => {
              const añoAdquisicion = new Date(equipo.fechaAdquisicion).getFullYear();
              const valorInicial = Number(equipo.valorCompra|| 0);
        
              Object.keys(datosAño).forEach(año => {
                if (año >= añoAdquisicion) {
                  const añosTranscurridos = año - añoAdquisicion;
                  const valorActual = valorInicial * Math.pow(1 - 0.125, añosTranscurridos);
                  datosAño[año].valorTotal += valorInicial;
                  datosAño[año].valorDepreciado += valorActual;
                }
              });
            });
          }
        
          return datosAño;
        };

        let segundosatiempo=(s)=>{

            const addZ=(n)=>{
                return (n<10? '0':'') + n;       
                
            }
            var ms = s % 1000;
             s = (s - ms) / 1000;
            var secs = s % 60;
             s = (s - secs) / 60;
             var mins = s % 60;
             var hrs = (s - mins) / 60;
    
      return addZ(hrs) + 'h:' + addZ(mins) + 'm:' + addZ(secs)+ 's';
    
        }
    
          let tiemporespuestahora = indicadoresp?.avgResponseTime != null 
          ? segundosatiempo(indicadoresp.avgResponseTime) 
          : "0";

            // Depreciación
            const dataLineDep = {
              labels: Object.keys(generarDatosLineales()),
              datasets: [
                {
                  label: 'Valor Total',
                  data: Object.values(generarDatosLineales()).map(d => Math.round(d.valorTotal)),
                  borderColor: '#0962d5',
                  backgroundColor: 'rgba(9, 98, 213, 0.1)',
                  fill: true,
                },
                {
                  label: 'Valor Depreciado',
                  data: Object.values(generarDatosLineales()).map(d => Math.round(d.valorDepreciado)),
                  borderColor: '#338bf7',
                  backgroundColor: 'rgba(51, 139, 247, 0.1)',
                  fill: true,
                }
              ]
            };
            
            const optionsLineDep = {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'top',
              },
              scales: {
                xAxes: [{
                  gridLines: {
                    display: false
                  }
                }],
                yAxes: [{
                  gridLines: {
                    display: true
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function(value) {
                      return '$' + new Intl.NumberFormat('es-CO').format(value);
                    }
                  }
                }]
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                      label += ': ';
                    }
                    label += '$' + new Intl.NumberFormat('es-CO').format(tooltipItem.yLabel);
                    return label;
                  }
                }
              }
            };
  
            const dataBarDep = {
              labels: Object.keys(datosDepreciacion.categorias),
              datasets: [
                {
                  label: 'Valor Inicial',
                  backgroundColor: '#0962d5',
                  data: Object.values(datosDepreciacion.categorias).map(cat => Math.round(cat.valorTotal))
                },
                {
                  label: 'Valor Actual',
                  backgroundColor: '#338bf7',
                  data: Object.values(datosDepreciacion.categorias).map(cat => Math.round(cat.valorActual))
                }
              ]
            };
            
            const dataPieDep = {
              labels: Object.keys(datosDepreciacion.categorias),
              datasets: [{
                data: Object.values(datosDepreciacion.categorias).map(cat => cat.cantidad),
                backgroundColor: [
                  '#0962d5',
                  '#338bf7',
                  '#069ad5',
                  '#06c1cc',
                  '#ef701d',
                  '#c4540f',
                  '#18941C'
                ],
                borderWidth: 1,
                borderColor: '#ffffff'
              }]
            };
  
            const optionsBarDep = {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'top',
              },
              scales: {
                xAxes: [{
                  gridLines: {
                    display: false
                  },
                  stacked: false,
                  categoryPercentage: 0.8,
                  barPercentage: 0.9,
                }],
                yAxes: [{
                  gridLines: {
                    display: true
                  },
                  ticks: {
                    beginAtZero: true,
                    callback: function(value) {
                      return '$' + new Intl.NumberFormat('es-CO').format(value);
                    }
                  }
                }]
              },
              tooltips: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                  label: function(tooltipItem, data) {
                    let label = data.datasets[tooltipItem.datasetIndex].label || '';
                    if (label) {
                      label += ': ';
                    }
                    label += '$' + new Intl.NumberFormat('es-CO').format(tooltipItem.yLabel);
                    return label;
                  }
                }
              }
            };
            
            // Configuración para el gráfico circular
            const optionsPieDep = {
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                display: true,
                position: 'right',
                labels: {
                  boxWidth: 12
                }
              },
              tooltips: {
                enabled: true,
                mode: 'index',
                callbacks: {
                  label: function(tooltipItem, data) {
                    const dataset = data.datasets[tooltipItem.datasetIndex];
                    const total = dataset.data.reduce((acc, val) => acc + val, 0);
                    const value = dataset.data[tooltipItem.index];
                    const percentage = ((value / total) * 100).toFixed(1);
                    return ` ${data.labels[tooltipItem.index]}: ${value} (${percentage}%)`;
                  }
                }
              }
            };
  
      
          const optionspie = {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            cutoutPercentage: 5,
            layout: { padding: 5},
            tooltips: {
              enabled: true,
              mode: 'index',
              intersect: false,
              borderWidth: 2,
              borderColor: "#03178C",
              backgroundColor: "#FFF",
              titleFontColor: "#03178C",
              bodyFontColor: "#03178C",
              footerFontColor: "#03178C"
            }
          };
          const optionspiecal = {
            legend: {
              display: false
            },
            responsive: true,
            maintainAspectRatio: false,
            animation: false,
            cutoutPercentage: 5,
            layout: { padding: 5},
            tooltips: {
              enabled: true,
              mode: 'index',
              intersect: false,
              borderWidth: 2,
              borderColor: "#03178C",
              backgroundColor: "#FFF",
              titleFontColor: "#03178C",
              bodyFontColor: "#03178C",
              footerFontColor: "#03178C"
            }
          };
        

          const   datapie = {
            datasets: [
              {
                data: [indicadoresp.mtosPrev,
                  
                    indicadoresp.progMtosPrev],
                backgroundColor: [
                    "#0962d5",
                  "#338bf7",
               
                  
        
                ],
                borderWidth: 10,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff'
              }
            ],
            labels: ['Realizados', 'Programados']
          };

          const   datapiecal = {
            datasets: [
              {
                data: [indicadoresp.progCals,
                  indicadoresp.cals],
                backgroundColor: [
                    "#069ad5",
                  "#06c1cc",
               
                  
        
                ],
                borderWidth: 10,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff'
              }
            ],
            labels: ['Programadas', 'Realizadas']
          };

          const databar = {
            labels: ['Funcionando', 'Correctivo', 'De Baja'],
            datasets: [
              {
                
                backgroundColor: [
                    "#18941C",
                  "#F2AC29",
                  "#E0231C"
                  ],
                
                borderWidth: 1,
                borderColor: '#ffffff',
                hoverBorderColor: '#ffffff',
                data: [indicadoresp.equiposFuncionando,
                  indicadoresp.equiposCorrectivo,
                  indicadoresp.equiposDeBaja
                    ],
              },
            ],
          };

          const optionsbar = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false, // Oculta la leyenda, que incluye las etiquetas del dataset
              },
            title: {
                display: false, // Oculta el título del gráfico
              },
            scales: {
                xAxes: [{
                    gridLines: {
                        display:false
                    }
                }],
                yAxes: [{
                    gridLines: {
                        display:false
                    }  , 
                    ticks: {
                        display: false, // Oculta los valores del eje Y
                      },
                }]
            },
            plugins: {
                
                tooltip: {
                enabled: false,
                mode: 'index',
                intersect: false,
              },
            },
          };


          //BAR MTO 
          const databarmto = {
            labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            datasets: [
              {
                label: 'Mantenimientos Preventivos',
                backgroundColor: "#0962d5",
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                data: indicadoresp?.monthlyDataPrevMtos?.map(item => item.mtosPrev) || [],
              },
              {
                label: 'Programaciones de Mantenimiento',
                backgroundColor: "#338bf7",
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                data: indicadoresp?.monthlyDataPrevMtos?.map(item => item.progMtosPrev) || [],
              },
            ],
          };
         
          
          const optionsbarmto = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'top',
            },
            title: {
              display: true,
              text: 'Mantenimientos Preventivos vs Programaciones',
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                categoryPercentage: 0.8, // Ajusta el ancho de las categorías
                barPercentage: 0.9, // Ajusta el ancho de las barras
              }],
              yAxes: [{
                gridLines: {
                  display: true
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                ticks: {
                  beginAtZero: true,
                  display: true,
                },
              }]
            },
            plugins: {
              tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
              },
            },
          };

          //BAR CALIBRACION
          const databarcal = {
            labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            datasets: [
              {
                label: 'Calibraciones',
                backgroundColor: "#069ad5",
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                data: indicadoresp?.monthlyDataCals?.map(item => item.cals) || [],
              },
              {
                label: 'Programaciones de Calibración',
                backgroundColor: "#06c1cc",
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                data: indicadoresp?.monthlyDataCals?.map(item => item.progCals) || [],
              },
            ],
          };
         
         
          
          const optionsbarcal = {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'top',
            },
            title: {
              display: true,
              text: 'Calibraciones Realizadas vs Programaciones',
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                categoryPercentage: 0.8, // Ajusta el ancho de las categorías
                barPercentage: 0.9, // Ajusta el ancho de las barras
              }],
              yAxes: [{
                gridLines: {
                  display: true
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                ticks: {
                  beginAtZero: true,
                  display: true,
                },
              }]
            },
            plugins: {
              tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
              },
            },
          };

           //Bar Correctivos Vs Solicitudes 
           const databarcorr = {
            labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            datasets: [
              {
                label: 'Mtos. Correctivos',
                backgroundColor: "#ef701d",
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                data: indicadoresp?.monthlyDataCombined?.map(item => item.mtosCorr) || [],
              },
              {
                label: 'Solicitudes',
                backgroundColor: "#c4540f",
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                data: indicadoresp?.monthlyDataCombined?.map(item => item.solicitudes) || [],
              },
            ],
          };
         
         
          
          const optionsbarcorr= {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'top',
            },
            title: {
              display: true,
              text: 'Mtos. Correctivos Vs Solicitudes',
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                categoryPercentage: 0.8, // Ajusta el ancho de las categorías
                barPercentage: 0.9, // Ajusta el ancho de las barras
              }],
              yAxes: [{
                gridLines: {
                  display: true
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                ticks: {
                  beginAtZero: true,
                  display: true,
                },
              }]
            },
            plugins: {
              tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
              },
            },
          };
//Bar Costo Correctivos Por Mes  
          const databarcosto = {
            labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            datasets: [
              {
                label: 'Mtos. Correctivos',
                backgroundColor: "#ef701d",
                borderColor: '#ffffff',
                borderWidth: 1,
                hoverBorderColor: '#ffffff',
                data: indicadoresp?.monthlyDataCombined?.map(item => item.cost) || [],
              }
            ],
          };
         
         
          
          const optionsbarcosto= {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              position: 'top',
            },
            title: {
              display: true,
              text: 'Costo de Mtos. Correctivos Mensual',
            },
            scales: {
              xAxes: [{
                gridLines: {
                  display: false
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                categoryPercentage: 0.8, // Ajusta el ancho de las categorías
                barPercentage: 0.9, // Ajusta el ancho de las barras
              }],
              yAxes: [{
                gridLines: {
                  display: true
                },
                stacked: false, // Cambiado a false para que las barras no se apilen
                ticks: {
                  beginAtZero: true,
                  display: true,
                },
              }]
            },
            plugins: {
              tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
              },
            },
          };




     
        let mapeoindicadores=!loadingindp ?(
        <div className='indicadoresprestadorcontainer-indp'>


            <div className='indicadoresrowcontainer-indp'>
           
            <div className="dashing-div-card-indp">
            <div className="dashing-card-indp">
                <div className="dashing-cardcontainer-indp">
                    <div className="dashing-cardcontent-indp">
                        <div className="dashing-cardtext-indp">
                            <p className="dashing-text-card-indp">
                            Total Equipos
                            </p>
        <h3 className="dashing-value-card-indp">{indicadoresp.totalEquipos}</h3>
                        </div>
                        <div className="dashing-cardicon-indp">
                            <FaLaptopMedical color="#FFFFFF" className="dashing-icon-indp"/>
                        </div>
                    </div>
                    

                </div>
                
            </div>
            </div>

            <div className="dashing-div-card-indp">

<IndicadoresPrestadorYear 
    initialYear={this.state.selectedYear}
    onYearChange={this.handleYearChange}
/>
  </div>


          


            </div>


            <div className='indicadoresrowcontainer-indp'>

            <div className="dashing-div-card-indp">



<div className="dashing-card-indp">
    <div className="dashing-cardcontainer-indp">
        <div className="dashing-cardcontent-indp">
            <div className="dashing-cardtext-indp">
                <p className="dashing-text-card-indp">
               Tareas Realizadas
                </p>
                <h3 className="dashing-value-card-indp">{indicadoresp.tareasRealizadas?indicadoresp.tareasRealizadas:0}</h3>
            </div>
            <div className="dashing-cardicon-indp">
                <FaWrench color="#FFFFFF" className="dashing-icon-indp"/>
            </div>
        </div>
       

    </div>
    
</div>
</div>

<div className="dashing-div-card-indp">

<div className="dashing-card-indp">
    <div className="dashing-cardcontainer-indp">
        <div className="dashing-cardcontent-indp">
            <div className="dashing-cardtext-indp">
                <p className="dashing-text-card-indp">
                Tareas Pendientes
                </p>
<h3 className="dashing-value-card-indp">{indicadoresp.tareasNoRealizadas?indicadoresp.tareasNoRealizadas:0}</h3>
            </div>
            <div className="dashing-cardicon-indp">
                <FaRocket color="#FFFFFF" className="dashing-icon-indp"/>
            </div>
        </div>
       

    </div>
    
</div>

</div>
            </div>
            
            

       

            
            

          
                <div className="dashing-secondindicators-indp">
                
                <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardestadotitulo-indp">
                                <p className="dashing-text-card-indp">
                                        Estado Equipos
                                        </p>
                                </div>
                                <div className="dashing-chart-container-indp">
                                <Bar
                                options={optionsbar}
                                data={databar}
                              
                                />
                        </div>
                        

                        <div className="dashing-card-data-container-indp">

            <div className="dashing-card-data-indp">
           
            <p className="dashing-text-data-indp"> Funcionando</p>
          <h2 className="dashing-value-data-green-indp">{indicadoresp.equiposFuncionando?indicadoresp.equiposFuncionando:0}</h2>
            </div>


            <div className="dashing-card-data-indp">
           
            <p className="dashing-text-data-indp"> En Correctivo</p>
          <h2 className="dashing-value-data-yellow-indp">{indicadoresp.equiposCorrectivo?indicadoresp.equiposCorrectivo:0}</h2>
            </div>

            <div className="dashing-card-data-indp">
           
            <p className="dashing-text-data-indp"> De Baja</p>
          <h2 className="dashing-value-data-red-indp">{indicadoresp.equiposDeBaja?indicadoresp.equiposDeBaja:0}</h2>
            </div>
        </div>

                    
                    </div>
                </div>

                

                <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardestadotitulo-indp">
                                <p className="dashing-text-card-indp">
                                       Mtos Preventivos
                                        </p>
                                </div>
                                <div className="dashing-chart-container-indp">
                                <Pie
                                options={optionspie}
                                data={datapie}
                                
                                />
                               
                        </div>
                        

                        <div className="dashing-card-data-container-indp">

            <div className="dashing-card-data-indp">
           
            <p className="dashing-text-data-indp"> Programados</p>
          <h2 className="dashing-value-data-blue-indp">{indicadoresp.progMtosPrev?indicadoresp.progMtosPrev:0}</h2>
            </div>


            <div className="dashing-card-data-indp">
            
            <p className="dashing-text-data-indp"> Realizados</p>
          <h2 className="dashing-value-data-blue-indp">{indicadoresp.mtosPrev?indicadoresp.mtosPrev:0}</h2>
            </div>

          
        </div>

                    
                    </div>
                </div>

                <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardestadotitulo-indp">
                                <p className="dashing-text-card-indp">
                                      Calibraciones
                                        </p>
                                </div>
                                <div className="dashing-chart-container-indp">
                                <Pie
                                options={optionspiecal}
                                data={datapiecal}
                                
                                />
                               
                        </div>
                        

                        <div className="dashing-card-data-container-indp">

            <div className="dashing-card-data-indp">
           
            <p className="dashing-text-data-indp"> Programadas</p>
          <h2 className="dashing-value-data-blue-indp">{indicadoresp.progCals?indicadoresp.progCals:0}</h2>
            </div>


            <div className="dashing-card-data-indp">
            
            <p className="dashing-text-data-indp"> Realizadas</p>
          <h2 className="dashing-value-data-blue-indp">{indicadoresp.cals?indicadoresp.cals:0}</h2>
            </div>

          
        </div>

                    
                    </div>
                </div>
       

       

        </div>
               
               
                <div className='indicadoresrowcontainer-indp'>

                <div className="dashign-div-secondcard-indp">
    
    <div className="dashing-card-indp">
        <div className="dashing-cardcontainer-indp">
            <div className="dashing-cardcontent-indp">
                <div className="dashing-cardtext-indp">
                    <p className="dashing-text-card-indp">
                   Cumplimiento Mtos. Preventivos
                    </p>
<h3 className="dashing-value-card-indp">{cumplimientomantenimiento=cumplimientomantenimiento||0}%</h3>
                </div>
                <div className="dashing-cardicon-blue-indp">
                    <FaRuler color="#FFFFFF" className="dashing-icon-indp"/>
                </div>
            </div>
            
        </div>
        
    </div>
    
    </div>  

<div className="dashign-div-secondcard-indp">
<div className="dashing-card-indp">
    <div className="dashing-cardcontainer-indp">
        <div className="dashing-cardcontent-indp">
            <div className="dashing-cardtext-indp">
                <p className="dashing-text-card-indp">
               Cumplimiento Correctivos
                </p>
<h3 className="dashing-value-card-indp">{cumplimientosolicitudes=cumplimientosolicitudes||0}%</h3>
            </div>
            <div className="dashing-cardicon-blue-indp">
                <FaTasks color="#FFFFFF" className="dashing-icon-indp"/>
            </div>
        </div>
        

    </div>
    
</div>
   

</div>



<div className="dashign-div-secondcard-indp">

                    <div className="dashing-card-indp">
                        <div className="dashing-cardcontainer-indp">
                            <div className="dashing-cardcontent-indp">
                                <div className="dashing-cardtext-indp">
                                    <p className="dashing-text-card-indp">
                                    Cumplimiento de Calibración
                                    </p>
                    <h3 className="dashing-value-card-indp">{cumplimientocalibracion=cumplimientocalibracion||0}%</h3>
                                </div>
                                <div className="dashing-cardicon-blue-indp">
                                    <FaRegCalendarCheck color="#FFFFFF" className="dashing-icon-indp"/>
                                </div>
                            </div>
                            
                        </div>
                        
                    </div>

                    </div>  
                    
                    
                    </div>

                    <div className="dashing-secondindicators-indp">
                
                <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardestadotitulo-indp">
                                <p className="dashing-text-card-indp">
                                       Mantenimientos Vs Programaciones Mensuales
                                        </p>
                                </div>
                                <div className="dashing-chart-container-indp">
                                <Bar
                                options={optionsbarmto}
                                data={databarmto}
                                height={220} 
                              
                                />
                        </div>
                        

       

                    
                    </div>
                </div>

                <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardestadotitulo-indp">
                                <p className="dashing-text-card-indp">
                                       Calibraciones Vs Programaciones Mensuales
                                        </p>
                                </div>
                                <div className="dashing-chart-container-indp">
                                <Bar
                                options={optionsbarcal}
                                data={databarcal}
                                height={220} 
                              
                                />
                        </div>
                        

       

                    
                    </div>
                </div>
                </div>

           
                <div className='indicadoresrowcontainer-indp'>

                
<div className="dashign-div-secondcard-indp">
    <Link className='link-card-indp' to={`/dashboard/perfilclientetec/${this.props.clienteId}/indicadoresperfil`}>
   
<div className="dashing-card-indp">
    <div className="dashing-cardcontainer-indp">
        <div className="dashing-cardcontent-indp">
            <div className="dashing-cardtext-indp">
                <p className="dashing-text-card-indp">
               Solicitudes 
                </p>
             <h3 className="dashing-value-card-indp">{indicadoresp.soliCorr}</h3>
            </div>
            <div className="dashing-cardicon-orange-indp">
                <FaPhoneSquareAlt color="#FFFFFF" className="dashing-icon-indp"/>
            </div>
        </div>
        

    </div>
    
</div>
</Link>
</div>

<div className="dashign-div-secondcard-indp">               
<div className="dashing-card-indp">
    <div className="dashing-cardcontainer-indp">
        <div className="dashing-cardcontent-indp">
            <div className="dashing-cardtext-indp">
                <p className="dashing-text-card-indp">
                Mtos. Correctivos
                </p>
                <h3 className="dashing-value-card-indp">{indicadoresp.mtosCorr}</h3>
            </div>
            <div className="dashing-cardicon-orange-indp">
                <FaCogs color="#FFFFFF" className="dashing-icon-indp"/>
            </div>
        </div>
        

    </div>
    
</div>
</div>

<div className="dashign-div-secondcard-indp">               
<div className="dashing-card-indp">
    <div className="dashing-cardcontainer-indp">
        <div className="dashing-cardcontent-indp">
            <div className="dashing-cardtext-indp">
                <p className="dashing-text-card-indp">
                Total Costo Correctivo
                </p>
                <h3 className="dashing-value-card-indp">${indicadoresp.totalRepuestos}</h3>
            </div>
            <div className="dashing-cardicon-orange-indp">
                <FaCogs color="#FFFFFF" className="dashing-icon-indp"/>
            </div>
        </div>
        

    </div>
    
</div>
</div>

<div className="dashign-div-secondcard-indp">               
<div className="dashing-card-indp">
    <div className="dashing-cardcontainer-indp">
        <div className="dashing-cardcontent-indp">
            <div className="dashing-cardtext-indp">
                <p className="dashing-text-card-indp">
                Tiempo de Respuesta
                </p>
                <h3 className="dashing-value-card-indp">{tiemporespuestahora}</h3>
            </div>
            <div className="dashing-cardicon-orange-indp">
                <FaRegClock  color="#FFFFFF" className="dashing-icon-indp"/>
            </div>
        </div>
        

    </div>
    
</div>
</div>





</div>


                <div className="dashing-secondindicators-indp">
                
                <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardestadotitulo-indp">
                                <p className="dashing-text-card-indp">
                                      Mtos. Correctivos Vs Solicitudes Mensuales
                                        </p>
                                </div>
                                <div className="dashing-chart-container-indp">
                                <Bar
                                options={optionsbarcorr}
                                data={databarcorr}
                                height={220} 
                              
                                />
                        </div>
                        

       

                    
                    </div>
                </div>
                <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardestadotitulo-indp">
                                <p className="dashing-text-card-indp">
                                      Costo Mtos. Correctivos Mensual
                                        </p>
                                </div>
                                <div className="dashing-chart-container-indp">
                                <Bar
                                options={optionsbarcosto}
                                data={databarcosto}
                                height={220} 
                              
                                />
                        </div>
                        

       

                    
                    </div>
                </div>


                </div>

                 <div className="dashing-secondindicators-indp">
                  <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                      <div className="dashing-cardestadotitulo-indp">
                        <p className="dashing-text-card-indp">
                          Valor por Categoría
                        </p>
                      </div>
                      <div className="dashing-chart-container-indp" style={{ height: '220px' }}>
                        <Bar
                          data={dataBarDep}
                          options={{
                            ...optionsBarDep,
                            scales: {
                              ...optionsBarDep.scales,
                              yAxes: [{
                                ticks: {
                                  callback: value => `$${new Intl.NumberFormat('es-CO').format(value)}`
                                }
                              }]
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                
                  <div className="dashing-cardestado-indp">
                    <div className="dashing-cardcontainer-indp">
                      <div className="dashing-cardestadotitulo-indp">
                        <p className="dashing-text-card-indp">
                          Distribución por Categoría
                        </p>
                      </div>
                      <div className="dashing-chart-container-indp" style={{ height: '220px' }}>
                        <Pie
                          data={dataPieDep}
                          options={optionsPieDep}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="dashing-secondindicators-indp">
                  <div className="dashing-cardestado-indp-dep">
                    <div className="dashing-cardcontainer-indp">
                      <div className="dashing-cardestadotitulo-indp">
                        <p className="dashing-text-card-indp">
                          Depreciación Anual
                        </p>
                      </div>
                      <div className="dashing-chart-container-indp" style={{ height: '220px' }}>
                        <Line
                          data={dataLineDep}
                          options={optionsLineDep}
                        />
                      </div>
                      <div className="dashing-card-data-container-indp">
                        <div className="dashing-card-data-indp">
                          <p className="dashing-text-data-indp">Año Actual</p>
                          <h2 className="dashing-value-data-blue-indp">
                            ${new Intl.NumberFormat('es-CO').format(
                              Math.round(generarDatosLineales()[new Date().getFullYear()].valorDepreciado)
                            )}
                          </h2>
                        </div>
                        <div className="dashing-card-data-indp">
                          <p className="dashing-text-data-indp">Depreciación Anual</p>
                          <h2 className="dashing-value-data-blue-indp">12.5%</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
               


        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )


        return (
            <div className='container-dashboard-asis'>
                {mapeoindicadores}
            </div>
        )
    }
}
DashboardAsistencial.propTypes={
    getIndicadoresPrestador:PropTypes.func.isRequired,
    getIndicadoresPrestadorNew: PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired  
}
const mapStateToProps=state=>({
    data:state.data
})
export default connect(mapStateToProps,{getIndicadoresPrestador,getIndicadoresPrestadorNew}) (DashboardAsistencial);


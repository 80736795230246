import React, { Component } from 'react';
import './InventarioEquipos.css';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getEquipos} from '../../../redux/actions/dataactions';
import MaterialTable from 'material-table';

import { Link, useHistory } from 'react-router-dom';
import {FaArrowCircleLeft, FaFilePdf} from "react-icons/fa";

import MyButton from '../../../utils/MyButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Fragment } from 'react';


export class InventarioEquipos extends Component {
    componentDidMount(){
       this.props.getEquipos(this.props.user.credentials.idprestador);
      
      }

     

  render() {

    const {equipos,loadingdata}= this.props.data;

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        MiIcon:forwardRef((props, ref) => <AddToPhotosIcon {...props} ref={ref}/>),
        MiIcon2:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        MiIcon3:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        MiIcon4:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
      };

    const columns=[
      {
        field: 'imagenurl',
        title: 'Avatar',
        render: rowData => <img src={rowData.imagenurl} style={{width: 50, borderRadius: '50%'}}/>
      },
      {title:'No. Inventario',field:'idinventario'},
        {title:'Nombre',field:'nombre'},
        {title:'Marca',field:'marca'},
        {title:'Modelo',field:'modelo'},
        {title:'Serie',field:'serie'},
        {title:'Ubicación',field:'ubicacion'},
        {title:'Servicio',field:'servicio'},
        {title:'Estado', field:'estado'},
        {title:'R. Invima',field:'registroinvima'},
        {title:'Riesgo',field:'riesgo'}
    ]
    
    let mapeoequipos=!loadingdata ?(
    <div>

      <div className='div-container-links-inventario'>
                        <Fragment>
                        <Link className='div-card-link' to={`/dashboard/inventarioequiposasistencial/${this.props.user.credentials.idprestador}`} state={equipos}>
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Exportar Inventario
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
                        
                    </div>
    <MaterialTable
    columns={columns}
    data={equipos}
    title={
      <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}></h2>
    }
      icons={tableIcons}
      options={{
          cellStyle: {
              fontSize: 11,
              fontWeight:500,
              color:'#696B6D'
            
          
            },
            headerStyle: {
              fontSize: 12,
              fontWeight: 'bolder',
              color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
            }
        
        }}

    actions={[
      rowData => ({
        icon: () =>  <Link to={`/dashboard/inventarioequiposasistencial/hojadevidaequipo/${rowData.equipoid}`} style={{ color: '#03178C' }} ><AssignmentIcon  /></Link>,
        tooltip: 'Ver Perfil ',
        onClick: (rowData)
      })
       
      ]}
     
    >

    </MaterialTable>
    </div>
    ):(
    <div className="div_cargando">
       <CircularProgress size={50}color="primary" className="login-progress" />
    </div>
    )

    
  
    return (
        <div className='inventario-container'>

        <div className="titulomarket_section">
        <div className="titulo_market">
        <h1 className="text-clientes">Inventario Equipos Biomedicos</h1>
        <hr className="clientes_separator"></hr>  

        </div>

        <div className="divmarket_boton">
      
        <Link to={`/dashboard/equiposasistencial`}>
        <MyButton tip="Volver">
         <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
        </MyButton>
        </Link>


        </div>
        </div>
        {mapeoequipos}
    </div>
    )
  }
}

InventarioEquipos.propTypes={
    getEquipos:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
}
const mapStateToProps=state=>({
    data:state.data,
    user:state.user
})

export default connect(mapStateToProps,{getEquipos}) (InventarioEquipos);

import React, { Component, Fragment } from 'react'
import './DocumentacionNuevo.css';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDropzone } from 'react-dropzone';
import {  FaArrowLeft, FaPlus, FaFileImport, FaCheck, FaCheckCircle } from "react-icons/fa";

import { postDocLegal, clearErrors, getDocsLegal } from '../../../redux/actions/dataactions';

// Crear un componente funcional para usar el hook useDropzone
const FileDropzone = ({ onFileDrop }) => {
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']
    },
    maxFiles: 1,
    onDrop: onFileDrop
  });

  return (
    <div className="dropzone-container mt-4">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p className="dropzone-text">Arrastra y suelta el archivo aquí, o haz clic para seleccionarlo</p>
        <em className="dropzone-info">(Solo se permite subir un archivo PDF, DOC o DOCX)</em>
      </div>
      
      <aside className="dropzone-preview">
        {fileRejections.length > 0 && (
          <div className="file-error">
            <h4>Solo se puede cargar un archivo</h4>
          </div>
        )}

        {acceptedFiles.length > 0 && (
          <div className="file-accepted">
            <h4>Archivo a cargar:</h4>
            <ul className="file-list">
              {acceptedFiles.map(file => (
                <li key={file.path} className="file-item">
                  {file.path} - {file.size} bytes
                </li>
              ))}
            </ul>
          </div>
        )}
      </aside>
    </div>
  );
};

export class DocumentacionNuevo extends Component {
    constructor() {
        super();
        this.state = {
            tipodoc: '',
            descripcion: '',
            consecutivo: '',
            documento: null,
            errors: {},
            aprobado:{},
            open: false
        };
    };

    static getDerivedStateFromProps(props, state) {
      const newState = {};
    
      if (props.UI.errors) {
        newState.errors = props.UI.errors;
      }
    
      if (props.UI.aprobado) {
        newState.aprobado = props.UI.aprobado;
      }
    
      return Object.keys(newState).length > 0 ? newState : null;
  }
    handleOpen = () => {
      this.setState({
        open: true,
        tipodoc: '',
        descripcion: '',
        consecutivo: '',
        aprobado:'',
        documento: null,
        errors: {}
    });
    };
    
    
     handleClose = () => {
      this.props.clearErrors();
            if (this.state.aprobado && this.state.aprobado.mensaje) {
                console.log(this.state.aprobado);
                
                this.setState({
                  open: false,
                  tipodoc: '',
                  descripcion: '',
                  consecutivo: '',
                  aprobado:'',
                  documento: null,
                  errors: {}
              });
                // Recargar la lista de solicitudes
                this.props.getDocsLegal(this.props.clienteId);
            } else {
              this.setState({
                open: false,
                tipodoc: '',
                descripcion: '',
                consecutivo: '',
                aprobado:{},
                documento: null,
                errors: {}
            });
            }
        };

    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleFileDrop = (acceptedFiles) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            this.setState({ documento: acceptedFiles[0] });
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        
        const formData = new FormData();
        formData.append('tipodoc', this.state.tipodoc);
        formData.append('descripcion', this.state.descripcion);
        formData.append('consecutivo', this.state.consecutivo);
        formData.append('clienteid', this.props.clienteId);
        
        if (this.state.documento) {
            formData.append('documento', this.state.documento);
        } else {
            formData.append('url', 'Sin Url');
        }

        console.log('Enviando datos:', {
            tipodoc: this.state.tipodoc,
            descripcion: this.state.descripcion,
            consecutivo: this.state.consecutivo,
            clienteid: this.props.clienteId,
            documento: this.state.documento ? 'Archivo adjunto' : 'Sin documento'
        });
        
        this.props.postDocLegal(formData, this.props.clienteId);
    };

    render() {
        const { UI: { loading, aprobado } } = this.props;
        const { errors } = this.state;

        return (
            <Fragment>
                <MyButton tip="Nuevo Documento" onClick={this.handleOpen}>
                    <FaFileImport color="#03178C" className="icon-crearcliente" />
                </MyButton>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Crear Nuevo Documento</DialogTitle>

                    {this.state.aprobado && this.state.aprobado.mensaje ? (
                        <DialogContent>
                            <div className='div-aprobado'>
                                <div className='div-icono-aprobado'>
                                    <FaCheck color="#ffffff" className='icono-aprobado' />
                                </div>
                                <h2 className='text-aprobado'>¡Documento Creado Exitosamente!</h2>
                            </div>
                        </DialogContent>
                    ) : (
                        <DialogContent>
                            <form>
                                <FormControl className="formcontrol-nuevocliente">
                                    <InputLabel>Tipo Documento</InputLabel>
                                    <Select
                                        required
                                        name="tipodoc"
                                        id="tipodoc"
                                        value={this.state.tipodoc}
                                        onChange={this.handleChange}
                                        fullWidth
                                    >
                                        <MenuItem value="">
                                            <em></em>
                                        </MenuItem>
                                        <MenuItem value="Contrato">Contrato</MenuItem>
                                        <MenuItem value="Factura">Factura</MenuItem>
                                        <MenuItem value="Hoja de Vida">Hoja de Vida Ingeniero</MenuItem>
                                        <MenuItem value="Registro Invima">Registro Invima</MenuItem>
                                        <MenuItem value="Programa de Tecnovigilancia">Programa de Tecnovigilancia</MenuItem>
                                        <MenuItem value="Programa de Capacitaciones">Programa de Capacitaciones</MenuItem>
                                        <MenuItem value="Programa de Mantenimiento">Programa de Mantenimiento</MenuItem>
                                        <MenuItem value="Certificado de Patron">Certificado de Patron</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.tipodoc}</FormHelperText>
                                </FormControl>

                                <TextField className="login-input-email"
                                    required
                                    type="text"
                                    name="descripcion"
                                    id="descripcion"
                                    label="Descripción"
                                    value={this.state.descripcion}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.descripcion}
                                    error={errors.descripcion ? true : false}
                                />

                                <TextField className="login-input-email"
                                    required
                                    type="text"
                                    name="consecutivo"
                                    id="consecutivo"
                                    label="Consecutivo"
                                    value={this.state.consecutivo}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.consecutivo}
                                    error={errors.consecutivo ? true : false}
                                />

                                {/* Componente funcional que usa useDropzone */}
                                <FileDropzone onFileDrop={this.handleFileDrop} />

                                <div className="error-container">
                                    {errors && errors.errors && (
                                        errors.errors.length > 0 ? (
                                            <ul className="error-list">
                                                {errors.errors.map((error, index) => (
                                                    <li key={index} className="error-item">{error}</li>
                                                ))}
                                            </ul>
                                        ) : (
                                            <p className="no-errors">No se encontraron errores.</p>
                                        )
                                    )}
                                </div>
                            </form>
                        </DialogContent>
                    )}

                    <DialogActions>
                        <MyButton tip="Cerrar" onClick={this.handleClose}>
                            <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
                        </MyButton>

                        {loading ? <CircularProgress size={30} color="primary" className="login-progress" /> :
                            this.state.aprobado && this.state.aprobado.mensaje ? (
                                <MyButton tip="Continuar" onClick={this.handleClose}>
                                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                                </MyButton>
                            ) : (
                                <MyButton tip="Nuevo Documento" onClick={this.handleSubmit} disabled={!this.state.tipodoc || !this.state.descripcion || !this.state.consecutivo}>
                                    <FaPlus color="#03178C" className="icon-formulario" />
                                </MyButton>
                            )
                        }
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

DocumentacionNuevo.propTypes = {
  postDocLegal: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  getDocsLegal: PropTypes.func.isRequired,  // Añadido getDocsLegal
  UI: PropTypes.object.isRequired,
  clienteId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  UI: state.UI
});

export default connect(mapStateToProps, { postDocLegal, clearErrors, getDocsLegal })(DocumentacionNuevo);
import React, { useEffect, useRef, useState } from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import Button from '../../../Elements/Button';
import imgsrc from './robot.png';

function Header() {
    const [isVisible, setIsVisible] = useState(false);
    const h1Ref = useRef(null);
    const h2Ref = useRef(null);
    const h3Ref = useRef(null);
    const buttonRef = useRef(null);
    const imageRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -100px 0px'
            }
        );

        const currentH1Ref = h1Ref.current;
        const currentH2Ref = h2Ref.current;
        const currentH3Ref = h3Ref.current;
        const currentButtonRef = buttonRef.current;
        const currentImageRef = imageRef.current;

        if (currentH1Ref) {
            observer.observe(currentH1Ref);
        }
        if (currentH2Ref) {
            observer.observe(currentH2Ref);
        }
        if (currentH3Ref) {
            observer.observe(currentH3Ref);
        }
        if (currentButtonRef) {
            observer.observe(currentButtonRef);
        }
        if (currentImageRef) {
            observer.observe(currentImageRef);
        }

        return () => {
            if (currentH1Ref) {
                observer.unobserve(currentH1Ref);
            }
            if (currentH2Ref) {
                observer.unobserve(currentH2Ref);
            }
            if (currentH3Ref) {
                observer.unobserve(currentH3Ref);
            }
            if (currentButtonRef) {
                observer.unobserve(currentButtonRef);
            }
            if (currentImageRef) {
                observer.unobserve(currentImageRef);
            }
        };
    }, []);

    return (
        <div className='main-header'>
            <div className="header-container">
                <div className='header-flex-1'>
                    <h1 ref={h1Ref} className={`h1-header ${isVisible ? 'visible' : ''}`}>
                        Bienvenido
                    </h1>
                    <h2 ref={h2Ref} className={`h2-header ${isVisible ? 'visible' : ''}`}>
                        Al Primer Ecosistema Digital
                    </h2>
                    <h2 ref={h2Ref} className={`h2-header ${isVisible ? 'visible' : ''}`}>
                        De Mantenimiento Hospitalario
                    </h2>
                    <h3 ref={h3Ref} className={`h3-header ${isVisible ? 'visible' : ''}`}>
                        Haz Parte del Futuro
                    </h3>
                    <div ref={buttonRef} className={`link-container-header ${isVisible ? 'visible' : ''}`}>
                        <Link className="linkbtn" to="/solicitarinformacion">
                            <Button buttonSize="btn--wide" buttonColor='blue'>
                                Solicitar Información
                            </Button>
                        </Link>
                    </div>
                </div>
                <div className="header-flex-2">
                    <img ref={imageRef} src={imgsrc} alt="logo" className={`robot ${isVisible ? 'visible' : ''}`} />
                </div>
            </div>
        </div>
    );
}

export default Header;

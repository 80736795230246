import {SET_SOLICITUDES_RECHAZADAS,SET_SOLICITUDES_APROBADAS,SET_SOLICITUDES_ENVIADAS,POST_SOLICITUD_ENVIAR,POST_ITEM_ASISTENCIAL,SET_SOLICITUD,POST_SOLICITUD_COMPRAS,SET_SOLICITUDES_SINENVIAR,
     SET_ERRORS, CLEAR_ERRORS, LOADING_UI,LOADING_INDP,SET_INDICADORES_P, LOADING_DATA_ASISTENCIAL, SET_OK} from '../types';
import axios from 'axios'; 
import { getCliente } from './dataactions';
import { apiV1, apiV2 } from '../../utils/api';
export const getIndicadoresPrestadorAsistencial =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_INDP});
    apiV1.get(`/getindicadoresasistencial/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_INDICADORES_P,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const subirEvidenciaSolicitudCorrectivo = (formData, solicitudid, equipoId) => (dispatch) => {
    dispatch({ type: LOADING_UI });
    apiV1.post(`/subirevidenciasolicitud/${solicitudid}`, formData)
      .then((res) => {
        dispatch({
          type: SET_OK,
          payload: res.data
        })
        dispatch(clearErrors());
        //dispatch(getEquipo(equipoId));                    // Echarle mano para renderizar luego de cargar
        //dispatch(getSolicitudesCorrectivos(equipoId));
      })
      .catch(err => console.log(err));
  };

  

export const postAprobarMto=(progid)=>(dispatch)=>{
    dispatch({type:LOADING_UI})

    apiV1.post(`/aprobarmto/${progid}`)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });

    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const postAprobarMtoPerfil=(mtoid)=>(dispatch)=>{
    dispatch({type:LOADING_UI})

    apiV1.post(`/aprobarmtoperfil/${mtoid}`)
    .then((res)=>{
        dispatch({
            type:SET_OK,
            payload:res.data
        });

    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}


export const postSolicitudCompras=(newSolicitud,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postsolicitudcompras`,newSolicitud)
    .then((res)=>{
        dispatch({
            type:POST_SOLICITUD_COMPRAS,
            payload:res.data
        });
       
        dispatch(clearErrors());
        //debe tirarlo a solicitudes sin enviar para que el usuario pueda completar su solicitud
        history.push('/dashboard');    

        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
          
    });
}

export const postSolicitudComprasCliente=(newSolicitud,history,clienteId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postsolicitudcompras/${clienteId}`,newSolicitud)
    .then((res)=>{
        dispatch({
            type:POST_SOLICITUD_COMPRAS,
            payload:res.data
        });
       
        dispatch(clearErrors());
        history.push(`/dashboard/clientes`);    
        //debe tirarlo a solicitudes sin enviar para que el usuario pueda completar su solicitud
    
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
          
    });
}
export const getSolicitudesComprasSinEnviar =()=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudescompra`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_SINENVIAR,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getSolicitudesCompraClienteSinEnviar =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudescompra/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_SINENVIAR,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}


export const getSolicitudesEnviadas =()=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudesenviadas`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_ENVIADAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getSolicitudesClienteEnviadas =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudesenviadas/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_ENVIADAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}
export const getSolicitudesAprobadas =()=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudesaprobadas`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_APROBADAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}
export const getSolicitudesClienteAprobadas =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudesaprobadas/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_APROBADAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getSolicitudesRechazadas =()=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudesrechazadas`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_RECHAZADAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getSolicitudesClienteRechazadas =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudesrechazadas/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUDES_RECHAZADAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}


export const getSolicitudCompra =(solicitudId)=>dispatch=>{
    dispatch({type:LOADING_DATA_ASISTENCIAL});
    apiV1.get(`/getsolicitudcompra/${solicitudId}`)
    .then(res=>{
        dispatch({
            type:SET_SOLICITUD,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}

export const postItemSolicitud=(newitem,solicitudId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postitemsolicitud/${solicitudId}`,newitem)
    .then((res)=>{
        console.log('Entré a Post Item',newitem,solicitudId);
        dispatch({
            type:POST_ITEM_ASISTENCIAL,
            payload:res.data
        });
        
        dispatch(clearErrors());
        dispatch(getSolicitudCompra(solicitudId));

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}
export const postSolicitudEnviar=(solicitudId,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postsolicitudenviar/${solicitudId}`)
    .then((res)=>{
        console.log('Entré a Post Solicitud'+solicitudId);
        dispatch({
            type:POST_SOLICITUD_ENVIAR
        });
        
        dispatch(clearErrors());
        history.push('/dashboard/solicitudesasistencial/missolicitudesenviadas');

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const postSolicitudEnviarCliente=(solicitudId,history,clienteId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postsolicitudenviar/${solicitudId}`)
    .then((res)=>{
        console.log('Entré a Post Solicitud'+solicitudId);
        dispatch({
            type:POST_SOLICITUD_ENVIAR
        });
        
        dispatch(clearErrors());
        history.push(`/dashboard/perfilcliente/${clienteId}`);

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };


  export const getIndicadoresPrestadorCompras =(clienteId)=>dispatch=>{
    dispatch({type:LOADING_INDP});
    apiV1.get(`/getindicadorescompras/${clienteId}`)
    .then(res=>{
        dispatch({
            type:SET_INDICADORES_P,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}
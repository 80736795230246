import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import TablaCronogramaCal from './TablaCronogramaCal'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class CronogramaCalPerfil extends Component {
  handleGoBack = () => {
    const { clienteId } = this.props.match.params;
    const { credentials } = this.props.user;
    
    // Redirigir según el tipo de usuario
    if (credentials.tipouser === "Tecnico") {
      this.props.history.push(`/dashboard/perfilclientetec/${clienteId}`);
    } else {
      // Asumimos que es "Ingeniero" u otro tipo
      this.props.history.push(`/dashboard/perfilcliente/${clienteId}`);
    }
  };

  render() {
    return (
      <div>
        <div className="titulo_section">
          <div className="titulo_clientes">
            <h1 className="text-clientes">Cronograma de Calibración</h1>
            <hr className="clientes_separator"></hr>
          </div>

          <div className="div_boton">
            <MyButton tip="Volver" onClick={this.handleGoBack}>
              <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
            </MyButton>
          </div>
        </div>

        <div className="div-tabla-equipos">
          <TablaCronogramaCal 
            clienteId={this.props.match.params.clienteId} 
            history={this.props.history}
            userType={this.props.user.credentials.tipouser}
          ></TablaCronogramaCal>
        </div>
      </div>
    )
  }
}

CronogramaCalPerfil.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(withRouter(CronogramaCalPerfil));
import React, { useEffect, useRef, useState } from 'react'
import './Caracteristicas.css'
import imgsrc from './alphamonitor.png'

function Caracteristicas() {
    const [isVisible, setIsVisible] = useState(false);
    const monitorRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -100px 0px'
            }
        );

        if (monitorRef.current) {
            observer.observe(monitorRef.current);
        }
        if (textRef.current) {
            observer.observe(textRef.current);
        }

        return () => {
            if (monitorRef.current) {
                observer.unobserve(monitorRef.current);
            }
            if (textRef.current) {
                observer.unobserve(textRef.current);
            }
        };
    }, []);

    return (
        <div id="caracteristicas" className="caracteristicas">
            <div className="container1">
                <img ref={monitorRef} src={imgsrc} alt="logo" className={`monitor ${isVisible ? 'visible' : ''}`}/>
            </div>
            <div ref={textRef} className={`container2 ${isVisible ? 'visible' : ''}`}>
                <h1 className='h1-ch'>
                    Optimiza
                </h1>
                <h2 className='h2-ch'>Los Costos Operativos</h2>
                <h2 className='h2-ch'>De tu Hospital, Gestionando tus</h2>
                <h2 className='h2-ch'>Activos con Alpha Biomédica</h2>
                <a href="/solicitarreunion" className='h3-ch'>Solicita una Reunión</a>
            </div>
        </div>
    )
}

export default Caracteristicas
import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';


import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaRobot, FaInfoCircle, FaClipboard,FaFileAlt } from "react-icons/fa";

import { descripcionGemini } from '../../../redux/actions/dataactions';

import './RecomendacionesDescripcionGemini.css'

export class RecomendacionesDescripcionGemini extends Component {

  constructor() {
    super();
    this.state = {
      nombre: '',
      marca: '',
      modelo: '',
      errors: {},
      aprobado: {},
      open: false
    };

  };
  static getDerivedStateFromProps(props, state) {
    const newState = {};

    if (props.UI.errors) {
      newState.errors = props.UI.errors;
    }

    if (props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }

    return Object.keys(newState).length > 0 ? newState : null;
  }

  componentDidMount() {
    const { equipo } = this.props;
    this.setState({
      nombre: equipo.nombre ? equipo.nombre : '',
      marca: equipo.marca ? equipo.marca : '',
      modelo: equipo.modelo ? equipo.modelo : '',
    });
  }

  handleOpen = () => {
    this.setState({
      open: true, errors: {},
      aprobado: {}
    });
    const dataequipo = {
      nombre: this.state.nombre,
      marca: this.state.marca,
      modelo: this.state.modelo
    }
    console.log(this.props.tipouser)
    console.log(dataequipo);
    this.props.descripcionGemini(dataequipo);
  };

  handleClose = () => {
    this.setState({
      open: false, errors: {},
      aprobado: {}
    });
    console.log(this.props.descripcionIA)
  };

  render() {
    const { UI: { loading } } = this.props;
    const { errors } = this.state;
    const { descripcionIA, equipo } = this.props;
    // Verificamos si descripcion existe como string y no está vacío
    const tieneDescripcion = equipo && equipo.descripcion && typeof equipo.descripcion === 'string' && equipo.descripcion.trim() !== '';

    console.log(this.props.tipouser)
    return (
      <div>
        <Fragment>
          <MyButton
            tip="Descripción del equipo"
            onClick={this.handleOpen}
          >
            <FaClipboard color="#03178C" className="icon-crearcliente" />
          </MyButton>


          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              <div className='container-title-descripcion'>
                <h2 className='title-descripcion'>Descripción del Equipo</h2>
              </div>
            </DialogTitle>

            {loading ? (
              <CircularProgress size={30} color="primary" className="login-progress-descripcion" />
            ) : (
              <div>
                <DialogContent>
                  <div className='container-descripcion'>
                    {/* Mostrar descripción existente si la hay */}
                    {tieneDescripcion && (
                      <div className="descripcion-existente">
                        <div className="seccion-cabecera-descripcion">
                          <FaFileAlt className="icon-seccion-descripcion" />
                          <Typography variant="h6" className="seccion-titulo-descripcion">
                            Descripción Registrada
                          </Typography>
                        </div>
                        
                        <div className="descripcion-item">
                          <p className='descripcion-text'>{equipo.descripcion}</p>
                        </div>
                        
                        <Divider style={{ margin: '20px 0' }} />
                      </div>
                    )}

                    {/* Mostrar descripción de IA */}
                    {descripcionIA !== undefined && (
                      <div className="descripcion-ia">
                        <div className="seccion-cabecera-descripcion">
                          <FaRobot className="icon-seccion-descripcion" />
                          <Typography variant="h6" className="seccion-titulo-descripcion">
                            Descripción Generada por IA
                          </Typography>
                        </div>
                        <div className="descripcion-item">
                          <p className='descripcion-text'>{descripcionIA}</p>
                        </div>
                      </div>
                    )}

                    {/* Mostrar mensaje si no hay ninguna descripción */}
                    {(!tieneDescripcion && (!descripcionIA)) && (
                      <div className="no-descripcion">
                        <Typography variant="body1">
                          No hay descripción disponible para este equipo.
                        </Typography>
                      </div>
                    )}
                  </div>
                </DialogContent>
              </div>
            )}

            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
              </MyButton>
            </DialogActions>

          </Dialog>
        </Fragment>
      </div >
    )
  }
}

RecomendacionesDescripcionGemini.propTypes = {
  descripcionGemini: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  tipouser: PropTypes.string.isRequired,
  descripcionIA: PropTypes.string
};

const mapActionsToProps = { descripcionGemini };

const mapStateToProps = (state) => ({
  equipo: state.data.equipo.credentials,
  UI: state.UI,
  tipouser: state.user.credentials.tipouser,
  descripcionIA: state.data.descripcionIA,
});

export default connect(mapStateToProps, mapActionsToProps)(RecomendacionesDescripcionGemini);
import React from 'react';
import './ListadoPrecios.css';
import { FaWhatsapp, FaCheckCircle, FaChevronRight } from 'react-icons/fa';
import { MdEventAvailable } from 'react-icons/md';
import FooterHome from '../Home/HomeComponents/Footer/FooterHome'
function ListadoPrecios() {
  const planes = [
    {
      titulo: "Licencia Anual",
      precio: "3.950.000",
      destacado: true,
      badge: "MÁS POPULAR",
      caracteristicas: [
        "Registro de equipos ilimitados",
        "Registro ilimitado de sedes",
        "Registro ilimitado de usuarios",
        "Registro ilimitado de actividades ",
        "Almacenamiento ilimitado",
        "Sin cobro por implementación",
        "Capacitaciones incluidas"
      ],
      notas: "Con este plan tendras todos los beneficios y ahorrarás 20% en su adquisición. Valor mes $330.000 COP"
    },
    {
      titulo: "Licencia Pago por Uso (SAAS)",
      precio: "960.000",
      caracteristicas: [
        "Registro de equipos ilimitados",
        "Registro ilimitado de sedes",
        "Registro ilimitado de usuarios",
        "Registro ilimitado de actividades ",
        "Almacenamiento ilimitado",        ,
        "Paga solo por lo que usas"
      ],
      notas: "Cobros mensuales por actividades: Mantenimiento Preventivo $1.500 COP, Calibración $1.500 COP, Mantenimiento Correctivo $1.800 COP"
    },
    {
      titulo: "Licencia 50/50",
      precio: "4.500.000",
      caracteristicas: [
        "Registro de equipos ilimitados",
        "Registro ilimitado de sedes",
        "Registro ilimitado de usuarios",
        "Registro ilimitado de actividades ",
        "Almacenamiento ilimitado",
        "Sin cobro por implementación",
        "Capacitaciones incluidas"
      ],
      notas: "Pago inicial de $2.250.000 COP y otro pago de $2.250.000 COP a los 6 meses"
    }
  ];

  const abrirWhatsApp = () => {
    const mensaje = "¡Hola! Me interesa conocer más sobre los planes de Alpha Biomedica";
    const numeroWhatsApp = "+573218472298"; // Reemplazar con el número real
    window.open(`https://wa.me/${numeroWhatsApp}?text=${encodeURIComponent(mensaje)}`, '_blank');
  };

  const abrirCalendly = () => {
    // Reemplazar con el link real de Calendly
    window.open('https://alphabiomedica.com/solicitarreunion', '_blank');
  };

  return (
    <>
    <div id="listado-precios">
      <h1>Planes Diseñados para Ti</h1>
      <div className="planes-container">
        {planes.map((plan, index) => (
          <div 
            key={index} 
            className={`plan-card ${plan.destacado ? 'destacado' : ''}`}
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            {plan.badge && <span className="badge">{plan.badge}</span>}
            <h2>{plan.titulo}</h2>
            <div className="precio">
              <span className="moneda">$</span>
              <span className="cantidad">{plan.precio}</span>
              <span className="moneda">COP</span>
            </div>
            <ul className="caracteristicas-precios">
              {plan.caracteristicas.map((caracteristica, idx) => (
                <li key={idx}>
                  <FaCheckCircle className="check-icon" />
                  {caracteristica}
                </li>
              ))}
            </ul>
            {plan.notas && (
              <div className="notas">
                <p>{plan.notas}</p>
              </div>
            )}
            <div className="botones-accion">
              <button 
                className="boton-primario"
                onClick={abrirCalendly}
              >
                <MdEventAvailable className="icon" />
                Agendar una Reunión
                <FaChevronRight className="icon" />
              </button>
              <a 
                href="#" 
                className="boton-whatsapp"
                onClick={(e) => {
                  e.preventDefault();
                  abrirWhatsApp();
                }}
              >
                <FaWhatsapp className="icon" />
                Contactar por WhatsApp
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
   <FooterHome></FooterHome>
    </>
  );
}

export default ListadoPrecios;
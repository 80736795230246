import React, { Component } from 'react';
import './ListaMtoProg.css'; 
import {getprogmtos,clearErrors,deleteProgMto,editProgMto,postMtoTablaV2} from '../../../redux/actions/dataactions';
import {getUsuariosAsistenciales} from '../../../redux/actions/useractions'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FaArrowLeft,FaCheck,FaCheckCircle,FaPlus, FaTrash } from "react-icons/fa";
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Button from '@material-ui/core/Button';


export class ListaMtoProg extends Component {
    
  constructor(){
    super();
    this.state={
        chasis:false,
        vaccesorios:false,
        vcontroles:false,
        vgeneral:false,
        limpieza:false,
        lelectronica:false,
        pcuantitativa:false,
        selectrica:false,
        lchasis:false,
        lubricacion:false,
        prueba1:'',
        instrumento1:'',
        valor1:'',
        aprobo1:'',
        prueba2:'',
        instrumento2:'',
        valor2:'',
        aprobo2:'',
        observaciones:'',
        horainicio:'',
        horafinal:'',
        firmarecibe:'',
        cargorecibe:'',
        fecha:'',
        // New fields for reception methods
        metodoRecepcion: 'manual', // default method
        // Digital signature fields
        metodoRecepcion: 'manual',
        firmaDigital: null,
        firmaGuardada: false, // Nuevo estado para mostrar confirmación de firma guardada
        uploadingImage: false, // Nuevo estado para controlar la subida de imágenes
        nombreFirmante: '',
        cargoFirmante: '',
        // User selection fields
        usuarioRecibe: '',
        // Image upload fields
        imagenFirma: null,
        nombreFirmaImagen: '',
        cargoFirmaImagen: '',
        // Existing fields
        errors:{},
        aprobado:{},
        open:false,
        opendelete:false,
        progid:'',
        clienteid:'',
        usuariosAsistenciales: [],
        
    };
    
    // Refs for digital signature and image upload
    this.canvasRef = React.createRef();
};

// Initialize canvas after component mounts
componentDidMount(){
    this.props.getprogmtos(this.props.equipoId);
    
    // Add event listeners for window resize to handle canvas responsiveness
    window.addEventListener('resize', this.resizeCanvas);
  }

  componentDidUpdate(prevProps, prevState) {
    // Initialize canvas when metodoRecepcion changes to "firma"
    if (prevState.metodoRecepcion !== this.state.metodoRecepcion) {
      if (this.state.metodoRecepcion === "firma" && this.canvasRef.current) {
        this.initCanvas();
      } else if (this.state.metodoRecepcion === "usuario") {
        // Cargar usuarios asistenciales cuando se selecciona este método
        this.props.getUsuariosAsistenciales(this.props.clienteId);
      }
    }
  }
componentWillUnmount() {
    // Clean up event listeners
    window.removeEventListener('resize', this.resizeCanvas);
}

// Initialize the canvas for signature
initCanvas = () => {
    if (!this.canvasRef.current) return;
    
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Set up canvas
    this.resizeCanvas();
    
    // Clear the canvas
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    // Set up drawing state
    this.isDrawing = false;
    this.lastX = 0;
    this.lastY = 0;
    
    // Set up event listeners for drawing
    canvas.addEventListener('mousedown', this.startDrawing);
    canvas.addEventListener('mousemove', this.draw);
    canvas.addEventListener('mouseup', this.stopDrawing);
    canvas.addEventListener('mouseout', this.stopDrawing);
    
    // Touch events
    canvas.addEventListener('touchstart', this.startDrawingTouch);
    canvas.addEventListener('touchmove', this.drawTouch);
    canvas.addEventListener('touchend', this.stopDrawing);
}

// Resize canvas to fit container
resizeCanvas = () => {
    if (!this.canvasRef.current) return;
    
    const canvas = this.canvasRef.current;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const container = canvas.parentElement;
    
    canvas.width = container.clientWidth * ratio;
    canvas.height = 200 * ratio;
    canvas.style.width = `${container.clientWidth}px`;
    canvas.style.height = "200px";
    
    const ctx = canvas.getContext("2d");
    ctx.scale(ratio, ratio);
    
    // Set drawing styles
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.lineWidth = 2;
    ctx.strokeStyle = 'black';
    
    // Fill with white background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
}

// Start drawing
startDrawing = (e) => {
    const canvas = this.canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    this.isDrawing = true;
    this.lastX = e.clientX - rect.left;
    this.lastY = e.clientY - rect.top;
}

// Handle touch start
startDrawingTouch = (e) => {
    e.preventDefault();
    if (e.touches.length !== 1) return;
    
    const touch = e.touches[0];
    const canvas = this.canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    
    this.isDrawing = true;
    this.lastX = touch.clientX - rect.left;
    this.lastY = touch.clientY - rect.top;
}

// Draw on the canvas
draw = (e) => {
    if (!this.isDrawing) return;
    
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    
    const currentX = e.clientX - rect.left;
    const currentY = e.clientY - rect.top;
    
    ctx.beginPath();
    ctx.moveTo(this.lastX, this.lastY);
    ctx.lineTo(currentX, currentY);
    ctx.stroke();
    
    this.lastX = currentX;
    this.lastY = currentY;
}

// Handle touch move
drawTouch = (e) => {
    e.preventDefault();
    if (!this.isDrawing || e.touches.length !== 1) return;
    
    const touch = e.touches[0];
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    
    const currentX = touch.clientX - rect.left;
    const currentY = touch.clientY - rect.top;
    
    ctx.beginPath();
    ctx.moveTo(this.lastX, this.lastY);
    ctx.lineTo(currentX, currentY);
    ctx.stroke();
    
    this.lastX = currentX;
    this.lastY = currentY;
}

// Stop drawing
stopDrawing = () => {
    this.isDrawing = false;
}

// Clear digital signature
clearSignature = () => {
    if (!this.canvasRef.current) return;
    
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // Clear canvas
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    
    this.setState({ firmaDigital: null });
}

// Check if canvas is empty
isCanvasEmpty = () => {
    if (!this.canvasRef.current) return true;
    
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    const pixelData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
    
    // Check if all pixel data is white (255, 255, 255, 255)
    for (let i = 0; i < pixelData.length; i += 4) {
        // Check RGB values (skip alpha)
        if (pixelData[i] !== 255 || pixelData[i + 1] !== 255 || pixelData[i + 2] !== 255) {
            return false; // Found a non-white pixel
        }
    }
    
    return true; // Canvas is empty (all white)
}

// Save digital signature
saveSignature = () => {
  if (!this.canvasRef.current) return;
  
  if (!this.isCanvasEmpty()) {
    const signatureData = this.canvasRef.current.toDataURL('image/png');
    
    // Guardar la firma y establecer firmaGuardada a true
    this.setState({ 
      firmaDigital: signatureData,
      firmaGuardada: true, // Indicador visual de que se guardó la firma
      errors: {
        ...this.state.errors,
        firmaDigital: null
      }
    });
    
    // Mostrar un mensaje de confirmación que desaparece después de unos segundos
    setTimeout(() => {
      this.setState({ firmaGuardada: false });
    }, 3000);
  } else {
    // Mostrar error - firma vacía
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        firmaDigital: "Es necesario firmar antes de guardar"
      }
    }));
  }
}

// Handle image upload
handleImageUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    // Indicar que estamos cargando la imagen
    this.setState({ uploadingImage: true });
    
    const reader = new FileReader();
    
    reader.onload = (e) => {
      this.setState({ 
        imagenFirma: e.target.result,
        uploadingImage: false, // Ya no estamos cargando
        errors: {
          ...this.state.errors,
          imagenFirma: null
        }
      });
    };
    
    reader.onerror = () => {
      this.setState({ 
        uploadingImage: false,
        errors: {
          ...this.state.errors,
          imagenFirma: "Error al cargar la imagen"
        }
      });
    };
    
    reader.readAsDataURL(file);
  }
}

handleImageButtonClick = () => {
  // Crear un input file temporal en lugar de usar una referencia persistente
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = 'image/*';
  
  // Configurar el manejador de eventos antes de activar el input
  fileInput.addEventListener('change', (event) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({ uploadingImage: true });
      
      const reader = new FileReader();
      
      reader.onload = (e) => {
        this.setState({ 
          imagenFirma: e.target.result,
          uploadingImage: false,
          errors: {
            ...this.state.errors,
            imagenFirma: null
          }
        });
      };
      
      reader.onerror = () => {
        this.setState({ 
          uploadingImage: false,
          errors: {
            ...this.state.errors,
            imagenFirma: "Error al cargar la imagen"
          }
        });
      };
      
      reader.readAsDataURL(file);
    }
  });
  
  // Simular clic en el input
  fileInput.click();
}
// Get user info by ID from props
getUserInfo = (userId, field) => {
  const { usuariosAsistenciales } = this.props.user;
  
  if (!usuariosAsistenciales) return '';
  
  const user = usuariosAsistenciales.find(u => u.idUsuario === userId);
  
  // Si el campo es 'cargo' pero no existe, proporcionar un valor por defecto
  if (field === 'cargo' && (!user || !user[field])) {
    return 'Asistencial';
  }
  
  return user ? user[field] : '';
}

// Modified handlePostMto to include the new reception methods
handlePostMto = () => {
  const { credentials } = this.props.user;
  
  // Determine which reception method to use and prepare the data accordingly
  let receptorInfo = {};
  
  switch(this.state.metodoRecepcion) {
    case 'manual':
      receptorInfo = {
        firmarecibe: this.state.firmarecibe,
        cargorecibe: this.state.cargorecibe,
        metodoRecepcion: 'manual'
      };
      break;
        
    case 'firma':
      // Get the signature data from the canvas
      if (this.canvasRef.current && !this.isCanvasEmpty()) {
        const signatureData = this.canvasRef.current.toDataURL('image/png');
          
        receptorInfo = {
          nombreFirmante: this.state.nombreFirmante,
          cargoFirmante: this.state.cargoFirmante,
          firmaDigital: signatureData,
          metodoRecepcion: 'firma'
        };
      } else {
        // Show error if no signature
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            firmaDigital: "Es necesario firmar antes de guardar"
          }
        }));
        return; // Exit without submitting
      }
      break;
        
    case 'usuario':
      if (this.state.usuarioRecibe) {
        receptorInfo = {
          usuarioRecibe: this.state.usuarioRecibe,
          metodoRecepcion: 'usuario'
        };
      } else {
        // Show error if no user selected
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            usuarioRecibe: "Debe seleccionar un usuario"
          }
        }));
        return; // Exit without submitting
      }
      break;
        
    case 'imagen':
      if (this.state.imagenFirma) {
        receptorInfo = {
          nombreFirmaImagen: this.state.nombreFirmaImagen,
          cargoFirmaImagen: this.state.cargoFirmaImagen,
          imagenFirma: this.state.imagenFirma,
          metodoRecepcion: 'imagen'
        };
      } else {
        // Show error if no image uploaded
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            imagenFirma: "Se requiere una imagen de firma"
          }
        }));
        return; // Exit without submitting
      }
      break;
        
    default:
      receptorInfo = {
        firmarecibe: this.state.firmarecibe,
        cargorecibe: this.state.cargorecibe,
        metodoRecepcion: 'manual'
      };
  }
  
  // Prepare the complete maintenance data object
  const newMtoPreventivo = {
    chasis: this.state.chasis,
    vaccesorios: this.state.vaccesorios,
    vcontroles: this.state.vcontroles,
    vgeneral: this.state.vgeneral,
    limpieza: this.state.limpieza,
    lelectronica: this.state.lelectronica,
    pcuantitativa: this.state.pcuantitativa,
    selectrica: this.state.selectrica,
    lchasis: this.state.lchasis,
    lubricacion: this.state.lubricacion,
    prueba1: this.state.prueba1,
    instrumento1: this.state.instrumento1,
    valor1: this.state.valor1,
    aprobo1: this.state.aprobo1,
    prueba2: this.state.prueba2,
    instrumento2: this.state.instrumento2,
    valor2: this.state.valor2,
    aprobo2: this.state.aprobo2,
    observaciones: this.state.observaciones,
    horainicio: this.state.horainicio,
    horafinal: this.state.horafinal,
    fecha: this.state.fecha,
    ...receptorInfo  // Include the reception method data
  };

  console.log(newMtoPreventivo);

  // Determine which user handle to use based on user type
  const userHandle = credentials.tipouser === "Tecnico" 
    ? credentials.handleuser 
    : credentials.handle;
  
  // Use the new V2 function for submission
  this.props.postMtoTablaV2(
    newMtoPreventivo, 
    this.props.equipoId, 
    this.props.clienteId, 
    this.state.progid, 
    userHandle
  );
}

      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
     
    

    handleOpen=(rawdataprogid,rawdatafecha,rawdatahora)=>{
       

        this.setState({open:true,aprobado:{},progid:rawdataprogid,fecha:rawdatafecha,horainicio:rawdatahora});
    
        };

     handleClose=()=>{
           // this.props.clearErrors();
           if(this.state.aprobado.mensaje){
              this.handleEditProgMto();
              this.setState({open:false,errors:{},aprobado:{}});
           }else{
            this.setState({open:false,errors:{},aprobado:{}});
           }
            
            };

    
     handleOpenDelete=(rawdataprogid,rawdataclienteid)=>{
    
                this.setState({opendelete:true,progid:rawdataprogid,clienteid:rawdataclienteid});
               
                };
        
    handleCloseDelete=()=>{
                   // this.props.clearErrors();
                    this.setState({opendelete:false,errors:{},aprobado:{}});
                    };
    handleDelete=()=>{
      const {credentials}=this.props.user;
      if(credentials.tipouser==="Tecnico"){
        console.log("Mantenimiento borrado desde:"+credentials.tipouser)
        console.log("Perfil de Ingenieria"+credentials.handleuser)
        //mandar el user de ingenieria
        this.props.deleteProgMto(this.state.clienteid,this.state.progid,this.props.equipoId,credentials.handleuser);
        
       // this.handleEditProgMto(this.state.progid); 
            }else{
              //mandar el handle user normal 
              console.log("Mantenimiento enviado desde:"+credentials.tipouser)
              console.log("Perfil de Ingenieria"+credentials.handle)
              this.props.deleteProgMto(this.state.clienteid,this.state.progid,this.props.equipoId,credentials.handle);
              //this.handleEditProgMto(this.state.progid); 
            }
     
     
    
  
    }

      handleChange=(event)=>{
                      this.setState({ [event.target.name]:event.target.value});
                      
                 };
    
    handleEditProgMto=()=>{

      this.props.editProgMto({nuevoestado:'Realizado'},this.state.progid,this.props.equipoId); 
   
    }


    handleMethodChange = (event) => {
      const method = event.target.value;
      this.setState({ metodoRecepcion: method });
      
      // Si selecciona usuario, cargar la lista de usuarios asistenciales
      if (method === 'usuario') {
        this.props.getUsuariosAsistenciales(this.props.clienteId);
      }
    }
    
    render() {
        const {progmtos,loadingmto}= this.props.data; 
      
        const {errors}=this.state;
        const{aprobado}=this.state;
        const { UI:{loading}}=this.props; 
     
        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };
        
        const columns=[
            {title:'Fecha',field:'fecha'},
            {title:'Mes',field:'mesnombre'},
            {title:'Hora',field:'hora'},
            {title:'Duración',field:'duracion'},
            {title:'Estado',field:'estado'},
            {title:'Actividades',field:'actividades'}
           
        ]


        
     let mapeoprogmtos=!loadingmto?(
         <div>
    <MaterialTable
            columns={columns}
            data={progmtos}
            title={
              <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'600' }}>Mantenimientos Preventivos Programados</h2>
            }
            icons={tableIcons}
            options={{
              cellStyle: {
                  fontSize: 12,
                  fontWeight:500,
                  color:'#696B6D'
                
               
                },
                headerStyle: {
                  fontSize: 14,
                  fontWeight: 'bolder',
                  color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
                }
            
            }}
    
            actions={[
                
              rowData => ({
                icon: tableIcons.Add,
                tooltip: 'Hacer Mantenimiento',
                onClick: (event, rowData) => {
                    this.handleOpen(rowData.progid,rowData.fecha,rowData.hora);
                },
                disabled: rowData.estado==='Realizado'
              })
             ,
                
                {
                  icon: tableIcons.Delete,
                  tooltip: 'Borrar Programación',
                  onClick: (event, rowData) => {
                      
                    this.handleOpenDelete(rowData.progid,this.props.clienteId);
                  }
                }
              ]}
    
            >
    
            </MaterialTable>

     
         </div>
           

        ):(  <div className="div_cargando">
            <CircularProgress size={50}color="primary" className="login-progress" />
            </div>);
            
       

        return (
           <div className="listamtoprog-container">
              
            <div className="listamtoprog-table">
            {mapeoprogmtos}
            </div>

            <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
              {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Cargado Exitosamente</h2>
              </div>
                
                ) : (<div>
                     <DialogTitle>Realizar Reporte de Mantenimiento Preventivo</DialogTitle>
                  <DialogContent>
                    <form>

                    <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    <h4 className="text-title-accorion">Actividades Realizadas</h4>
                </AccordionSummary>
                <AccordionDetails>
                <div className="div-accordion-container">
                <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Inspección Chásis</InputLabel>
                          <Select  required  name="chasis" id="chasis" value={this.state.chasis} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.chasis}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Verificar Accesorios</InputLabel>
                          <Select  required  name="vaccesorios" id="vaccesorios" value={this.state.vaccesorios} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.vaccesorios}</FormHelperText>
                    </FormControl> 
                    </div>
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Verificar Controles</InputLabel>
                          <Select  required  name="vcontroles" id="vcontroles" value={this.state.vcontroles} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.vcontroles}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Prueba Cuantitativa</InputLabel>
                          <Select  required  name="pcuantitativa" id="pcuantitativa" value={this.state.pcuantitativa} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.pcuantitativa}</FormHelperText>
                    </FormControl> 
                    </div>
                    
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Seguridad Eléctrica</InputLabel>
                          <Select  required  name="selectrica" id="selectrica" value={this.state.selectrica} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.selectrica}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Limpieza</InputLabel>
                          <Select  required  name="limpieza" id="limpieza" value={this.state.limpieza} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.limpieza}</FormHelperText>
                    </FormControl> 
                    </div>
                    
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Lubricación de partes</InputLabel>
                          <Select  required  name="lubricacion" id="lubricacion" value={this.state.lubricacion} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.lubricacion}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Limpieza Electronica</InputLabel>
                          <Select  required  name="lelectronica" id="lelectronica" value={this.state.lelectronica} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.lelectronica}</FormHelperText>
                    </FormControl> 
                    </div>
                    
                    <div className="div-input-doble-mtoprog">
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Limpieza de Chásis</InputLabel>
                          <Select  required  name="lchasis" id="lchasis" value={this.state.lchasis} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem> 
                                    </Select>
                                    <FormHelperText>{errors.lchasis}</FormHelperText>
                    </FormControl>  
                    <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Verificación General</InputLabel>
                          <Select  required  name="vgeneral" id="vgeneral" value={this.state.vgeneral} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.vgeneral}</FormHelperText>
                    </FormControl> 
                    </div>

                </div>

                  </AccordionDetails>
                  </Accordion>

                  <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                <h4 className="text-title-accorion">Mediciones Realizadas</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="div-accordion-container">
                  <div className="div-mediciones-container">
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="prueba1"
                              id="prueba1"
                              label="Prueba"
                              value={this.state.prueba1}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.prueba1}
                              error={errors.prueba1?true:false}
                          
                              />
                      </div>
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="instrumento1"
                              id="instrumento1"
                              label="Instrumento"
                              value={this.state.instrumento1}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.instrumento1}
                              error={errors.instrumento1?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="valor1"
                              id="valor1"
                              label="Valor"
                              value={this.state.valor1}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.valor1}
                              error={errors.valor1?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Aprobó</InputLabel>
                          <Select    name="aprobo1" id="aprobo1" value={this.state.aprobo1} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.aprobo1}</FormHelperText>
                    </FormControl> 


                      </div>
                    


                    </div>

                    <div className="div-mediciones-container">
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                              
                              type="Text"
                              name="prueba2"
                              id="prueba2"
                              label="Prueba"
                              value={this.state.prueba2}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.prueba2}
                              error={errors.prueba2?true:false}
                          
                              />
                      </div>
                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                          
                              type="Text"
                              name="instrumento2"
                              id="instrumento2"
                              label="Instrumento"
                              value={this.state.instrumento2}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.instrumento2}
                              error={errors.instrumento2?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <TextField className="login-input-email"
                            
                              type="Text"
                              name="valor2"
                              id="valor2"
                              label="Valor"
                              value={this.state.valor2}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.valor2}
                              error={errors.valor2?true:false}
                          
                              />
                      </div>

                      <div className="div-mediciones-item">
                      <FormControl className="formcontrol-nuevomto">
                        <InputLabel>Aprobó</InputLabel>
                          <Select    name="valor2" id="valor2" value={this.state.valor2} onChange={this.handleChange}
                                    fullWidth>
                                    <MenuItem value=""><em></em></MenuItem>
                                    <MenuItem value={true}>Si</MenuItem>
                                    <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.valor2}</FormHelperText>
                    </FormControl> 


                      </div>
                    


                    </div>


                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <h4 className="text-title-accorion">Observaciones</h4>
                </AccordionSummary>
                <AccordionDetails>
                <TextField className="login-input-email"
                              
                              multiline
                              type="Text"
                              name="observaciones"
                              id="observaciones"
                              label="Observaciones"
                              rows={4}
                              value={this.state.observaciones}
                              onChange={this.handleChange}
                              fullWidth
                              helperText={errors.observaciones}
                              error={errors.observaciones?true:false}
                          
                              />
                </AccordionDetails>
              </Accordion>
                  
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                    <h4 className="text-title-accorion">Fecha y Hora</h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="div-accordion-container">
                  <TextField className="login-input-email"
                              required
                              type="date"
                              name="fecha"
                              id="fecha"
                              label="Fecha de Realización"
                              value={this.state.fecha}
                              onChange={this.handleChange}
                              min="2019-01-01" max="2022-01-01"
                              fullWidth
                              helperText={errors.fecha}
                              error={errors.fecha?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              />
                  <div className="div-input-doble-mtoprog">
                  <TextField className="login-input-email"
                              required
                              type="time"
                              name="horainicio"
                              id="horainicio"
                              label="Hora de Inicio"
                              value={this.state.horainicio}
                              onChange={this.handleChange}
                              min="2000-01-01" max="2022-01-01"
                              fullWidth
                              helperText={errors.horainicio}
                              error={errors.horainicio?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              />
                    <TextField className="login-input-email"
                              required
                              type="time"
                              name="horafinal"
                              id="horafinal"
                              label="Hora Final"
                              value={this.state.horafinal}
                              onChange={this.handleChange}
                              min="2000-01-01" max="2022-01-01"
                              fullWidth
                              helperText={errors.horafinal}
                              error={errors.horafinal?true:false}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              />
                
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1a-content"
    id="panel1a-header"
  >
    <h4 className="text-title-accorion">Personal que recibe la actividad</h4>
  </AccordionSummary>
  <AccordionDetails>
    <div className="div-accordion-container">
      {/* Method selection */}
      <FormControl className="formcontrol-nuevomto" fullWidth>
        <InputLabel>Método de recepción</InputLabel>
        <Select
          name="metodoRecepcion"
          id="metodoRecepcion"
          value={this.state.metodoRecepcion || "manual"}
          onChange={(e) => {
            this.handleChange(e);
            this.handleMethodChange(e);
          }}
          fullWidth
        >
          <MenuItem value="manual">Ingreso manual</MenuItem>
          <MenuItem value="firma">Firma digital</MenuItem>
          <MenuItem value="usuario">Seleccionar usuario</MenuItem>
          <MenuItem value="imagen">Cargar imagen</MenuItem>
        </Select>
        <FormHelperText>{errors.metodoRecepcion}</FormHelperText>
      </FormControl>

      {/* Different components based on selection */}
      {this.state.metodoRecepcion === "manual" || !this.state.metodoRecepcion ? (
        <div className="div-firma-digital">
          <TextField 
            className="login-input-email"
            required
            type="Text"
            name="firmarecibe"
            id="firmarecibe"
            label="Nombre de Quien Recibe La Actividad"
            value={this.state.firmarecibe}
            onChange={this.handleChange}
            fullWidth
            helperText={errors.firmarecibe}
            error={errors.firmarecibe ? true : false}
          />

          <TextField 
            className="login-input-email"
            required
            type="Text"
            name="cargorecibe"
            id="cargorecibe"
            label="Cargo de Quien Recibe la Actividad"
            value={this.state.cargorecibe}
            onChange={this.handleChange}
            fullWidth
            helperText={errors.cargorecibe}
            error={errors.cargorecibe ? true : false}
          />
        </div>
      ) : this.state.metodoRecepcion === "firma" ? (
        <div className="div-firma-digital">
          <div className="firma-canvas-container">
            <canvas 
              ref={this.canvasRef}
              id="firma-digital-canvas" 
              className="firma-canvas"
              width="500" 
              height="200"
            />
          </div>
          <div className="div-firma-buttons">
          <Button 
            variant="outlined"
            onClick={this.clearSignature}
            className="btn-limpiar-firma"
          >
            Limpiar firma
          </Button>
          <Button 
            variant="contained"
            onClick={this.saveSignature}
            className="btn-guardar-firma"
          >
            Guardar firma
          </Button>
          </div>
          {/* Indicador de firma guardada */}
          {this.state.firmaGuardada && (
            <div className="firma-guardada-indicador" style={{ 
              color: '#20B90C', 
              marginTop: '10px', 
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center' 
            }}>
              <FaCheckCircle style={{ marginRight: '5px' }} />
              <span>Firma guardada correctamente</span>
            </div>
          )}
          <TextField 
            className="login-input-email mt-2"
            required
            type="Text"
            name="nombreFirmante"
            id="nombreFirmante"
            label="Nombre del firmante"
            value={this.state.nombreFirmante}
            onChange={this.handleChange}
            fullWidth
            helperText={errors.nombreFirmante}
            error={errors.nombreFirmante ? true : false}
          />
          <TextField 
            className="login-input-email"
            required
            type="Text"
            name="cargoFirmante"
            id="cargoFirmante"
            label="Cargo del firmante"
            value={this.state.cargoFirmante}
            onChange={this.handleChange}
            fullWidth
            helperText={errors.cargoFirmante}
            error={errors.cargoFirmante ? true : false}
          />
        </div>
      ) : this.state.metodoRecepcion === "usuario" ? (
        <div className="div-seleccion-usuario">
          <FormControl className="formcontrol-nuevomto" fullWidth>
            <InputLabel>Usuario que recibe</InputLabel>
            <Select
              name="usuarioRecibe"
              id="usuarioRecibe"
              value={this.state.usuarioRecibe || ""}
              onChange={this.handleChange}
              fullWidth
            >
              <MenuItem value=""><em>Seleccione un usuario</em></MenuItem>
              {this.props.user.usuariosAsistenciales && this.props.user.usuariosAsistenciales.map(user => (
                <MenuItem key={user.idUsuario} value={user.idUsuario}>
                  {user.nombre} {user.cargo ? `- ${user.cargo}` : ''}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.usuarioRecibe}</FormHelperText>
          </FormControl>
          {this.state.usuarioRecibe && (
            <div className="div-usuario-info" style={{
              marginTop: '10px',
              padding: '10px',
              backgroundColor: '#f5f5f5',
              borderRadius: '4px'
            }}>
              <p className="usuario-info-nombre">
                <strong>Nombre: </strong> 
                {this.getUserInfo(this.state.usuarioRecibe, 'nombre')}
              </p>
              <p className="usuario-info-cargo">
                <strong>Cargo: </strong> 
                {this.getUserInfo(this.state.usuarioRecibe, 'cargo')}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="div-carga-imagen">
         
         <Button
          variant="contained"
          onClick={this.handleImageButtonClick}
          disabled={this.state.uploadingImage}
          className="btn-guardar-firma"
        >
          {this.state.uploadingImage ? 'Procesando...' : 'Cargar imagen de firma'}
        </Button>

        {this.state.imagenFirma && (
            <div className="div-preview-imagen">
              <img 
                src={this.state.imagenFirma} 
                alt="Vista previa de firma" 
                className="preview-imagen-firma"
                style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '10px' }}
              />
            </div>
          )}
          <TextField 
            className="login-input-email mt-2"
            required
            type="Text"
            name="nombreFirmaImagen"
            id="nombreFirmaImagen"
            label="Nombre del firmante"
            value={this.state.nombreFirmaImagen}
            onChange={this.handleChange}
            fullWidth
            helperText={errors.nombreFirmaImagen}
            error={errors.nombreFirmaImagen ? true : false}
          />
          <TextField 
            className="login-input-email"
            required
            type="Text"
            name="cargoFirmaImagen"
            id="cargoFirmaImagen"
            label="Cargo del firmante"
            value={this.state.cargoFirmaImagen}
            onChange={this.handleChange}
            fullWidth
            helperText={errors.cargoFirmaImagen}
            error={errors.cargoFirmaImagen ? true : false}
          />
        </div>
      )}
    </div>
  </AccordionDetails>
</Accordion>
                
                    </form>  
                  </DialogContent>
                </div>
               
                )}
            
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Realizar Mantenimiento" onClick={this.handlePostMto}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
           </DialogActions>

            </Dialog>
          
           
           
            <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Borrado Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar programación de Mto?</DialogTitle>
                </div>)}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Programación" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
            </Dialog>
        
           </div>

         
        )
    }
}

ListaMtoProg.propTypes = {
  
  postMtoTablaV2:PropTypes.func.isRequired,
  getUsuariosAsistenciales:PropTypes.func.isRequired,
    getprogmtos: PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,    
    data:PropTypes.object.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteProgMto:PropTypes.func.isRequired,
    editProgMto:PropTypes.func.isRequired
  };

const mapActionsToProps={getprogmtos,clearErrors,deleteProgMto,editProgMto,postMtoTablaV2, getUsuariosAsistenciales};

const mapStateToProps = (state)=>({
    data:state.data,
    user:state.user,
    UI:state.UI
})



export default connect(mapStateToProps,mapActionsToProps)(ListaMtoProg);




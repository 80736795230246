import React, { Component } from 'react';
import { FaRocket } from 'react-icons/fa';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import './IndicadoresPrestadorYear.css';

class IndicadoresPrestadorYear extends Component {
    state = {
        isOpen: false,
        selectedYear: this.props.initialYear || new Date().getFullYear()
    };

    componentDidMount() {
        // Actualizar el estado inicial con el año proporcionado
        if (this.props.initialYear) {
            this.setState({ selectedYear: this.props.initialYear });
        }
    }

    componentDidUpdate(prevProps) {
        // Actualizar el estado cuando cambia el año desde las props
        if (prevProps.initialYear !== this.props.initialYear && this.props.initialYear) {
            this.setState({ selectedYear: this.props.initialYear });
        }
    }

    // Genera array de años (año actual + 2 años atrás)
    getYears = () => {
        const currentYear = new Date().getFullYear();
        return Array.from({ length: 3 }, (_, i) => currentYear - i);
    };

    handleYearSelect = (year) => {
        this.setState({ 
            selectedYear: year,
            isOpen: false 
        });
        if (this.props.onYearChange) {
            this.props.onYearChange(year);
        }
    };

    handleOpen = () => {
        this.setState({ isOpen: true });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const { isOpen, selectedYear } = this.state;
        const years = this.getYears();

        return (
            <>
                <div className="dashing-card-indp" onClick={this.handleOpen}>
                    <div className="dashing-cardcontainer-indp">
                        <div className="dashing-cardcontent-indp">
                            <div className="dashing-cardtext-indp">
                                <p className="dashing-text-card-indp">
                                    Año
                                </p>
                                <div className="year-selector-container">
                                    <h3 className="dashing-value-card-indp">
                                        {selectedYear}
                                    </h3>
                                </div>
                            </div>
                            <div className="dashing-cardicon-indp">
                                <FaRocket color="#FFFFFF" className="dashing-icon-indp"/>
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog 
                    open={isOpen} 
                    onClose={this.handleClose}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle>Seleccionar Año</DialogTitle>
                    <DialogContent>
                        <div className="year-dialog-options">
                            {years.map((year) => (
                                <Button
                                    key={year}
                                    fullWidth
                                    variant={selectedYear === year ? "contained" : "text"}
                                    color="primary"
                                    onClick={() => this.handleYearSelect(year)}
                                    className="year-dialog-button"
                                >
                                    {year}
                                </Button>
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

export default IndicadoresPrestadorYear;
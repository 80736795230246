import React from 'react';
import './FooterHome.css'; // Importa los estilos
import imgsrc from './logo.png'
import { Link } from 'react-router-dom';
function FooterHome() {
  return (
    <footer className="footer">
      {/* Primer bloque: Logo, ciudad, teléfono y correo */}
      <div className="footer-block">
         <Link to='/'> 
         <img src={imgsrc} alt="Logo" className="footer-logo" />
         
         </Link>
       
        <p>Cali, Colombia</p>
        <p>Teléfono: +57 321 847 2298</p>
        <p>Email: gerencia@alphabiomedica.com</p>
      </div>

      {/* Segundo bloque: Enlaces */}
      <div className="footer-block">
        <ul>
          <li> <Link to='/solicitarreunion'>Agenda una Reunión </Link></li>
          <li><Link to="/solicitarinformacion">Solicita Información</Link></li>
        
        </ul>
      </div>

      {/* Tercer bloque: Blog, cursos y eventos */}
      <div className="footer-block">
        <ul>
        <li><Link to="/precios">Listado de Precios</Link></li>
        <li><Link to="/quienessomos">Quienes Somos</Link></li>
        </ul>
      </div>
    </footer>
  );
}

export default FooterHome;
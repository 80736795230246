import React, { Component, Fragment } from 'react';
import MyButton from '../../../utils/MyButton';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './DocumentoSubir.css';

import { subirDocLegal, clearErrors,getDocsLegal } from '../../../redux/actions/dataactions';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDropzone } from 'react-dropzone';
import {  FaArrowLeft, FaPlus, FaUpload, FaCheck, FaCheckCircle } from "react-icons/fa";

// Componente funcional para usar el hook useDropzone
const FileDropzone = ({ onFileDrop }) => {
  const { getRootProps, getInputProps, acceptedFiles, fileRejections } = useDropzone({
    accept: {
      'application/pdf': ['.pdf']
    },
    maxFiles: 1,
    onDrop: onFileDrop
  });

  return (
    <div className="documento-subir-dropzone-container">
      <div {...getRootProps({ className: 'documento-subir-dropzone' })}>
        <input {...getInputProps()} />
        <p className="documento-subir-text">Arrastra y suelta el archivo aquí, o haz clic para seleccionarlo</p>
        <em className="documento-subir-info">(Solo se permite subir un archivo PDF)</em>
      </div>
      
      <aside className="documento-subir-preview">
        {fileRejections.length > 0 && (
          <div className="documento-subir-file-error">
            <h4>Solo se puede cargar un archivo PDF</h4>
          </div>
        )}

        {acceptedFiles.length > 0 && (
          <div className="documento-subir-file-accepted">
            <h4>Archivo a cargar:</h4>
            <ul className="documento-subir-file-list">
              {acceptedFiles.map(file => (
                <li key={file.path} className="documento-subir-file-item">
                  {file.path} - {file.size} bytes
                </li>
              ))}
            </ul>
          </div>
        )}
      </aside>
    </div>
  );
};

export class DocumentoSubir extends Component {
  constructor() {
    super();
    this.state = {
      selectedFile: null,
      open: false,
      errors: {},
      aprobado: {}
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {};
  
    if (props.UI && props.UI.errors) {
      newState.errors = props.UI.errors;
    }
  
    if (props.UI && props.UI.aprobado) {
      newState.aprobado = props.UI.aprobado;
    }
  
    return Object.keys(newState).length > 0 ? newState : null;
  }

  handleOpen = () => {
    this.setState({ open: true,
        selectedFile: null,
        aprobado: {},
        errors: {} });
  };
      
  handleClose = () => {
    if (this.props.clearErrors) {
      this.props.clearErrors();
    }
    
    if (this.state.aprobado && this.state.aprobado.mensaje) {
      console.log('Documento subido exitosamente:', this.state.aprobado);
      
      this.setState({
        open: false,
        selectedFile: null,
        aprobado: {},
        errors: {}
      });
      this.props.getDocsLegal(this.props.clienteId);

    } else {
      this.setState({
        open: false,
        selectedFile: null,
        errors: {}
      });
    }
  };

  handleFileDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      this.setState({ selectedFile: acceptedFiles[0] });
    }
  };

  onFileUpload = () => {
    if (!this.state.selectedFile) {
      console.error('No se ha seleccionado ningún archivo');
      return;
    }

    // Crear un objeto FormData
    const formData = new FormData();
    
    // Agregar el archivo al FormData
    formData.append(
      "documento", // Nombre del campo en el backend
      this.state.selectedFile,
      this.state.selectedFile.name
    );
    
    console.log('Subiendo documento:', {
      docId: this.props.docId,
      clienteId: this.props.clienteId,
      fileName: this.state.selectedFile.name
    });
    
    // Llamar a la acción para subir el documento
    this.props.subirDocLegal(formData, this.props.docId, this.props.clienteId);
  };

  render() {
    const { UI } = this.props;
    const loading = UI && UI.loading;
    const { errors } = this.state;

    return (
        <div>
        <Fragment>
          <div className="documento-subir-btn-container">
            <MyButton tip="Subir Archivo" onClick={this.handleOpen} >
              <FaUpload color="#03178C" className="documento-subir-btn-icon"/>
            </MyButton>
          </div>
                
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>Subir Documento</DialogTitle>
                        
            <DialogContent>
              {this.state.aprobado && this.state.aprobado.mensaje ? (
                <div className='documento-subir-aprobado'>
                  <div className='documento-subir-icono-container'>
                    <FaCheck color="#ffffff" className='documento-subir-icono' />
                  </div>
                  <h2 className='documento-subir-texto'>¡Documento Subido Exitosamente!</h2>
                </div>
              ) : (
                <>
                  {/* Componente de Dropzone */}
                  <FileDropzone onFileDrop={this.handleFileDrop} />
                  
                  {/* Mostrar errores si existen */}
                  <div className="documento-subir-error-container">
                    {errors && errors.error && (
                      <p className="documento-subir-error-message">{errors.error}</p>
                    )}
                  </div>
                </>
              )}
            </DialogContent>
                
            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="documento-subir-form-icon"/>
              </MyButton>
              
              {loading ? (
                <CircularProgress size={30} color="primary" className="documento-subir-progress" />
              ) : this.state.aprobado && this.state.aprobado.mensaje ? (
                <MyButton tip="Continuar" onClick={this.handleClose}>
                  <FaCheckCircle color="#20B90C" className="documento-subir-form-icon" />
                </MyButton>
              ) : (
                <MyButton 
                  tip="Subir Archivo" 
                  onClick={this.onFileUpload} 
                  disabled={!this.state.selectedFile}
                >
                  <FaPlus color="#03178C" className="documento-subir-form-icon"/>
                </MyButton>
              )}
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}

// En propiedades recibe el cliente id
DocumentoSubir.propTypes = {
  subirDocLegal: PropTypes.func.isRequired,
  getDocsLegal:PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  docId: PropTypes.string.isRequired,
  clienteId: PropTypes.string.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  subirDocLegal,
  clearErrors,getDocsLegal
  
};
  
export default connect(mapStateToProps, mapActionsToProps)(DocumentoSubir);
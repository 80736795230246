import {POST_OFERTA_RECHAZAR,POST_OFERTA_APROBAR,SET_OFERTA,SET_OFERTAS,POST_OFERTA,POST_ESTADO_PUBLICAR,POST_ITEM,LOADING_UI,LOADING_DATA_SALES,SET_COTIZACIONES,POST_COTIZACION,SET_COTIZACION,SET_ERRORS,CLEAR_ERRORS} from '../types';
import axios from 'axios'; 

import { apiV1, apiV2, setAuthorizationHeader } from '../../utils/api';

export const postCotizacion=(newcotizacion,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postcotizacion`,newcotizacion)
    .then((res)=>{
        dispatch({
            type:POST_COTIZACION,
            payload:res.data
        });
        dispatch(clearErrors());
        history.push('/dashboard/homemarket');

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const getCotizaciones =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getcotizaciones`)
    .then(res=>{
        dispatch({
            type:SET_COTIZACIONES,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getCotizacionesPublicadas =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getcotizacionespublicadas`)
    .then(res=>{
        dispatch({
            type:SET_COTIZACIONES,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}
export const getCotizacionesPublicadasEmpresa =(categoria)=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getcotizacionespublicadasempresa/${categoria}`)
    .then(res=>{
        dispatch({
            type:SET_COTIZACIONES,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
    
}

export const getCotizacion =(cotizacionId)=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getcotizacion/${cotizacionId}`)
    .then(res=>{
        dispatch({
            type:SET_COTIZACION,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}

export const postItem=(newitem,cotizacionId)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postitem/${cotizacionId}`,newitem)
    .then((res)=>{
        console.log('Entré a Post Item',newitem,cotizacionId);
        dispatch({
            type:POST_ITEM,
            payload:res.data
        });
        
        dispatch(clearErrors());
        dispatch(getCotizacion(cotizacionId));

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}
export const postEstadoPublicar=(cotizacionId,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postestadopublicar/${cotizacionId}`)
    .then((res)=>{
        console.log('Entré a Post Estado'+cotizacionId);
        dispatch({
            type:POST_ESTADO_PUBLICAR
        });
        
        dispatch(clearErrors());
        history.push('/dashboard/homemarket');

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const postOferta=(newOferta,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postoferta`,newOferta)
    .then((res)=>{
        console.log('Entró a post oferta');
        dispatch({
            type:POST_OFERTA,
            payload:res.data
           
        });
     
        dispatch(clearErrors());
        history.push('/dashboard/homemarketempresa');


       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}
export const getOfertas =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getofertas`)
    .then(res=>{
        dispatch({
            type:SET_OFERTAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:err.response.data
        });
    })
    
}

export const getOfertasAprobadas =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getofertasaprobadas`)
    .then(res=>{
        dispatch({
            type:SET_OFERTAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:err.response.data
        });
    })
    
}

export const getOfertasRechazadas =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getofertasrechazadas`)
    .then(res=>{
        dispatch({
            type:SET_OFERTAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:err.response.data
        });
    })
    
}

export const getOfertasAprobadasCliente =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getofertasaprobadascliente`)
    .then(res=>{
        dispatch({
            type:SET_OFERTAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:err.response.data
        });
    })
    
}

export const getOfertasRechazadasCliente =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getofertasrechazadascliente`)
    .then(res=>{
        dispatch({
            type:SET_OFERTAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:err.response.data
        });
    })
    
}

export const getOfertasCliente =()=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getofertascliente`)
    .then(res=>{
        dispatch({
            type:SET_OFERTAS,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:err.response.data
        });
    })
    
}

export const getOferta =(ofertaId)=>dispatch=>{
    dispatch({type:LOADING_DATA_SALES});
    apiV1.get(`/getofertacliente/${ofertaId}`)
    .then(res=>{
        dispatch({
            type:SET_OFERTA,
            payload:res.data
        });
        dispatch(clearErrors());
    }).catch(err=>{
        dispatch({
            type: SET_ERRORS,
            payload:[]
        });
    })
}

export const postAprobarOferta=(data,ofertaId,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postaprobaroferta/${ofertaId}`,data)
    .then((res)=>{
        console.log('Entré a Post Aprobar Oferta',data,ofertaId);
        dispatch({
            type:POST_OFERTA_APROBAR
        });
        
        dispatch(clearErrors());
        history.push('/dashboard/homemarket');

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}

export const postRechazarOferta=(ofertaId,history)=>(dispatch)=>{
    dispatch({type:LOADING_UI});
    apiV1
    .post(`/postrechazaroferta/${ofertaId}`)
    .then((res)=>{
        console.log('Entré a Post Rechazar Oferta',ofertaId);
        dispatch({
            type:POST_OFERTA_RECHAZAR
        });
        
        dispatch(clearErrors());
        history.push('/dashboard/homemarket');

       
        
    })
    .catch((err)=>{
        dispatch({
            type: SET_ERRORS,
            payload: err.response.data
          });
    });
}


export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };
import React, { Component } from 'react'
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import DocumentacionNuevo from './DocumentacionNuevo'
import DocumentacionLegalLista from './DocumentacionLegalLista'

class DocumentacionLegal extends Component {
  
  handleGoBack = () => {
    const { clienteId } = this.props.match.params;
    const { credentials } = this.props.user;
    
    // Redirigir según el tipo de usuario
    if (credentials.tipouser === "Tecnico") {
      this.props.history.push(`/dashboard/perfilclientetec/${clienteId}`);
    } else {
      // Asumimos que es "Ingeniero" u otro tipo
      this.props.history.push(`/dashboard/perfilcliente/${clienteId}`);
    }
  };

  render() {
    return (
      <div>
        <div className="titulo_section">
          <div className="titulo_clientes">
            <h1 className="text-clientes">Documentación Legal </h1>
            <hr className="clientes_separator"></hr>
          </div>
          <div className="div_boton">
          </div>

          <div className="div_boton">
            <DocumentacionNuevo clienteId={this.props.match.params.clienteId}/>
            <MyButton tip="Volver" onClick={this.handleGoBack}>
              <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
            </MyButton>
          </div>
        </div>

        <div className="listaclientes_container">
          <DocumentacionLegalLista clienteId={this.props.match.params.clienteId}/>
        </div>
      </div>
    )
  }
}

DocumentacionLegal.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(withRouter(DocumentacionLegal));
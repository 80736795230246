import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getEquipo, getCliente, getMtoCorr } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './InventarioPDF.css';
import ReporteCorrDocuPDF from './ReporteCorrDocuPDF';

const ReporteCorrectivoPDF = ({ getEquipo, getCliente, getMtoCorr, data, match }) => {
  const [verPDF, setVerPDF] = useState(false);
  const history = useHistory();

  const { equipo, cliente, mtopreventivo, loading } = data;

  useEffect(() => {
    // get MTO PREVENTIVO DEBE ENVIAR DESDE LA LISTA EL ID DEL REPORTE DE MTO TAMBIÉN
    getMtoCorr(match.params.mtoid);
    // GET EQUIPO
    getEquipo(match.params.equipoId);
    // get cliente
    getCliente(match.params.clienteId);
  }, [getMtoCorr, getEquipo, getCliente, match.params.mtoid, match.params.equipoId, match.params.clienteId]);

  const handleOpenPDF = () => {
    setVerPDF(!verPDF);
  };

  const goBack = () => {
    history.goBack(); // Esto llevará a la página anterior, independientemente de su origen.
  };

  const renderContent = () => {
    if (!cliente?.plantillaType) {
      return (
        <div className="mto-error-message">
          <p>Para ver las plantillas, primero debe configurar el tipo de plantilla en la lista de clientes</p>
        </div>
      );
    }

    if (equipo && cliente && verPDF) {
      return (
        <PDFViewer style={{ width: "100%", height: "90vh" }}>
          <ReporteCorrDocuPDF 
            equipo={equipo} 
            cliente={cliente} 
            mtopreventivo={mtopreventivo} 
          />
        </PDFViewer>
      );
    }

    return null;
  };

  return (
    <div className='div-inventaripdfgeneral'>
      <div className='div-inventaripdftitulo'>
        <div className="div_boton">
          <MyButton tip="Volver" onClick={goBack}>
            <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
          </MyButton>
        </div>
        {cliente?.plantillaType && (
          <Button 
            variant="text"
            color="primary"
            onClick={handleOpenPDF}
          >
            {verPDF ? "Ocultar PDF" : "Ver PDF"}
          </Button>
        )}
      </div>
      <div className='div-inventariopdfcontent'>
        {renderContent()}
      </div>
    </div>
  );
}

ReporteCorrectivoPDF.propTypes = {
  getEquipo: PropTypes.func.isRequired,
  getCliente: PropTypes.func.isRequired,
  getMtoCorr: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  data: state.data
})

export default connect(mapStateToProps, { getEquipo, getCliente, getMtoCorr })(ReporteCorrectivoPDF);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SolicitarInfo.css';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '../Elements/Button';
import imgsrc from '../Navbar/logohorizontal.svg';
import FooterHome from '../Home/HomeComponents/Footer/FooterHome';

import {solicitudInformacion} from '../../redux/actions/dataactions';

class SolicitarInfo extends Component {
    constructor() {
        super();
        this.state = {
            nombre: '',
            email: '',
            telefono: '',
            empresa: '',
            mensaje: '',
            loading: false,
            errors: {},
            aprobado:{},
        };
    }

    
    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
            newState.aprobado = props.UI.aprobado;
            // Limpiar el formulario cuando se recibe el mensaje de aprobado
            if (props.UI.aprobado.mensaje) {
                newState.nombre = '';
                newState.email = '';
                newState.telefono = '';
                newState.empresa = '';
                newState.mensaje = '';
                newState.errors = {};
            }
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        
        const infoData = {
            nombre: this.state.nombre,
            email: this.state.email,
            telefono: this.state.telefono,
            empresa: this.state.empresa,
            mensaje: this.state.mensaje
        };

        // Aquí puedes agregar la lógica para enviar los datos
        console.log(infoData);
     
            this.props.solicitudInformacion(infoData); 
        
        // Simular envío
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        const { UI:{loading}}=this.props; 
        const {  errors } = this.state;

        return (
            <>
            <div id="solicitar-info">
                <div className="info-container">
                    <div className='info-container1'>
                        <div className="info-card">
                            <div className="info-divtitulo">
                                <h1 className="info-titulo">Solicitar Información</h1>
                                <p className="info-subtitulo">Completa el formulario y nos pondremos en contacto</p>
                            </div>

                            <form className="info-form" noValidate onSubmit={this.handleSubmit}>
                                <TextField
                                    className="info-input"
                                    required
                                    name="nombre"
                                    id="nombre"
                                    label="Nombre Completo"
                                    value={this.state.nombre}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombre ? errors.nombre : "Ingrese su nombre completo"}
                                    error={errors.nombre ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="email"
                                    id="email"
                                    label="Email"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.email ? errors.email : "Ingrese su email de contacto"}
                                    error={errors.email ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="telefono"
                                    id="telefono"
                                    label="Teléfono"
                                    value={this.state.telefono}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.telefono ? errors.telefono : "Ingrese su teléfono de contacto"}
                                    error={errors.telefono ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    name="empresa"
                                    id="empresa"
                                    label="Empresa"
                                    value={this.state.empresa}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText="Nombre de su empresa (opcional)"
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    name="mensaje"
                                    id="mensaje"
                                    label="Mensaje"
                                    value={this.state.mensaje}
                                    onChange={this.handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText={errors.mensaje ? errors.mensaje : "Describa brevemente su consulta"}
                                    error={errors.mensaje ? true : false}
                                    variant="standard"
                                />

                                <p className="info-error">{errors.general}</p>

                                {this.state.aprobado.mensaje && (
                                    <p className="info-success">Tu solicitud ha sido enviada! Nos comunicaremos pronto contigo.</p>
                                )}


                                {loading ? (
                                    <CircularProgress size={30} color="primary" className="info-progress" />
                                ) : (
                                    <Button type="submit" buttonSize="btn--wide" buttonColor="blue">
                                        Enviar Solicitud
                                    </Button>
                                )}
                            </form>
                        </div>
                    </div>
                    
                    <div className='info-container2'>
                        <div className="info-card">
                            <div className="info-divtitulo">
                                <h2 className="info-titulo">¿Por qué elegir Alpha Biomédica?</h2>
                            </div>
                            <div className="info-mensaje">
                                <p>
                                    Con Alpha Biomédica el mantenimiento de tu hospital estará en un solo lugar, brindamos herramientas con IA,
                                    diseñadas para:
                                </p>
                                <ul>
                                    <li>Optimizar sus procesos de mantenimiento con indicadores en tiempo real</li>
                                    <li>Generar reportes automaticos para el cumplimiento de normativas actuales</li>
                                    <li>Reducir costos operativos con el control de gastos y compras</li>
                                    <li>Aumentar la productividad de sus servicios</li>
                                </ul>
                                <p>
                                    Contáctenos hoy mismo y descubra cómo podemos ayudar a su empresa a alcanzar
                                    sus objetivos con nuestras soluciones tecnológicas.
                                </p>
                                <img src={imgsrc} alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            <FooterHome></FooterHome>
            </>
        );
    }
}
SolicitarInfo.propTypes={
  solicitudInformacion:PropTypes.func.isRequired,
 
  UI:PropTypes.object.isRequired,
  
};
const mapStateToProps=(state)=>({
  UI:state.UI
});

export default connect (mapStateToProps,{solicitudInformacion}) (SolicitarInfo)

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SolicitudCorrectivo.css';

import TextField from '@material-ui/core/TextField';
import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaPlus, FaCogs, FaCheck } from "react-icons/fa";

import { 
    postSolicitudMtoCorrectivo, 
    getSolicitudesCorrectivos,
    clearErrors
} from '../../../redux/actions/dataactions';

import {
    getUsuariosAsistenciales
} from '../../../redux/actions/useractions';

export class SolicitudCorrectivo extends Component {
    constructor(){
        super();
        this.state = {
            fallareportada: '',
            usuarioAsistencialId: '',
            errors: {},
            open: false,
            aprobado: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
            newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

    componentDidMount() {
        if(this.state.open) {
            this.props.getUsuariosAsistenciales(this.props.clienteId);
        }
    }

    handleOpen = () => {
        this.setState({open: true});
        this.props.getUsuariosAsistenciales(this.props.clienteId);
    };

    handleClose = () => {
        if (this.state.aprobado.mensaje) {
            this.props.getSolicitudesCorrectivos(this.props.equipoId);            
            this.setState({
                open: false,
                errors: {},
                fallareportada: '',
                usuarioAsistencialId: '',
                aprobado: {}
            });
            this.props.clearErrors();
        } else {
            this.props.clearErrors();
            this.setState({
                open: false,
                errors: {},
                fallareportada: '',
                usuarioAsistencialId: '',
                aprobado: {}
            });
        }
    };
    
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleSubmit = (event) => {
        event.preventDefault();
        const fechasolicitud = Date.now();
        
        if (!this.state.fallareportada || !this.state.usuarioAsistencialId) {
            this.setState({
                errors: {
                    fallareportada: !this.state.fallareportada ? 'Este campo es requerido' : '',
                    usuarioAsistencialId: !this.state.usuarioAsistencialId ? 'Debe seleccionar un usuario asistencial' : ''
                }
            });
            return;
        }

        // Encontrar el usuario asistencial seleccionado
        const usuarioAsistencial = this.props.usuariosAsistenciales.find(
            usuario => usuario.idUsuario === this.state.usuarioAsistencialId
        );

        const newSolicitudMto = {
            fallareportada: this.state.fallareportada,
            idprestador: this.props.clienteId,
            idusersolicita: usuarioAsistencial.userId,
            handlesolicita: usuarioAsistencial.handle,
            nombresolicita: usuarioAsistencial.nombre,
            nombreequipo: this.props.equipo.nombre,
            marca: this.props.equipo.marca,
            serie: this.props.equipo.serie,
            modelo: this.props.equipo.modelo,
            handleuser: this.props.equipo.ingeniero,
            idinventario: this.props.equipo.idinventario,
            ubicacion: this.props.equipo.ubicacion,
            servicio: this.props.equipo.servicio,
            equipoid: this.props.equipoId,
            fechasolicitudmilis: fechasolicitud,
            telnotificacion: this.props.user.credentials.telefono,
            emailnotificacion: this.props.user.credentials.email,
            usuarioAsistencialId: this.state.usuarioAsistencialId
        };
    
        this.props.postSolicitudMtoCorrectivo(newSolicitudMto, this.props.equipoId);
    }

    render() {
        const { UI: { loading }, usuariosAsistenciales } = this.props;
        const { errors } = this.state;

        return (
            <div>
                <Fragment>
                    <MyButton tip="Solicitar Mantenimiento Correctivo" onClick={this.handleOpen}>
                        <FaCogs color="#03178C" className="icon-crearcliente"/>
                    </MyButton>

                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        fullWidth
                        maxWidth="sm"
                    >
                        {this.state.aprobado.mensaje ? 
                            (<></>) : 
                            (<DialogTitle>Solicitar Mantenimiento Correctivo</DialogTitle>)
                        }

                        <DialogContent>
                            {this.state.aprobado.mensaje ? (
                                <div className='div-aprobado'>
                                    <div className='div-icono-aprobado'>
                                        <FaCheck color="#ffffff" className='icono-aprobado' />
                                    </div>
                                    <h2 className='text-aprobado'>{this.state.aprobado.mensaje}</h2>
                                </div>
                            ) : (
                                <form>
                                    <TextField
                                        className="login-input-email"
                                        required
                                        type="text"
                                        name="fallareportada"
                                        id="fallareportada"
                                        label="Describa la Falla"
                                        value={this.state.fallareportada}
                                        onChange={this.handleChange}
                                        fullWidth
                                        helperText={errors.fallareportada}
                                        error={errors.fallareportada ? true : false}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    <FormControl fullWidth margin="normal">
                                        <InputLabel id="usuario-asistencial-label">
                                            Usuario Asistencial
                                        </InputLabel>
                                        <Select
                                            labelId="usuario-asistencial-label"
                                            id="usuarioAsistencialId"
                                            name="usuarioAsistencialId"
                                            value={this.state.usuarioAsistencialId}
                                            onChange={this.handleChange}
                                            error={errors.usuarioAsistencialId ? true : false}
                                        >
                                            {usuariosAsistenciales && usuariosAsistenciales.map((usuario) => (
                                                <MenuItem key={usuario.idUsuario} value={usuario.idUsuario}>
                                                    {usuario.nombre}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </form>
                            )}
                        </DialogContent>

                        <DialogActions>
                            <MyButton tip="Cerrar" onClick={this.handleClose}>
                                <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                            </MyButton>

                            {loading ? (
                                <CircularProgress size={30} color="primary" className="login-progress" />
                            ) : this.state.aprobado.mensaje ? (
                                <></>
                            ) : (
                                <MyButton tip="Solicitar Mantenimiento Correctivo" onClick={this.handleSubmit}>
                                    <FaPlus color="#03178C" className="icon-formulario"/>
                                </MyButton>
                            )}
                        </DialogActions>
                    </Dialog>
                </Fragment>
            </div>
        )
    }
}

SolicitudCorrectivo.propTypes = {
    postSolicitudMtoCorrectivo: PropTypes.func.isRequired,
    getSolicitudesCorrectivos:PropTypes.func.isRequired,
    getUsuariosAsistenciales: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    equipo: PropTypes.object.isRequired,
    usuariosAsistenciales: PropTypes.array.isRequired,
    clienteId: PropTypes.string.isRequired,
    equipoId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    UI: state.UI,
    user: state.user,
    equipo: state.data.equipo.credentials,
    data: state.data,
    usuariosAsistenciales: state.user.usuariosAsistenciales || []
});

export default connect(
    mapStateToProps,
    { postSolicitudMtoCorrectivo, clearErrors, getUsuariosAsistenciales,getSolicitudesCorrectivos }
)(SolicitudCorrectivo);
import React, { useEffect, useRef, useState } from 'react';
import './PlanesNew.css';
import imgsrc from './planimg.png';

function PlanesNew() {
    const [isVisible, setIsVisible] = useState(false);
    const textRef = useRef(null);
    const imgRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            },
            {
                threshold: 0.1,
                rootMargin: '0px 0px -100px 0px',
            }
        );

        if (textRef.current) observer.observe(textRef.current);
        if (imgRef.current) observer.observe(imgRef.current);

        return () => {
            if (textRef.current) observer.unobserve(textRef.current);
            if (imgRef.current) observer.unobserve(imgRef.current);
        };
    }, []);

    return (
        <div id="section-plans" className="plans-container">
            <div ref={textRef} className={`content-container ${isVisible ? 'visible' : ''}`}>
                <h1 className='header-primary'>Conoce Nuestros Planes</h1>
                <h2 className='header-secondary'>Nuestra Prioridad</h2>
                <h2 className='header-secondary'>es Retornar tu</h2>
                <h2 className='header-secondary'>Inversión en Alpha Biomédica</h2>
                <a href="/precios" className='header-tertiary'>Ver Planes</a>
          
            </div>
            <div className="image-container">
                <img ref={imgRef} src={imgsrc} alt="logo" className={`image-plan ${isVisible ? 'visible' : ''}`} />
            </div>
        </div>
    );
}

export default PlanesNew;
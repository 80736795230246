import React, { Component } from 'react';

import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getRepuestos} from '../../../redux/actions/dataactions';
import {subirImagenRepuesto} from '../../../redux/actions/dataactions';
import {deleteRepuesto} from '../../../redux/actions/dataactions';
import {editRepuesto} from '../../../redux/actions/dataactions';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import './TablaEquipos.css'
import {Link} from 'react-router-dom';

import MyButton from '../../../utils/MyButton';

import NuevoEquipo from './NuevoEquipo';
import SubirEquipos from '../ClientePerfil/SubirEquipos';
import { Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import NuevoRepuesto from './NuevoRepuesto';
import SubirRepuestos from '../ClientePerfil/SubirRepuestos';

import { FaArrowLeft,FaPlus,FaFilePdf, FaTrash, FaCheck, FaCheckCircle, FaRegEdit } from "react-icons/fa";

import TextField from '@material-ui/core/TextField';




export class TablaRepuestos extends Component {
    
    state = { 
        // Initially, no file is selected 
        selectedFile: null,
        open: false,        
        opendelete:false,
        aprobado:{},
        errors:{},
        openedit:false,
        repuestoid:'',
        repuestoedit:{},
        codigo:'',
        nombre:'',
        marca:'',
        modelo:'',
        serie:'',
        cantidad:'',
        valorunitario:'',
        proveedor:'',
        proveedortel:'',
        proveedoremail:'',

      }; 


      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }
      handleOpen=(repuestoid)=>{
        this.setState({open:true,repuestoid:repuestoid});
        
    };

    handleClose=()=>{
        this.setState({open:false,repuestoid:''});
     };

     onFileChange = event => { 
     
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] }); 
       
    }; 

    onFileUpload = () => { 
     
        // Create an object of formData 
        const formData = new FormData(); 
       
        // Update the formData object 
        formData.append( 
          "myFile", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 
   console.log("repuesto id "+this.state.repuestoid);
  
        this.props.subirImagenRepuesto(formData,this.state.repuestoid,this.props.user.credentials.handle);
        //Imagen subida
        console.log("imagen subida");
        //
        this.setState({open:false});

    }; 

    componentDidMount(){
        console.log(this.props.user.credentials.handle)
        this.props.getRepuestos(this.props.user.credentials.handle);
      }

      handleOpenDelete=(rawdatarepid)=>{
        console.log('repuestoid'+rawdatarepid)
        this.setState({opendelete:true,repuestoid:rawdatarepid,aprobado:{}});
       
        };

        handleCloseDelete=()=>{
            // this.props.clearErrors();
            if(this.state.aprobado.mensaje){
                //getEquipos this.handleEditProgMto();
                this.props.getRepuestos(this.props.user.credentials.handle);
                this.setState({opendelete:false,errors:{},aprobado:{}});
            }else{
             this.setState({opendelete:false,errors:{},aprobado:{}});
            }
             
             };

             handleDelete=()=>{
                console.log('Borrando repuesto'+this.state.repuestoid);
                this.props.deleteRepuesto(this.state.repuestoid);

                //this.props.deleteProgMto(this.state.clienteid,this.state.progid,this.props.equipoId,credentials.handleuser);
                         
              }



    
              handleOpenEdit=(rawdata)=>{
                console.log('rowdata'+rawdata)
                this.setState({openedit:true,
                    codigo:rawdata.codigo,
                    nombre:rawdata.nombre,
                    marca:rawdata.marca,
                    modelo:rawdata.modelo,
                    serie:rawdata.serie,
                    cantidad:rawdata.cantidad,
                    valorunitario:rawdata.valorunitario,
                    proveedor:rawdata.proveedor,
                    proveedortel:rawdata.proveedortel,
                    proveedoremail:rawdata.proveedoremail,
                    repuestoid:rawdata.repuestoId
                    ,aprobado:{}});
               
                };

                handleCloseEdit=()=>{

                    if(this.state.aprobado.mensaje){
                        //getEquipos this.handleEditProgMto();
                        this.props.getRepuestos(this.props.user.credentials.handle);
                        this.setState({openedit:false,
                            codigo:'',
                            nombre:'',
                            marca:'',
                            modelo:'',
                            serie:'',
                            cantidad:'',
                            valorunitario:'',
                            proveedor:'',
                            proveedortel:'',
                            proveedoremail:'',
                            aprobado:{}});
                    }else{
                        this.setState({openedit:false,
                            codigo:'',
                            nombre:'',
                            marca:'',
                            modelo:'',
                            serie:'',
                            cantidad:'',
                            valorunitario:'',
                            proveedor:'',
                            proveedortel:'',
                            proveedoremail:'',
                            aprobado:{}});
                    }
                   
                   
                                
                    };

                handleChange=(event)=>{
                    this.setState({[event.target.name]:event.target.value});
                    };


                    handleEdit = () => {
                        let Data = {
                            codigo:this.state.codigo,
                            nombre:this.state.nombre,
                            marca:this.state.marca,
                            modelo:this.state.modelo,
                            serie:this.state.serie,
                            cantidad:this.state.cantidad,
                            valorunitario:this.state.valorunitario,
                            proveedor:this.state.proveedor,
                            proveedortel:this.state.proveedortel,
                            proveedoremail:this.state.proveedoremail,
                        }
                        console.log(Data)
                      this.props.editRepuesto(Data,this.state.repuestoid,this.props.user.credentials.handle);
                      }

       

  render() {
    const {repuestos,loadingrep}= this.props.data;
    const { UI:{loading}}=this.props; 
    const {errors}=this.state;
    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
        MiIcon:forwardRef((props, ref) => <AddToPhotosIcon {...props} ref={ref}/>),
        MiIcon2:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        MiIcon3:forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
        MiIcon4:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
        editRegister: forwardRef((props, ref) => <FaRegEdit {...props} ref={ref} />)
      };

    const columns=[
        {
            field: 'imagenurl',
            title: 'Avatar',
            render: rowData => <img src={rowData.imagenurl} style={{width: 50, borderRadius: '50%'}}/>
          },
    
        {title:'Codigo',field:'codigo'},
        {title:'Nombre',field:'nombre'},
        {title:'Marca',field:'marca'},
        {title:'Modelo',field:'modelo'},
        {title:'Serie',field:'serie'},
        {title:'Cantidad',field:'cantidad'},
        {title:'Valor',field:'valorunitario'},
        {title:'Proveedor',field:'proveedor'},
        {title:'Telefono',field:'proveedortel'},
        {title:'email',field:'proveedoremail'}
        
    ]

    let mapeorepuestos=!loadingrep ?(
        <div>

<div className='div-container-links-inventario'>
                        <NuevoRepuesto handleUser={this.props.user.credentials.handle}></NuevoRepuesto>
                        <SubirRepuestos handleUser={this.props.user.credentials.handle}></SubirRepuestos>
                       
                       
                        <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/repuestospdf/${this.props.user.credentials.handle}`} state={repuestos}>
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver Lista en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
                        
                    </div>
            
             

        <MaterialTable
        columns={columns}
        data={repuestos}
        title={
            <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}></h2>
          }
        icons={tableIcons}
        options={{
            cellStyle: {
                fontSize: 11,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 12,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}

        actions={[
            {
                title: "Editar Repuesto",
                icon: tableIcons.editRegister,
                tooltip: 'Editar Repuesto',
                onClick: (event, rowData) => this.handleOpenEdit(rowData)
              },
                
            {
              icon: tableIcons.Delete,
              tooltip: 'Borrar Repuesto',
              onClick: (event, rowData) => {
                  
                this.handleOpenDelete(rowData.repuestoId);
              }
            },
            
            {
                icon: tableIcons.MiIcon3,
                tooltip: 'Subir Imagen',
                onClick: (event, rowData) => this.handleOpen(rowData.repuestoId) 
              },

             
             
           
          ]}
         
        >
            {//window.location.href=`equipo/${this.props.clienteId}/${rowData.equipoid}`/dashboard/perfilcliente/equipo/:clienteId/:equipoId
            }
        </MaterialTable>

{/*//aqui va el dialogo */}
<Dialog
                open={this.state.open}
                onClose={this.handleClose}
                 fullWidth
                maxWidth="sm"
                >
                 <DialogTitle>Subir Imagen del Equipo</DialogTitle>
                 
                <DialogContent>
                
                </DialogContent>
                <input type="file" onChange={this.onFileChange} accept="image/png,image/jpeg"   /> 
                <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    <MyButton tip="Subir Imagen del Equipo" onClick={this.onFileUpload}>
                    <FaPlus  color="#03178C" className="icon-formulario"/>
                    </MyButton>
                    
                </DialogActions>

                </Dialog>

        
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )

    return (
        <div>
        {mapeorepuestos}

        <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >

            {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-delete'>
                    <FaTrash color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Borrado Exitosamente</h2>
              </div>
                
                ):(<div>
                <DialogTitle>¿Estás seguro de borrar este repuesto?</DialogTitle>
                </div>)}
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseDelete}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleCloseDelete}>
                    <FaCheckCircle color="#f9044e" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Borrar Programación" onClick={this.handleDelete}>
                    <FaTrash color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
           </DialogActions>
            </Dialog>


            <Dialog
             open={this.state.openedit}
             onClose={this.handleCloseEdit}
             fullWidth
             maxWidth="sm"
            >
 {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Repuesto Editado Exitosamente</h2>
                  
                 
              </div>
                
                ) : (<div>
                    
               
            
               
            <DialogContent>

                    <form>
                
          <div className="flex-accordion">
          <h4 className="text-title-accorion">Ingresa los datos del Repuesto</h4>
          <TextField className="login-input-email"
                      required
                      type="text"
                      name="nombre"
                      id="nombre"
                      label="Nombre del Repuesto"
                      value={this.state.nombre}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.nombre}
                      error={errors.nombre?true:false}
                      />

<div className="input_divido">
<div className="input_divido_children">

<TextField className="login-input-email"
                      required
                      type="text"
                      name="codigo"
                      id="codigo"
                      label="Codigo del Repuesto"
                      value={this.state.codigo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.codigo}
                      error={errors.codigo?true:false}
                      />

</div>

<div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="marca"
                      id="marca"
                      label="Marca del Repuesto"
                      value={this.state.marca}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.marca}
                      error={errors.marca?true:false}
                      />
                      <br/>
                        </div>
                        
    
    </div>
                      
                      <div className="input_divido">
                       
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="modelo"
                      id="modelo"
                      label="Modelo del Repuesto"
                      value={this.state.modelo}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.modelo}
                      error={errors.modelo?true:false}
                      />
                      <br/>
                        </div>
                    
                        <div className="input_divido_children">
                       <TextField className="login-input-email"
                     required
                     type="text"
                     name="serie"
                     id="serie"
                     label="Serie del Repuesto"
                     value={this.state.serie}
                     onChange={this.handleChange}
                     fullWidth
                     helperText={errors.serie}
                     error={errors.serie?true:false}
                     />
                     <br/>
                       </div>
                    </div>

                   

                   <div className="input_divido">
                       
                       <div className="input_divido_children">
                       <TextField className="login-input-email"
                     required
                     type="number"
                     name="cantidad"
                     id="cantidad"
                     label="Cantidad"
                     value={this.state.cantidad}
                     onChange={this.handleChange}
                     fullWidth
                     helperText={errors.cantidad}
                     error={errors.cantidad?true:false}
                     />
                     <br/>
                       </div>

                       <div className="input_divido_children">
                       <TextField className="login-input-email"
                     required
                     type="number"
                     name="valorunitario"
                     id="valorunitario"
                     label="Valor Unitario con IVA"
                     value={this.state.valorunitario}
                     onChange={this.handleChange}
                     fullWidth
                     helperText={errors.valorunitario}
                     error={errors.valorunitario?true:false}
                     />
                     <br/>
                       </div>
                   
                     
                   </div>

                   <div className="input_divido">
                      
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="text"
                      name="proveedor"
                      id="proveedor"
                      label="Proveedor del Repuesto"
                      value={this.state.proveedor}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedor}
                      error={errors.proveedor?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>

                    <div className="input_divido">
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="number"
                      name="proveedortel"
                      id="proveedortel"
                      label="Telefono Proveedor"
                      value={this.state.proveedortel}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedortel}
                      error={errors.proveedortel?true:false}
                      />
                      <br/>
                        </div>
                        <div className="input_divido_children">
                        <TextField className="login-input-email"
                      required
                      type="email"
                      name="proveedoremail"
                      id="proveedoremail"
                      label="Email Proveedor"
                      value={this.state.proveedoremail}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.proveedoremail}
                      error={errors.proveedoremail?true:false}
                      />
                      <br/>
                        </div>
                    
                      
                    </div>
                  
                    
                      

                        

                        
                        




          </div>
        
                    

                           




                      </form>
                      
            
            </DialogContent>
          

           
                </div>
               
                )}

<DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleCloseEdit}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                              
                  <MyButton tip="Ir a Inventario" onClick={this.handleCloseEdit}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                  
                ) : (
                  <MyButton tip="Crear Equipo" onClick={this.handleEdit}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
            
            
          </DialogActions>
                 </Dialog>
    </div>
    )
  }
}

TablaRepuestos.propTypes={
    getRepuestos:PropTypes.func.isRequired,
    deleteRepuesto:PropTypes.func.isRequired,
    editRepuesto:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    user:PropTypes.object.isRequired,
    subirImagenRepuesto: PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,  
}
const mapStateToProps=state=>({
    data:state.data,
    user:state.user,
    UI:state.UI
})
export default connect(mapStateToProps,{getRepuestos,subirImagenRepuesto,deleteRepuesto,editRepuesto}) (TablaRepuestos);


import React from 'react';
import './QuienesSomos.css';
import person0 from './person0.jpeg'
import person1 from './person1.jpeg'
import person2 from './person2.jpeg'
import person3 from './person3.jpeg'
import person4 from './person4.jpeg'
import person5 from './person5.jpeg'
import person6 from './person6.jpeg'
import person7 from './person7.png'
import person8 from './person8.png'
import FooterHome from '../Home/HomeComponents/Footer/FooterHome';
import { FaLinkedin } from 'react-icons/fa';

const QuienesSomos = () => {
  const equipo = [
    {
        nombre: "Jose Arias",
        cargo: "CEO",
        descripcion: "CEO, Fundador y Desarrollador de Alpha Biomedica",
        imagen: person0,
        linkedin: "https://www.linkedin.com/in/josearias17"
      },
      {
        nombre: "Andrea Peñas Arteaga",
        cargo: "Directora Comercial",
        descripcion: "Especialista en equipos biomédicos con más de 6 años de experiencia.",
        imagen: person7,
        linkedin: "https://www.linkedin.com/in/andrea-pe%C3%B1as-arteaga-920135267"
      },
      {
        nombre: "Luis Alejandro Londoño ",
        cargo: "Desarrollador IA",
        descripcion: "Diseñador de Funciones de IA aplicadas a procesos de Mantenimiento",
        imagen: person8,
        linkedin: "https://www.linkedin.com/in/luale-londocampo"
      },
     
      {
        nombre: "Andres Mauricio Gonzalez",
        cargo: "Asesor de Desarrollo",
        descripcion: "Especialista en Data Science, IA e Ingeniería Biomédica",
        imagen: person6,
        linkedin: "https://www.linkedin.com/in/andresgonzalezvargas/"
      } ,
    {
      nombre: "Oscar Esteban Jerez",
      cargo: "Estudiante Investigador - Alpha Biomedica",
      descripcion: "Desarrollador de Modulos y Nuevas Funciones",
      imagen: person1,
      linkedin: "https://www.linkedin.com/in/oscar-esteban-jerez-almeida-192621269/"
    },
    {
      nombre: "Juan Manuel Campos",
      cargo: "Estudiante Investigador - Alpha Biomedica",
      descripcion: "Desarrollador de Modulos y Nuevas Funciones",
      imagen: person2,
      linkedin: "https://www.linkedin.com/in/juan-manuel-campos-franco-9ab51832b"
    },
    {
      nombre: "Cielo Sofía García ",
      cargo: "Estudiante Investigador - Alpha Biomedica",
      descripcion: "Desarrollador de Modulos y Nuevas Funciones",
      imagen: person3,
      linkedin: "https://www.linkedin.com/in/cielo-sofia-garc%C3%ADa-mera-813072304/"
    },
    {
        nombre: "Juan Mario Canchila Madera",
        cargo: "Estudiante Investigador - Alpha Biomedica",
        descripcion: "Desarrollador de Modulos y Nuevas Funciones",
        imagen: person4,
        linkedin: "https://www.linkedin.com/in/juan-mario-canchila-madera-683697321/"
      },
      {
        nombre: "Andres felipe Verhelst",
        cargo: "Estudiante Investigador - Alpha Biomedica",
          descripcion: "Desarrollador de Modulos y Nuevas Funciones",
        imagen: person5,
        linkedin: "https://www.linkedin.com/in/andr%C3%A9s-felipe-verhelst-fern%C3%A1ndez-b97b76331"
      }
     
  ];

  return (
    <>
    <div id="quienessomos" className="quienes-somos">
      <div className="container">
        <div className="header">
          <h2 className='header-titulo-q'>Quiénes Somos</h2>
          <p className="descripcion">
            En Alpha Biomédica, nos dedicamos a proporcionar soluciones innovadoras en el campo de la tecnología médica,
            garantizando la más alta calidad y servicio a nuestros clientes.
          </p>
        </div>
        
        <div className="equipo-grid">
          {equipo.map((miembro, index) => (
            <div key={index} className="miembro-card">
              <div className="imagen-container">
                <img
                  className="imagen-perfil"
                  src={miembro.imagen}
                  alt={miembro.nombre}
                />
              </div>
              <h3 className="nombre">{miembro.nombre}</h3>
              <p className="cargo">{miembro.cargo}</p>
              <p className="bio">{miembro.descripcion}</p>
              <a
                href={miembro.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-link"
              >
                 <FaLinkedin className="linkedin-icon" />
                Ver perfil
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
    <FooterHome/>
    </>
  );
};

export default QuienesSomos;
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MyButton from '../../../utils/MyButton'
import { FaArrowCircleLeft } from 'react-icons/fa'
import { CircularProgress, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';

import './ProgramacionMensual.css'
import Button from '../../Elements/Button';

class ProgramacionMensual extends Component {

    constructor(){
        super();
        this.state={
            categoria:'mantenimiento',
            mes:'enero',
            year:'2023',
            errors:{},
            open:false
         
        };
        
    };

    handleChange=(event)=>{
        this.setState({ [event.target.name]:event.target.value});
    };

    handleGoBack = () => {
        const { clienteId } = this.props.match.params;
        const { credentials } = this.props.user;
        
        // Redirigir según el tipo de usuario
        if (credentials.tipouser === "Tecnico") {
            this.props.history.push(`/dashboard/perfilclientetec/${clienteId}`);
        } else {
            // Asumimos que es "Ingeniero" u otro tipo
            this.props.history.push(`/dashboard/perfilcliente/${clienteId}`);
        }
    };

    render() {
        const {errors}=this.state;
        const { credentials } = this.props.user;
        // Determinar la ruta base según el tipo de usuario
        const baseRoutePath = credentials.tipouser === "Tecnico" 
            ? `/dashboard/perfilclientetec/${this.props.match.params.clienteId}`
            : `/dashboard/perfilcliente/${this.props.match.params.clienteId}`;

        return (
            <div>
                <div className="titulo_section">
                    <div className="titulo_clientes">
                        <h1 className="text-clientes">Programación Mensual</h1>
                        <hr className="clientes_separator"></hr>            
                    </div>

                    <div className="div_boton">
                        <MyButton tip="Volver" onClick={this.handleGoBack}>
                            <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente"/>
                        </MyButton>
                    </div>
                </div>

                <div className='contenedor-principal'>
                    <div className='contenedor-secundario'>
                     
                        <FormControl className="formcontrol-nuevomto">
                            <InputLabel>Categoria a Consultar</InputLabel>
                            <Select name="categoria" id="categoria" value={this.state.categoria || ''} onChange={this.handleChange}
                                fullWidth>
                                <MenuItem value={'mantenimiento'}>Mantenimiento</MenuItem>
                                <MenuItem value={'calibracion'}>Calibración</MenuItem>
                            </Select>
                            <FormHelperText>{errors.categoria}</FormHelperText>
                        </FormControl> 

                        <FormControl className="formcontrol-nuevomto">
                            <InputLabel>Selecciona Mes a Consultar</InputLabel>
                            <Select name="mes" id="mes" value={this.state.mes || ''} onChange={this.handleChange}
                                fullWidth>
                                <MenuItem value={'enero'}>Enero</MenuItem>
                                <MenuItem value={'febrero'}>Febrero</MenuItem>
                                <MenuItem value={'marzo'}>Marzo</MenuItem>
                                <MenuItem value={'abril'}>Abril</MenuItem>
                                <MenuItem value={'mayo'}>Mayo</MenuItem>
                                <MenuItem value={'junio'}>Junio</MenuItem>
                                <MenuItem value={'julio'}>Julio</MenuItem>
                                <MenuItem value={'agosto'}>Agosto</MenuItem>
                                <MenuItem value={'septiembre'}>Septiembre</MenuItem>
                                <MenuItem value={'octubre'}>Octubre</MenuItem>
                                <MenuItem value={'noviembre'}>Noviembre</MenuItem>
                                <MenuItem value={'diciembre'}>Diciembre</MenuItem>
                            </Select>
                            <FormHelperText>{errors.mes}</FormHelperText>
                        </FormControl> 

                        <FormControl className="formcontrol-nuevomto">
                            <InputLabel>Selecciona Año a Consultar</InputLabel>
                            <Select name="year" id="year" value={this.state.year || ''} onChange={this.handleChange}
                                fullWidth>
                                <MenuItem value={'2022'}>2022</MenuItem>
                                <MenuItem value={'2023'}>2023</MenuItem>
                                <MenuItem value={'2024'}>2024</MenuItem>
                                <MenuItem value={'2025'}>2025</MenuItem>
                                <MenuItem value={'2026'}>2026</MenuItem>
                                <MenuItem value={'2027'}>2027</MenuItem>
                            </Select>
                            <FormHelperText>{errors.year}</FormHelperText>
                        </FormControl> 

                        <div className='div-boton-consultar'>
                            <Link to={`${baseRoutePath}/programacion/${this.state.categoria}/${this.state.mes}/${this.state.year}`}>
                                <Button type="submit" buttonSize="btn--wide" buttonColor='blue'>
                                    Consultar
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

ProgramacionMensual.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(withRouter(ProgramacionMensual));
import React, { Component } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getEquipos} from '../../../../redux/actions/dataactions';
import {subirImagenEquipoInventario} from '../../../../redux/actions/dataactions';
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import './InventarioTablaTec.css'
import {Link} from 'react-router-dom';

import MyButton from '../../../../utils/MyButton';
import {FaArrowCircleLeft, FaArrowLeft, FaChartBar, FaFilePdf, FaPlus} from "react-icons/fa";

import { Fragment } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

export class InventarioTablaTec extends Component {

    state = { 
        // Initially, no file is selected 
        selectedFile: null,
        open: false,
        equipoid:''
      }; 

      handleOpen=(equipoid)=>{
        this.setState({open:true,equipoid:equipoid});
        
    };

    handleClose=()=>{
        this.setState({open:false,equipoid:''});
     };

     onFileChange = event => { 
     
        // Update the state 
        this.setState({ selectedFile: event.target.files[0] }); 
       
    }; 

    onFileUpload = () => { 
     
        // Create an object of formData 
        const formData = new FormData(); 
       
        // Update the formData object 
        formData.append( 
          "myFile", 
          this.state.selectedFile, 
          this.state.selectedFile.name 
        ); 
   console.log("equipo id "+this.state.equipoid);
   console.log('clienteid'+this.props.clienteId);
        this.props.subirImagenEquipoInventario(formData,this.state.equipoid,this.props.clienteId);
        //Imagen subida
        console.log("imagen subida");
        //
        this.setState({open:false});

    }; 

 

    componentDidMount(){
        this.props.getEquipos(this.props.clienteId);
      }

    
    
    render() {
        const {equipos,loadingdata}= this.props.data;

        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
            MiIcon:forwardRef((props, ref) => <AddToPhotosIcon {...props} ref={ref}/>),
            MiIcon2:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
            MiIcon3:forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
            MiIcon4:forwardRef((props, ref) => <AssignmentIcon  {...props} ref={ref}/>),
          };

        const columns=[
            {
                field: 'imagenurl',
                title: 'Avatar',
                render: rowData => <img src={rowData.imagenurl} style={{width: 50, borderRadius: '50%'}}/>,
                width: 'auto'
              },
        
            {title:'Id',field:'idinventario',width: 'auto'},
            {title:'Nombre',field:'nombre',width: 'auto'},
            {title:'Marca',field:'marca',width: 'auto'},
            {title:'Modelo',field:'modelo',width: 'auto'},
            {title:'Serie',field:'serie',width: 'auto'},
            {title:'Ubicación',field:'ubicacion',width: 'auto'},
            {title:'Servicio',field:'servicio',width: 'auto'},
            {title:'Estado', field:'estado',width: 'auto'},
            {title:'Invima',field:'registroinvima',width: 'auto'},
            {title:'Riesgo',field:'riesgo',width: 'auto'}
        ]
        
        let mapeoequipos=!loadingdata ?(
        <div>
            <div className='div-container-links-inventario'>
                        
                        <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/inventario/${this.props.clienteId}`} state={equipos}>
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Exportar Inventario
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
                        
                    </div>
             

        <MaterialTable
        columns={columns}
        data={equipos}
        title={
            <h2 style={{ fontSize: '16px', color: '#03178c',fontWeight:'bold' }}></h2>
          }
        icons={tableIcons}
        options={{
            cellStyle: {
                fontSize: 11,
                fontWeight:500,
                color:'#696B6D'
              
             
              },
              headerStyle: {
                fontSize: 12,
                fontWeight: 'bolder',
                color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
              }
          
          }}

        actions={[
            rowData => ({
                icon: () =>  <Link to={`/dashboard/perfilclientetec/equipo/${this.props.clienteId}/${rowData.equipoid}`} style={{ color: '#03178C' }} ><AssignmentIcon  /></Link>,
                tooltip: 'Ver Perfil ',
                onClick: (rowData)
              }),
              {
                icon: tableIcons.MiIcon3,
                tooltip: 'Subir Imagen',
                onClick: (event, rowData) => this.handleOpen(rowData.equipoid) 
              }
             
           
          ]}
         
        >
            {//window.location.href=`equipo/${this.props.clienteId}/${rowData.equipoid}`/dashboard/perfilcliente/equipo/:clienteId/:equipoId
            }
        </MaterialTable>

{/*//aqui va el dialogo */}
<Dialog
                open={this.state.open}
                onClose={this.handleClose}
                 fullWidth
                maxWidth="sm"
                >
                 <DialogTitle>Subir Imagen del Equipo</DialogTitle>
                 
                <DialogContent>
                
                </DialogContent>
                <input type="file" onChange={this.onFileChange} accept="image/png,image/jpeg"   /> 
                <DialogActions>
                    <MyButton tip="Cerrar" onClick={this.handleClose}>
                    <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
                    </MyButton>
                    <MyButton tip="Subir Imagen del Equipo" onClick={this.onFileUpload}>
                    <FaPlus  color="#03178C" className="icon-formulario"/>
                    </MyButton>
                    
                </DialogActions>

                </Dialog>

        
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )


        return (
            <div className='div-inventario-tabla'>
                {mapeoequipos}
            </div>
        )
    }
}
InventarioTablaTec.propTypes={
    getEquipos:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired,
    subirImagenEquipoInventario: PropTypes.func.isRequired,  
}
const mapStateToProps=state=>({
    data:state.data
})
export default connect(mapStateToProps,{getEquipos,subirImagenEquipoInventario}) (InventarioTablaTec);


import React from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft } from 'react-icons/fa';
import TablaCronogramaMto from './TablaCronogramaMto';

function CronogramaMtoPerfil(props) {
  const history = useHistory();
  const { user, match } = props;

  const handleGoBack = () => {
    const clienteId = match.params.clienteId;
    const tipouser = user.credentials.tipouser;
    
    // Redirigir según el tipo de usuario
    if (tipouser === "Tecnico") {
      history.push(`/dashboard/perfilclientetec/${clienteId}`);
    } else {
      // Asumimos que es "Ingeniero" u otro tipo
      history.push(`/dashboard/perfilcliente/${clienteId}`);
    }
  };

  return (
    <div>
      <div className="titulo_section">
        <div className="titulo_clientes">
          <h1 className="text-clientes">Cronograma de Mantenimiento Preventivo</h1>
          <hr className="clientes_separator"></hr>
        </div>

        <div className="div_boton">
          <MyButton tip="Volver" onClick={handleGoBack}>
            <FaArrowCircleLeft color="#03178C" className="icon-eliminarcliente" />
          </MyButton>
        </div>
      </div>

      <div className="div-tabla-equipos">
        <TablaCronogramaMto 
          clienteId={match.params.clienteId} 
          history={history}
          userType={user.credentials.tipouser}
        />
      </div>
    </div>
  );
}

CronogramaMtoPerfil.propTypes = {
  user: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps)(CronogramaMtoPerfil);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './SolicitarReunion.css';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '../Elements/Button';
import FooterHome from '../Home/HomeComponents/Footer/FooterHome';
import MenuItem from '@mui/material/MenuItem';

import imgsrc from '../../components/Home/HomeComponents/Caracteristicas/alphamonitor.png';

import { solicitudReunion } from '../../redux/actions/dataactions';

class SolicitarReunion extends Component {
    constructor() {
        super();
        this.state = {
            nombre: '',
            email: '',
            telefono: '',
            empresa: '',
            cargo: '',
            fecha: '',
            hora: '',
            tipoReunion: 'virtual',
            numParticipantes: '',
            temaReunion: '',
            mensaje: '',
            loading: false,
            errors: {},
            aprobado: {},
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
            newState.aprobado = props.UI.aprobado;
            if (props.UI.aprobado.mensaje) {
                // Limpiar todos los campos
                newState.nombre = '';
                newState.email = '';
                newState.telefono = '';
                newState.empresa = '';
                newState.cargo = '';
                newState.fecha = '';
                newState.hora = '';
                newState.tipoReunion = 'virtual';
                newState.numParticipantes = '';
                newState.temaReunion = '';
                newState.mensaje = '';
                newState.errors = {};
            }
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        
        const reunionData = {
            nombre: this.state.nombre,
            email: this.state.email,
            telefono: this.state.telefono,
            empresa: this.state.empresa,
            cargo: this.state.cargo,
            fecha: this.state.fecha,
            hora: this.state.hora,
            tipoReunion: this.state.tipoReunion,
            numParticipantes: this.state.numParticipantes,
            temaReunion: this.state.temaReunion,
            mensaje: this.state.mensaje
        };

        console.log(reunionData);
        this.props.solicitudReunion(reunionData);
        
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    render() {
        const { UI: { loading } } = this.props;
        const { errors } = this.state;

        // Obtener fecha mínima (día actual)
        const today = new Date().toISOString().split('T')[0];

        return (
            <>
            <div id="solicitar-info">
                <div className="info-container">
                    <div className='info-container1'>
                        <div className="info-card">
                            <div className="info-divtitulo">
                                <h1 className="info-titulo">Solicitar Reunión</h1>
                                <p className="info-subtitulo">Agenda una reunión con nuestro equipo</p>
                            </div>

                            <form className="info-form" noValidate onSubmit={this.handleSubmit}>
                                <TextField
                                    className="info-input"
                                    required
                                    name="nombre"
                                    label="Nombre Completo"
                                    value={this.state.nombre}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.nombre ? errors.nombre : "Ingrese su nombre completo"}
                                    error={errors.nombre ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="email"
                                    label="Email"
                                    type="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.email ? errors.email : "Ingrese su email de contacto"}
                                    error={errors.email ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="telefono"
                                    label="Teléfono"
                                    value={this.state.telefono}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.telefono ? errors.telefono : "Ingrese su teléfono de contacto"}
                                    error={errors.telefono ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="empresa"
                                    label="Empresa"
                                    value={this.state.empresa}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.empresa ? errors.empresa : "Nombre de su empresa"}
                                    error={errors.empresa ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="cargo"
                                    label="Cargo"
                                    value={this.state.cargo}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.cargo ? errors.cargo : "Su cargo en la empresa"}
                                    error={errors.cargo ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="fecha"
                                    label="Fecha de reunión"
                                    type="date"
                                    value={this.state.fecha}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: today }}
                                    helperText={errors.fecha ? errors.fecha : "Seleccione la fecha preferida"}
                                    error={errors.fecha ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="hora"
                                    label="Hora de reunión"
                                    type="time"
                                    value={this.state.hora}
                                    onChange={this.handleChange}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ step: 900 }} // Intervalos de 15 minutos
                                    helperText={errors.hora ? errors.hora : "Seleccione la hora preferida"}
                                    error={errors.hora ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="tipoReunion"
                                    label="Tipo de Reunión"
                                    select
                                    value={this.state.tipoReunion}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.tipoReunion ? errors.tipoReunion : "Seleccione el tipo de reunión"}
                                    error={errors.tipoReunion ? true : false}
                                    variant="standard"
                                >
                                    <MenuItem value="virtual">Virtual</MenuItem>
                                    <MenuItem value="presencial">Presencial</MenuItem>
                                </TextField>

                                <TextField
                                    className="info-input"
                                    required
                                    name="numParticipantes"
                                    label="Número de Participantes"
                                    type="number"
                                    value={this.state.numParticipantes}
                                    onChange={this.handleChange}
                                    fullWidth
                                    inputProps={{ min: 1 }}
                                    helperText={errors.numParticipantes ? errors.numParticipantes : "Número de personas que asistirán"}
                                    error={errors.numParticipantes ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    required
                                    name="temaReunion"
                                    label="Tema de la Reunión"
                                    value={this.state.temaReunion}
                                    onChange={this.handleChange}
                                    fullWidth
                                    helperText={errors.temaReunion ? errors.temaReunion : "Tema principal a tratar"}
                                    error={errors.temaReunion ? true : false}
                                    variant="standard"
                                />

                                <TextField
                                    className="info-input"
                                    name="mensaje"
                                    label="Comentarios Adicionales"
                                    value={this.state.mensaje}
                                    onChange={this.handleChange}
                                    fullWidth
                                    multiline
                                    rows={4}
                                    helperText="Información adicional que desee compartir"
                                    variant="standard"
                                />

                                <p className="info-error">{errors.general}</p>

                                {this.state.aprobado.mensaje && (
                                    <p className="info-success">¡Tu solicitud de reunión ha sido enviada! Te confirmaremos por correo electrónico.</p>
                                )}

                                {loading ? (
                                    <CircularProgress size={30} color="primary" className="info-progress" />
                                ) : (
                                    <Button type="submit" buttonSize="btn--wide" buttonColor="blue">
                                        Agendar Reunión
                                    </Button>
                                )}
                            </form>
                        </div>
                    </div>
                    
                    <div className='info-container2'>
                            <div className="info-card">
                                <div className="info-divtitulo">
                                   
                                    <h2 className="info-titulo">Transformemos juntos la gestión de tu hospital</h2>
                                    <p className="info-subtitulo">
                                        Agenda una reunión personalizada y descubre cómo Alpha Biomédica 
                                        está revolucionando el mantenimiento hospitalario
                                    </p>
                                </div>
                                <div className="info-mensaje">
                                    <div className="info-seccion-destacada">
                                        <h3>¿Qué lograrás en esta reunión?</h3>
                                        <ul>
                                            <li>Conocerás cómo nuestra IA puede reducir hasta un 30% tus costos operativos</li>
                                            <li>Verás una demostración en vivo de nuestro software</li>
                                            <li>Recibirás un análisis preliminar de las áreas de mejora en tu institución</li>
                                            <li>Resolveremos todas tus dudas con nuestros expertos</li>
                                        </ul>
                                    </div>
                                    
                                    <div className="info-ventajas">
                                        <h3>¿Por qué Alpha Biomédica?</h3>
                                        <p>
                                            Somos líderes en soluciones tecnológicas para el sector salud, 
                                            con más de 10 años transformando la gestión del mantenimiento hospitalario.
                                        </p>
                                        <ul>
                                            <li>
                                                <strong>Innovación Continua:</strong> Nuestras herramientas con IA optimizan 
                                                tus procesos de mantenimiento con indicadores en tiempo real
                                            </li>
                                            <li>
                                                <strong>Cumplimiento Normativo:</strong> Generamos automáticamente todos los 
                                                reportes necesarios para cumplir con las regulaciones vigentes
                                            </li>
                                            <li>
                                                <strong>Eficiencia Operativa:</strong> Control total de gastos y compras 
                                                para maximizar tu presupuesto
                                            </li>
                                            <li>
                                                <strong>Resultados Comprobados:</strong> Incrementamos la productividad 
                                                de los servicios hospitalarios
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="info-cta">
                                        <p>
                                            No esperes más para optimizar la gestión de tu hospital. 
                                            Agenda tu reunión ahora y da el primer paso hacia la transformación 
                                            digital de tu institución.
                                        </p>
                                        <img src={imgsrc} alt="Alpha Biomédica" className="info-logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <FooterHome />
            </>
        );
    }
}

SolicitarReunion.propTypes = {
    solicitudReunion: PropTypes.func.isRequired,
    UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    UI: state.UI
});

export default connect(mapStateToProps, { solicitudReunion })(SolicitarReunion);
import React, { useState, useEffect } from 'react';
import { ButtonGroup, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import MyButton from '../../../utils/MyButton';
import { FaArrowCircleLeft, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getEquipos, getCliente } from '../../../redux/actions/dataactions';
import { PDFViewer } from '@react-pdf/renderer';
import './InventarioPDFAsis.css';
import InventarioDocuPDF from '../Equipos/InventarioDocuPDF';
import * as XLSX from 'xlsx';

function InventarioPDFAsis(props) {
    const [verPDF, setVerPDF] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (props.match?.params?.clienteId) {
            props.getEquipos(props.match.params.clienteId);
            props.getCliente(props.match.params.clienteId);
        }
    }, [props.match?.params?.clienteId]);

    const handleOpenPDF = () => {
        setVerPDF(!verPDF);
    };

    const handleGoBack = () => {
        history.push('/dashboard/inventarioequiposasistencial');
    };

    const { equipos, cliente, loading } = props.data;
    
    // Función para formatear valores de moneda (similar a la que usan en el PDF)
    const formatCurrency = (value) => {
        if (!value) return '$ 0';
        return `$ ${Number(value).toLocaleString('es-CO')}`;
    };

    // Función para exportar el inventario a Excel
    const exportToExcel = () => {
        if (!equipos || equipos.length === 0) {
            alert('No hay datos de equipos para exportar');
            return;
        }

        // Crear un libro de trabajo nuevo
        const workbook = XLSX.utils.book_new();
        
        // Preparar los datos del cliente y la información del encabezado
        const headerData = [
            ['INVENTARIO DE EQUIPOS BIOMÉDICOS', '', '', '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '', '', ''],
            ['Cliente:', cliente?.nombre || 'No disponible', '', '', 'CC/NIT:', cliente?.ccnit || 'No disponible', '', '', 'Ciudad:', cliente?.ciudad || 'No disponible', ''],
            ['Fecha:', new Date().toLocaleDateString('es-CO'), '', '', '', '', '', '', '', '', ''],
            ['', '', '', '', '', '', '', '', '', '', '']
        ];
        
        // Encabezados de la tabla
        const tableHeaders = [
            ['ID', 'Nombre', 'Marca', 'Modelo', 'Serie', 'Ubicación', 'Servicio', 'Estado', 'Registro Invima', 'Riesgo', 'Costo']
        ];
        
        // Ordenar equipos por ubicación
        const equiposOrdenados = [...equipos].sort((a, b) => {
            const ubicacionA = (a.ubicacion || 'Sin ubicación').toLowerCase();
            const ubicacionB = (b.ubicacion || 'Sin ubicación').toLowerCase();
            return ubicacionA.localeCompare(ubicacionB);
        });
        
        // Mapear los datos de los equipos para la tabla (ya ordenados por ubicación)
        const tableData = equiposOrdenados.map(equipo => [
            equipo.idinventario || '',
            equipo.nombre || '',
            equipo.marca || '',
            equipo.modelo || '',
            equipo.serie || '',
            equipo.ubicacion || '',
            equipo.servicio || '',
            equipo.estado || '',
            equipo.registroinvima || '',
            equipo.riesgo || '',
            formatCurrency(equipo.costo) || ''
        ]);
        
        // Agregar una fila de pie de página
        const footerData = [
            ['', '', '', '', '', '', '', '', '', '', ''],
            ['Generado por Alpha Biomédica - www.alphabiomedica.com', '', '', '', '', '', '', '', '', '', '']
        ];
        
        // Combinar todos los datos
        const sheetData = [...headerData, ...tableHeaders, ...tableData, ...footerData];
        
        // Crear la hoja con todos los datos
        const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
        
        // Ajustar anchos de columna
        const columnsWidth = [
            { wch: 8 },  // ID
            { wch: 25 }, // Nombre
            { wch: 15 }, // Marca
            { wch: 15 }, // Modelo
            { wch: 15 }, // Serie
            { wch: 20 }, // Ubicación
            { wch: 15 }, // Servicio
            { wch: 10 }, // Estado
            { wch: 15 }, // R.Invima
            { wch: 8 },  // Riesgo
            { wch: 15 }  // Costo
        ];
        worksheet['!cols'] = columnsWidth;
        
        // Fusionar celdas para el título principal
        worksheet['!merges'] = [
            // Fusionar celdas para el título
            { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
            // Fusionar celdas para información del cliente
            { s: { r: 2, c: 1 }, e: { r: 2, c: 2 } },
            { s: { r: 2, c: 5 }, e: { r: 2, c: 6 } },
            { s: { r: 2, c: 9 }, e: { r: 2, c: 10 } },
            // Fusionar celdas para el pie de página
            { s: { r: tableData.length + headerData.length + tableHeaders.length + 1, c: 0 }, e: { r: tableData.length + headerData.length + tableHeaders.length + 1, c: 10 } }
        ];
        
        // Añadir la hoja al libro
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Inventario');
        
        // Generar el nombre del archivo con la fecha actual
        const fechaActual = new Date().toISOString().slice(0, 10);
        const fileName = `Inventario_${cliente?.nombre || 'Cliente'}_${fechaActual}.xlsx`;
        
        // Descargar el archivo
        XLSX.writeFile(workbook, fileName);
    };

    const renderContent = () => {
        if (loading) {
            return (
                <div className="inventario-loading-message">
                    <p>Cargando datos...</p>
                </div>
            );
        }

        if (!cliente?.plantillaType) {
            return (
                <div className="inventario-error-message">
                    <p>Para ver las plantillas, primero debe configurar el tipo de plantilla en la lista de clientes</p>
                </div>
            );
        }

        if (!equipos) {
            return (
                <div className="inventario-error-message">
                    <p>No se encontraron equipos para mostrar</p>
                </div>
            );
        }

        if (verPDF && equipos && cliente) {
            return (
                <PDFViewer className="pdf-viewer">
                    <InventarioDocuPDF 
                        equipos={equipos} 
                        cliente={cliente}
                    />
                </PDFViewer>
            );
        }

        return null;
    };

    return (
        <div className='div-inventaripdfgeneral'>
            <div className='div-inventaripdftitulo'>
                <div className="div_boton">
                    <div className="back-button-container">
                        <MyButton tip="Volver" onClick={handleGoBack}>
                            <FaArrowCircleLeft className="icon-eliminarcliente"/>
                        </MyButton>
                    </div>
                    
                    {equipos && cliente?.plantillaType && (
                        <div className="button-group-container">
                            <ButtonGroup 
                                variant="text" 
                                aria-label="text button group" 
                                color='primary'
                            >
                                <Button 
                                    onClick={handleOpenPDF}
                                    className="pdf-button"
                                    startIcon={<FaFilePdf />}
                                >
                                    {verPDF ? "Ocultar PDF" : "Ver PDF"}
                                </Button>
                                
                                {/* Botón para exportar a Excel */}
                                <Button 
                                    onClick={exportToExcel}
                                    className="excel-button"
                                    startIcon={<FaFileExcel />}
                                >
                                    Exportar a Excel
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}
                </div>
            </div>

            <div className='div-inventariopdfcontent'>
                {renderContent()}
            </div>
        </div>
    );
}

InventarioPDFAsis.propTypes = {
    getEquipos: PropTypes.func.isRequired,
    getCliente: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    data: state.data
});

export default connect(mapStateToProps, { getEquipos, getCliente })(InventarioPDFAsis);
import React, { Component, Fragment } from 'react';

import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MyButton from '../../../utils/MyButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import CircularProgress from '@material-ui/core/CircularProgress';

import { FaArrowLeft, FaTools, FaPuzzlePiece } from "react-icons/fa";

import './AccesoriosEquipo.css'

export class AccesoriosEquipo extends Component {
  state = {
    open: false
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  // Helper method to check if equipment has any accessories
  hasAccessories() {
    const { equipo } = this.props;
    return equipo && (
      equipo.accesorio === true || 
      (equipo.accesorionombre1 && equipo.accesorionombre1.trim() !== '') ||
      (equipo.accesorionombre2 && equipo.accesorionombre2.trim() !== '') ||
      (equipo.accesorionombre3 && equipo.accesorionombre3.trim() !== '')
    );
  }

  render() {
    const { UI: { loading }, equipo } = this.props;
    const hasAccessories = this.hasAccessories();

    return (
      <div>
        <Fragment>
          <MyButton
            tip="Accesorios del Equipo"
            onClick={this.handleOpen}
          >
            <FaTools color="#03178C" className="icon-crearcliente" />
          </MyButton>

          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle>
              <div className='container-title-recomendaciones'>
                <h2 className='title-recomendaciones'>Accesorios del Equipo</h2>
              </div>
            </DialogTitle>

            {loading ? (
              <CircularProgress size={30} color="primary" className="login-progress-recomendation-uso" />
            ) : (
              <div>
                <DialogContent>
                  <div className='container-recomendaciones'>
                    {/* Mostrar accesorios si existen */}
                    {hasAccessories ? (
                      <div className="accesorios-existentes">
                        <div className="seccion-cabecera">
                          <FaTools className="icon-seccion" />
                          <Typography variant="h6" className="seccion-titulo">
                            Accesorios registrados
                          </Typography>
                        </div>
                        
                        <div className="accesorios-container">
                          {/* Indicador general de accesorios */}
                          {equipo.accesorio === true && (
                            <div className="accesorios-indicator">
                              <p className="recomendacion-text">El equipo incluye accesorios</p>
                            </div>
                          )}

                          {/* Lista de accesorios */}
                          {(equipo.accesorionombre1 || equipo.accesorionombre2 || equipo.accesorionombre3) && (
                            <div className="accesorios-lista">
                              
                              {/* Accesorio 1 */}
                              {equipo.accesorionombre1 && equipo.accesorionombre1.trim() !== '' && (
                                <div className="accesorio-item">
                                  <h3 className="accesorio-titulo">{equipo.accesorionombre1}</h3>
                                  <div className="accesorio-details">
                                    <div className="accesorio-detail-item"><strong>Referencia:</strong> {equipo.accesorioref1 || 'No especificada'}</div>
                                    <div className="accesorio-detail-item"><strong>Serie:</strong> {equipo.accesorioserie1 || 'No especificada'}</div>
                                  </div>
                                </div>
                              )}

                              {/* Accesorio 2 */}
                              {equipo.accesorionombre2 && equipo.accesorionombre2.trim() !== '' && (
                                <div className="accesorio-item">
                                  <h3 className="accesorio-titulo">{equipo.accesorionombre2}</h3>
                                  <div className="accesorio-details">
                                    <div className="accesorio-detail-item"><strong>Referencia:</strong> {equipo.accesorioref2 || 'No especificada'}</div>
                                    <div className="accesorio-detail-item"><strong>Serie:</strong> {equipo.accesorioserie2 || 'No especificada'}</div>
                                  </div>
                                </div>
                              )}

                              {/* Accesorio 3 */}
                              {equipo.accesorionombre3 && equipo.accesorionombre3.trim() !== '' && (
                                <div className="accesorio-item">
                                  <h3 className="accesorio-titulo">{equipo.accesorionombre3}</h3>
                                  <div className="accesorio-details">
                                    <div className="accesorio-detail-item"><strong>Referencia:</strong> {equipo.accesorioref3 || 'No especificada'}</div>
                                    <div className="accesorio-detail-item"><strong>Serie:</strong> {equipo.accesorioserie3 || 'No especificada'}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="no-accesorios">
                        <Typography variant="h6" className="no-accesorios-titulo">
                          Sin accesorios registrados
                        </Typography>
                        <Typography variant="body1" className="no-accesorios-mensaje">
                          Este equipo no tiene accesorios adicionales registrados en el sistema.
                        </Typography>
                        <Typography variant="body2" className="no-accesorios-sugerencia">
                          Puede agregar accesorios al editar la información del equipo. En la función Editar.
                        </Typography>
                      </div>
                    )}
                  </div>
                </DialogContent>
              </div>
            )}

            <DialogActions>
              <MyButton tip="Cerrar" onClick={this.handleClose}>
                <FaArrowLeft color="#D3D7DC" className="icon-formulario" />
              </MyButton>
            </DialogActions>

          </Dialog>
        </Fragment>
      </div >
    )
  }
}

AccesoriosEquipo.propTypes = {
  UI: PropTypes.object.isRequired,
  tipouser: PropTypes.string.isRequired,
  equipo: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  equipo: state.data.equipo.credentials,
  UI: state.UI,
  tipouser: state.user.credentials.tipouser,
});

export default connect(mapStateToProps)(AccesoriosEquipo);